<!--

두가지 방식으로 사용.
            <div class="row">
              <s-td th="검색어" class="col-12 col-md">
                전체 - 검색어
              </s-td>
              <s-td th="검색어" class="col-12 col-md">
                전체 - 검색어
              </s-td>
              <s-td th="원생상태" class="col-12 col-md">
                전체 정상 휴원 정상 휴원 정상 휴원 정상 휴원 정상 휴원
              </s-td>
            </div>

            <div class="row">
              <s-td th="검색어" class="col-12 col-md">
                전체 - 검색어
              </s-td>

              <s-td th="검색어" class="col-12 col-md-8">
                전체 - 검색어
              </s-td>
            </div>


-->
<template>
  <div class="row no-wrap s-td" :class="parnet_td_class">

    <!-- attr 방식 헤더 : title="반선택" 방식으로 정의한 경우 -->
    <div v-if="th"
        class="col-auto imd-s-td-th s-th"
        :class="thClass"
        :style="th_style">
      <span v-if="required" class="imd-required">*</span>
      <span v-html="th"/>
      <s-help v-if="thHelp" :content="thHelp" margin-left/>
    </div>

    <!-- scope 방식 헤더 : "<template v-slot:th>" 또는 <div slot="th"> 로 정의한 경우 -->
    <template v-else-if="typeof $scopedSlots['th'] !== 'undefined'">
      <div class="col-auto imd-s-td-th s-th q-pa-xs"
           :class="thClass"
           :style="th_style">
        <slot name="th"/>
        <s-help v-if="thHelp" :content="thHelp" margin-left/>
      </div>
    </template>

    <!--  데이터  -->
    <div class="row col imd-s-td-td s-td-body" :class="contentClass" :style="contentStyle">
      <slot/>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    required:     { type: Boolean , default: false }, // 필수표시여부 (빨간색 별표)
    th:           { type: String  , default: ''   },  // th 내용
    thWidth:      { type: Number  , default: null },  // th 의 width 값 (px)  -- ** 여러개 쓰일 경우는  css (deep-selectors) 를 이용하자. 아래참고 **
    thClass:      { type: String  , default: null },  // th 의 class : ex. th-class="gt-sm" -- md 부터 보이게
    thHelp:       { type: String }, // th 의 도움말
    contentClass: { type: String  , default: null },  // td 의 class
    contentStyle: { type: String  , default: null },  // td 의 class
    ignoreStdClass: { type: Boolean  , default: false }, // 부모의 std-class 무시할지 여부
  },
  computed: {
    th_style() {
      if (this.thWidth > 0)
        return { width: this.thWidth + 'px'}

      const parentSthWidth = this.$parent['sthWidth']
      if (T.isNotNU(parentSthWidth))
        return { width: parentSthWidth + 'px'}

      return {}
    },
    parnet_td_class() {
      if (this.ignoreStdClass) return null
      return this.$parent['stdClass']
    },
  },
}
</script>

<!--

  [ deep-selectors css ]  ---  https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors

  <style scoped>
    .s-detail >>> .s-th { width: 120px; }
  </style>

-->
