<template>
  <q-option-group
    dense inline size="27px"
    class="imd-option-group"
    v-bind="{...$attrs, value}"
    v-on="{...$listeners}"
    :options="items_cp"
  />
</template>

<script>

const log = T.newLogger("i-input-radio", false)

export default {
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    itemsp: {type: String},
    items: {type: Array},
    value: {type: [Object,Number,String]},
    numberValue: {type: Boolean, default: false}
  },
  data () {
    return {
      items_cp : []
    }
  },
  created() {
    if (!this.items && this.itemsp) {

      log("this.itemsp", this.itemsp)

      this.items_cp = this.itemsp.trim()
        .split(/\s*\|\s*/)
        .reduce((p,i)=>{
          const ar = i.split(/\s*\/\s*/);
          p.push({
            'value' : this.numberValue ? T.toNumber(ar[0]) : ar[0],
            // 'value' : ar[0],
            'label' : ar.length > 1 ? ar[1] : ar[0]
          });
          return p
        }, [])

      log("this.items_cp", this.items_cp)

    } else {
      this.items_cp = this.items
    }
  },
  watch : {
    items () {
      this.items_cp = this.items
    }
  }
}
</script>


<!--

  <i-input-radio v-model="search.useyn" itemsp="/전체 | 1/사용 | 0/미사용" />
  <i-input-radio v-model="search.forceyn" itemsp="/전체 | Y/강세 | N/선택" />

-->
