import {computed, reactive, ref, toRefs,} from 'vue';

const log = T.getLogger("CO-LIST-BOX-SELECTED", "debug")

/**
 * 선택 목록
 * @param {Function} list
 * @param {string}   key
 */
export default (list, key) => {

  const state = reactive({

    /**
     * 아이템별 checked value
     * . ex) [123,333,444]
     */
    selected_list: [] as any[],

  })

  return {
    ...toRefs(state),

    /** 선택된 갯수 */
    selected_cnt: computed(()=>state.selected_list?.length),

    /** 현재 선택된 아이템인가 ?*/
    isSelected: (v) => state.selected_list?.includes(v),

    /** 선택 초기화 */
    doSelectClear: () => state.selected_list = [],

    /** 전체선택 checkbox : '0', '1', null 인 경우 살짝 선택된 짝대기 표시 */
    computed_top_checkbox: computed({
      get: ():string => state.selected_list?.length < 1             ? '0'  :      // 선택된 것 없으면 '0'
                        state.selected_list?.length < list().length ? null : '1', // 총 갯수보다 선택된 갯수가 작으면 null, 총 갯수와 선택된 갯수가 같으면 '1'
      set: (v)   => v === '1' ? state.selected_list = list().map(m => m[key])  // 체크 처리
                                  : state.selected_list = [],                      // 체크해제 처리
    }),

  }
}
