<template>
  <q-btn
    :outline="false"
    padding="3px 5px"
    size="12px"
    class="imd-i-td-btn shadow-up-1"
    v-bind="{...$attrs, color, textColor}"
    v-on="{...$listeners}"
    :label="title"
  >
    <slot></slot>

    <!-- 툴팁 지정시  -->
    <s-tooltip v-if="tip" :content="tip" />

  </q-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {},
    tip: {},
    color: { default: 'white'},
    textColor: { default: 'black'},
}
  // props: ['type', 'skey',],
}
</script>
