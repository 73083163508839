<!-- md 이하일때 th td 를 wrap 한다. -->
<template>
  <div class="no-wrap">

  <!-- [th] lt-md 로 인하여 md 이하일때 full-width 로 보여주는 부분  -->
    <template v-if="!isMd">
      <!-- attr 방식 헤더 : title="반선택" 방식으로 정의한 경우..  lt-md -->
      <div v-if="th && $q.screen.lt.md" class="col-12 no-wrap">
        <div class="q-pa-xs imd-s-td-th">
          <span v-if="required" class="imd-required">*</span>
          {{ th }}
          <s-help v-if="thHelp" :content="thHelp"/>
        </div>
      </div>

      <!-- scope 방식 헤더 : "<template v-slot:th>" 또는 <div slot="th"> 로 정의한 경우 -->
      <template v-else-if="typeof $scopedSlots['th'] !== 'undefined'">
        <div class="col-12 q-pa-xs imd-s-td-th lt-md"><slot name="th"/></div>
      </template>
    </template>

    <div class="col-12 row no-wrap">

      <template v-if="isMd">
        <!-- [th] gt-sm 로 인하여 md 부터 보여주는 부분  -->

        <!-- attr 방식 헤더 : title="반선택" 방식으로 정의한 경우 -->
        <div v-if="th"
            class="col-auto q-pa-xs imd-s-td-th gt-sm s-th"
             style="display: inline"
            :style="th_style">
          <span v-if="required" class="imd-required">*</span>
          {{ th }}
          <s-help v-if="thHelp" :content="thHelp"/>
        </div>

        <!-- scope 방식 헤더 : "<template v-slot:th>" 또는 <div slot="th"> 로 정의한 경우 -->
        <template v-else-if="typeof $scopedSlots['th'] !== 'undefined'">
          <div class="col-auto q-pa-xs imd-s-td-th gt-sm s-th"
               :style="th_style">
            <slot name="th"/>
            <s-help v-if="thHelp" :content="thHelp" margin-left/>
          </div>
        </template>
      </template>

      <!--  데이터  -->
      <div class="col imd-s-td-td" :class="T.mergeClass({'q-pa-xs': isMd}, tdClass)">
        <slot/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 's-td-wrapmd',
  props: {
    th:       { type: String  , default: ''   },  // th 내용
    thWidth:  { type: Number  , default: null },  // th 의 width 값 (px)  -- ** 여러개 쓰일 경우는  css 를 이용하자. **
    thClass:  { type: String  , default: null },  // th 의 class : ex. th-class="gt-sm" -- md 부터 보이게
    tdClass:  { type: String },
    required: { type: Boolean , default: false }, // 필수여부
    thHelp:   { type: String }, // th 의 도움말
  },
  computed: {
    isMd() { return this.$q.screen.gt.sm } ,// 현재 화면이 md, lg, xl 인가 ?
    th_style() {return this.thWidth > 0 ? { width: this.thWidth + 'px'} : {}},
  }
}
</script>
