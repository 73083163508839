<template>
  <q-icon
    v-bind="{...$attrs, size, name}"
    v-on="$listeners"
    class="cursor-pointer"
  >
    <slot/>
    <s-tooltip v-if="tip" :content="tip"/>
  </q-icon>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    name: {type: String, required: true},
    size: {type: String, default: '19px'}, // 16px
    tip: {type: String}
  },
})
</script>
