<template>
  <q-chip
    v-bind="{...$attrs, label}"
    v-on="{...$listeners}"
    outline color="primary" text-color="white"
    class="col-auto s-chip"
    size="12px"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  // model: {
  //   prop: 'value',
  //   event: 'input'
  // },
  props: {
    label: {},
    // trueValue:  { default: '1' },  // 체크했을때 값. 기본 1
    // falseValue: { default: '0' },  // 체크 안했을때 값. 기본 0
  },

}
</script>


<style scoped>
</style>
