<template>
  <q-splitter
    v-model="opt.leftWidthValue"
    :horizontal="$q.screen.lt.md"
    :disable="$q.screen.lt.md"
    @input="v=>$emit('change', v)"
    class="full-width"
    :separator-style="{ display: $q.screen.lt.md ? 'none' : 'block' }"
    :limits="[0,Infinity]"
    :unit="opt.unit"
  >
<!--    style="display: flex;flex-direction: column-reverse;"-->
    <template v-slot:before><slot name="split1" /></template>
    <template v-slot:after><slot name="split2" /></template>
  </q-splitter>
</template>

<script>
/**
 * s-split 은 하위 컴포넌트 정보가 날라가서 임시로 새로 만들었다.
 * 다만 이 방식은 rightTopSm 옵션을 지원하지 않는다.
 * 그리고 다양한 환경에서 테스트 되지 않았다.
 */
export default {
  props: {
    /** 왼쪽 사이즈(%) 기본값 30%  */
    leftWidth: {type: String, default: '50%'},
  },
  data() {
    return {
      opt: this.getOptionFromProp()
    }
  },
  watch: {
    leftWidth(n,p) {
      T.setOverwrite(this.opt, this.getOptionFromProp())
    },
  },
  methods: {
    getOptionFromProp() {
      const lw = this.leftWidth || '50%'
      const unit = lw.endsWith('%') ? '%' : 'px'
      const leftWidthValue = Number(lw.replace(/(%|px)$/,''))
      return {unit, leftWidthValue}
    },
  }
}
</script>
