<template>
  <q-tooltip
    content-class="imd-tooltip"
    :anchor="anchor" :self="self"
    v-bind="{...$attrs, target}"
    v-on="{...$listeners}"
  >
    <span v-if="content" v-html="T.newlineToBr(content)"/>
    <slot/>
  </q-tooltip>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    target: {type: [Boolean, String], default: true},
    content: {type: String}, // 툴팁내용. <slot> 대신 사용가능. 자동 br 변환
    position: {type: String, default: 'top'}, // 툴팁위치 : top, down
  },
  computed: {
    anchor() {
      if (this.position === 'down') return "bottom middle"
      return "top middle"
    },
    self() {
      if (this.position === 'down') return "top middle"
      return "bottom middle"
    }
  },
}
</script>
