<template>
<!--  <q-page class="q-pa-sm" style="background-color: #F5F9FC">-->
<!--    <slot/>-->
<!--  </q-page>-->
  <div class="q-pa-sm s-wrapper" style="background-color: #F4F5F7">
    <slot/>
  </div>
  <!--
    <q-page 를 쓰게 되면 q-page 자체를 window.height 로 잡게 되어 웹에서 (원톡 관리자에서) 탑 메뉴가 차지하는 공간 때문에
    스크롤바가 늘 나타나게 된다.
    그래서 고심하다가 그냥 q-page를 안쓰는 방향으로 일단은 결정.
   -->
</template>

<script>
export default {
}
</script>
