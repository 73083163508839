// import {FileUploadResponse} from "@common/type/attach-file";

export interface AttachFileType {
  /** 파일 이름 */
  name: string
  /** 파일 경로 */
  path: string
  /** 파일 타입 */
  type: string
  /** 파일 사이즈 */
  size: number
}

/** 업로드 api 호출 결과.. 음.. 이건 프로젝트마다 다를껀데 어쩐다.. */
export interface FileUploadResponse {
  status        : 'ok' | 'error' | string // "ok",
  field_name    : string // file.fieldname,
  org_filename  : string // file.originalname,
  saved_filename: string // file.filename,
  ext           : string // extname(file.originalname),
  size          : number // file.size,
  content_type  : string // file.mimetype
  path          : string
}

export type AttachFileStatus =
  | 'ready'
  | 'resizing'
  | 'resized'
  | 'resizfail'
  | 'uploading'
  | 'uploaded'
  | 'uploadfail'
  | string

export const AttachFileStatusToCmt: {[key:AttachFileStatus]: string} = {
  ready       : "준비",
  resizing    : "리사이즈 중",
  resized     : "리사이즈 완료",
  resizfail   : "리사이즈 실패",
  uploading   : "업로드 중",
  uploaded    : "업로드 완료",
  uploadfail  : "업로드 실패",
}

/** 서버에 저장되어 있던 파일 */
export interface AttachLoadFile {
  /** 파일 이름 */
  name: string
  /** 파일 경로 */
  path: string
  /** 파일 타입 */
  type: string
  /** 파일 사이즈 */
  size: number
  
  /** 있는경우 view url */
  url ?: string
}

export class AttachFile {
  /** 파일 객체 */
  file: File
  name: string
  /** 고유한 id */
  id: string
  /** blob url (미리보기용) */
  url: string
  /** 상태 */
  status: AttachFileStatus
  size ?: number
  sizeLabel?: string
  uploadProgress?: any
  uploadResult?: FileUploadResponse
  /** 이미지가 아닌경우 true*/
  no_img?: boolean
  extension ?: string

  /** 업로드된 경로 */
  path?: string
  
  /** 이전 파일 여부*/
  is_prev ?: boolean
  is_image ?: boolean

  constructor(file: File | AttachLoadFile) {

    if (file instanceof File) {
      this.is_prev = false
      // this.file       = file
      // this.url        = URL.createObjectURL(file)
      this.name = file.name
      this.status = 'ready'
      this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
      this.extension = T.getFileExtOnly(file.name).toUpperCase()
      this.is_image = isImageFile(file.name)
      this.updateFile(file)

    } else {
      this.is_prev = true
      this.status = "uploaded"
      this.name = file.name
      this.path = file.path
      this.size = file.size
      this.url  = file.url
      this.is_image = isImageFile(file.name)
      this.uploadResult = {
        content_type: file.type,
        size: file.size,
        org_filename: file.name,
      } as FileUploadResponse
    }

  }

  statusName() {
    return AttachFileStatusToCmt[this.status]
  }

  updateFile(file: File) {
    this.file       = file
    this.url        = URL.createObjectURL(file)
    this.size       = file.size
    this.sizeLabel  = T.byteToHumanSize(file.size)
  }

}


function isImageFile(fileName: string) {
  if (!fileName) return false;
  
  // 이미지 파일 확장자 목록
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

  // 파일 확장자 추출
  const extension = fileName.split('.').pop().toLowerCase();

  // 이미지 확장자 목록에 해당 확장자가 있는지 확인
  return imageExtensions.includes(extension);
}
