<template>
  <q-checkbox
    v-bind="$attrs"
    v-on="$listeners"
    size="32px"
    dense
    :true-value="trueValue"
    :false-value="falseValue"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  // model: {
  //   prop: 'value',
  //   event: 'input'
  // },
  props: {
    trueValue:  { default: '1' },  // 체크했을때 값. 기본 1
    falseValue: { default: '0' },  // 체크 안했을때 값. 기본 0
  }
}
</script>
