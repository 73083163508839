<template>
    <q-select
      v-bind="{
        ...$attrs,
        options: items_cp,
        value: myValue,
        itemValue
      }"

      v-on="{
        ...$listeners,
        input: onInput,
      }"

      emit-value map-options
      dense options-dense
      outlined

      style="display: inline-block"
      class="imd-input-sel"
      popup-content-class="imd-input-sel"

      transition-show="none"
      transition-hide="none"
      transition-duration="none"
    />
</template>

<script lang="ts">
import InputSet from './mixin/input-set';

function isEmptyArray(array) { // 배열이 비어있나 확인한다.
  if (array === null || array === undefined || array.length === 0) return true
  if (array.length === 1 && array[0] === undefined || array[0] == null) return true
  return false
}

export default {
  inheritAttrs: false,
  mixins: [ InputSet ],
  props: {
    value: [String, Number, Object, Array],
    itemsp: String,  // itemsp="/전체 | Y/강세 | N/선택"
    items: Array,
    itemValue: { type: String, default: "value" },
    debug: { type: Boolean, default: false },
    emitFirstValue: { type: Boolean, default: false }, // 자동으로 첫번째 선택시 그 값이 emit 할지 여부 ( 보통은 하지 말자 )
  },
  data () {
    return {
      myValue : null, // 실제 값하고 표시하는 값하고 달라야 하기 때문에 표시용 myValue는 반드시 필요하다.
                      // 가령 i-input-sel 이 search 에 사용되는 경우 items 가 늦게 로딩될때
                      // getFirstValue 에 의해서 자동으로 첫번째 값이 선택되고 그것이 emit 되어
                      // 순간적으로 search 값을 '' 으로 만들어 잘못된 조회가 발생하게 된다.
      items_cp : [],
      org_value : null, // 임의로 값 지정시 원래 값을 보관한다.
//      sendEmit : 0, // 내 자신이 바꾼것은 watch를 피하기 위한 변수 ( 무한루프 방지 )
    }
  },
  watch : {
    value (n,p) {
      this.debug && console.log("---[i-input-sel] (watch) value 변경 감지! : new =", T.objectDetail(n), ", prev =", T.objectDetail(p), ", org_value =", T.objectDetail(this.org_value), ", myValue =", T.objectDetail(this.myValue), ", value =", T.objectDetail(this.value))
      // if (this.isMyEmit()) return // 내가 보낸 emit 인 경우 아무것도 하지 않는다.
      if (n === this.myValue) {
        this.debug && console.log("[i-input-sel] (watch) 현재값 하고 동일하여 아무것도 안한다.")
        return
      }
      if (this.value === undefined) { // clear()시 호출됨.
        // this.emit("", "WATCH")
      }
      this.autoSelectByValue() // 값이 변경된 것도 create() 하고 동급으로 처리한다.
    },
    items (n,p) { // CODE같은 경우 늦게 로딩될때까 있다. 그걸 왓치해서 items_cp 에 반영하자.
      this.debug && console.log("[i-input-sel] (watch) items 변경 감지! : items =", this.items, ", new =", T.objectDetail(n), ", prev =", T.objectDetail(p));

      if (isEmptyArray(p) && isEmptyArray(n)) {
        this.debug && console.log("[i-input-sel] watch(items) : 둘다 빈값. 아무것도 안함")
        return
      }

      this.items_cp = this.items
      this.autoSelectByItems()
    },
    itemsp (n,p) {
      this.debug && console.log("[i-input-sel] (watch) itemsp 변경 감지! : itemsp =", this.itemsp, ", new =", T.objectDetail(n), ", prev =", T.objectDetail(p));

      if (!p && !n) {
        this.debug && console.log("[i-input-sel] watch(itemsp) : 둘다 빈값. 아무것도 안함")
        return
      }

      this.items_cp = n.trim() // itemsp="/전체 | Y/강세 | N/선택"
        .split(/\s*\|\s*/)
        .map(v  => v.trim().split(/\s*\/\s*/))
        .map(ar => ({value: ar[0], label: ar.length > 1 ? ar[1] : ar[0]}))
      this.autoSelectByItems()
    },
  },
  created() {
    this.debug && console.log("[i-input-sel] created() : this.value = ", T.objectDetail(this.value))
    this.myValue = this.value;

    // items대신 itemsp 가 지정된 경우
    if (!this.items && this.itemsp) {
      this.items_cp = this.itemsp.trim() // itemsp="/전체 | Y/강세 | N/선택"
        .split(/\s*\|\s*/)
        .map(v  => v.trim().split(/\s*\/\s*/))
        .map(ar => ({value: ar[0], label: ar.length > 1 ? ar[1] : ar[0]}))
    } else {
      this.items_cp = this.items
    }

    this.autoSelectByValue() // 자동선택 (select box 처럼)

  },
  methods: {
    onInput(v) {
      this.myValue = v
      this.emit(v, "onInput")
    },
    hasItemsValue(v) { // 지정된 값이 items 에 있나 ?
      const ret = !isEmptyArray(this.items_cp) && this.items_cp.some(item=> (item == v) || (!T.isNU(item) && item[this.itemValue] == v))
      this.debug && console.log("[i-input-sel] hasItemsValue(v) : v = ", v, ", isFound =", ret);
      return ret
    },
    getFirstValue() { // 목록의 첫번째 아이템 가져오기
      const firstItem = this.items_cp[0]
      this.debug && console.log("[i-input-sel] getFirstValue() : itemValueName =", T.objectDetail(this.itemValue), ", firstItem =", T.objectDetail(firstItem));
      if (T.isNU(firstItem)) return firstItem // null or undefined
      const v = firstItem[this.itemValue]
      if (v === undefined) {
        this.debug && console.log("[i-input-sel] getFirstValue() : ret =", firstItem, " -- firstItem[this.itemValue] == undefined 라서 그냥 firstItem 를 반환함. ")
        return firstItem;
      } else {
        this.debug && console.log("[i-input-sel] getFirstValue() : ret =", v)
        return v
      }
    },

    autoSelectByValue() { // value 변화에 따른 자동선택

      this.debug && console.log("[i-input-sel] autoSelectByValue() : this.value =", T.objectDetail(this.value), ", items_cp = ", T.objectDetail(this.items_cp));

      if (this.value === undefined) { // 현재값이 undefined 면 일하지 않는다.
        this.debug && console.log("[i-input-sel] autoSelectByValue() : 현재 value 가 undefined. 그래서 아무것도 안함")
        return
      }

      if (isEmptyArray(this.items_cp)) {  // 배열이 없으면 자동선택 자체를 할게 없다.
        this.debug && console.log("[i-input-sel] autoSelectByValue() : items_cp 가 비었음 아무것도 안함")
        return
      }

      if (this.hasItemsValue(this.value)) { // 일반적인 경우..
        this.debug && console.log("[i-input-sel] autoSelectByValue() : 값 있다. 할꺼 없음 ! this.value =", this.value)
        this.org_value = null
        this.myValue = this.value
        return
      }

      // 목록의 첫번째 값을 자동으로 선택한다.
      const firstValue = this.getFirstValue()
      if (T.isNU(firstValue)) {
        this.debug && console.log("[i-input-sel] autoSelectByValue() : firstValue =", firstValue, " 아무것도 안함");
        return
      }

      this.debug && console.log("[i-input-sel] autoSelectByValue() : 원본 값 보관 : 기존 org_value =", this.org_value, " 신규 org_value =", this.value)
      this.org_value = this.value // 나중에 items 가 업데이트 될 경우를 대비하여 원본 value 값 보관

      this.debug && console.log("[i-input-sel] autoSelectByValue() : firstValue = [", firstValue, "] EMIT !");
      this.myValue = firstValue
      if (this.emitFirstValue) this.emit(firstValue, "firstValue")

    },
    autoSelectByItems() { // items 변화에 따른 자동선택 ( 하아... 이런걸 왜 만들어야 하는지 ㅠ 뷰티파이.. 디자인에 현혹되어 ㅠ dog짜증 )

      this.debug && console.log("[i-input-sel] autoSelectByItems() : this.org_value =", this.org_value, "this.value =", this.value);

      if (this.value === undefined && this.org_value === null) { // 현재값이 undefined  이고 org_value 도 null 이면 일하지 않는다.
        this.debug && console.log("[i-input-sel] autoSelectByItems() : value === undefined 이고 org_value === null 이라서 아무것도 안함. 종료")
        return
      }

      if (isEmptyArray(this.items_cp)) { // 배열이 없으면 자동선택 자체를 할게 없다.
        this.debug && console.log("[i-input-sel] autoSelectByItems() : items_cp 가 없어서 자동선택 자체를 할 이유가 없음. 종료")
        return
      }

      // 저장해 두었던 org_value 를 먼저 확인해서 적용해야 한다.

      if (!T.isNU(this.org_value) && this.hasItemsValue(this.org_value)) { // 원본이 있고 변경된 items에 해당값이 있다면 그값을 사용하고 종료.
        this.myValue = this.org_value
        this.emit(this.org_value, "autoSelectByItems() -- 1") // org_value를 사용한다
        this.org_value = null // 보관한건 이제 필요 없다. 필수로 초기화
        return
      }

      // 아래의 경우가 실제로 존재할까 싶긴 하지만.. 일단은 그대로 둔다.

      if (!T.isNU(this.value) && this.hasItemsValue(this.value)) { // items가 변경되었더라도 이미 value가 있다면 아무것도 할 필요가 없다.
        this.debug && console.log("[i-input-sel] autoSelectByItems() : hasItemsValue. return 3")
        return
      }

      const firstValue = this.getFirstValue()
      this.org_value = this.value

      this.debug && console.log("[i-input-sel] autoSelectByItems() : org_value가 list에 없다. firstValue로 설정 V = ", firstValue)
      this.myValue = firstValue
      if (this.emitFirstValue) this.emit(firstValue)

    },

    emit(value, from = '') {
      if (value === this.value) {
        this.debug && console.log("[i-input-sel] emit() from["+from+"] value=["+value+"], value=["+this.value+"] 현재값과 동일하여 emit 취소 ")
        return
      }
      this.debug && console.log("[i-input-sel] emit() from["+from+"] value=["+value+"], value=["+this.value+"]")
      this.$emit('input', value)
      // this.sendEmit++
    },
    // isMyEmit() {
    //   if (this.sendEmit > 0) {
    //     this.debug && console.log("[i-input-sel] 내가 보낸 emit 이다. 패쓰한다. ", this.sendEmit)
    //     this.sendEmit--
    //     return true
    //   }
    //   return false
    // },
  },
}
</script>
