<!-- tinymce 웹데이터 컴포넌트 랩핑 -->
<template>
  <!--  https://www.tiny.cloud/docs/integrations/vue/ -->
  <Editor
      style="width: 100%"
      ref="ref_tiny"
      v-bind="$attrs"
      v-on="$listeners"
      api-key="fe0y901m81ya8heur0k7zkcsvv7pwdus8hnji8b7604xshfv"
      :init="myInit"
  />
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
// -----------------------------------------------------------------
// -- tinymce self-host - https://wiki.imdgo.com/pages/viewpage.action?pageId=164299009
// -- 근데 이거 문제가 self-host 하면 이미지 클릭시 리사이즈 하는 기능이 작동하지 않는다..
// -- self-host 해도 이미지 클릭시 리사이즈 되게 하는거 -- https://wiki.imdgo.com/pages/viewpage.action?pageId=164299019
// -----------------------------------------------------------------
// import "tinymce/tinymce.min.js"; // 기본 -- // npm install tinymce@5.10.3
import "./editor/tinymce.min.js";  // 로컬에서 사용하도록 ./editor/다운기록.md  -- https://wiki.imdgo.com/pages/viewpage.action?pageId=164299019

import "tinymce/skins/ui/oxide/skin.min.css"; // ui 테마 1/2
import "tinymce/skins/ui/oxide/content.min.css"; // ui 테마 2/2
import "tinymce/themes/silver/theme.min.js"; // 테마
import 'tinymce-i18n/langs5/ko_KR.js' // npm install tinymce-i18n
import "tinymce/icons/default/icons.min.js"; // 아이콘
import 'tinymce/plugins/imagetools/plugin.min.js' // 이하 사용하는 플러그인
import 'tinymce/plugins/table/plugin.min.js'
import 'tinymce/plugins/media/plugin.min.js'
import 'tinymce/plugins/fullscreen/plugin.min.js'
import 'tinymce/plugins/code/plugin.min.js'
import 'tinymce/plugins/searchreplace/plugin.min.js'
import 'tinymce/plugins/link/plugin.min.js'
import 'tinymce/plugins/lists/plugin.min.js'
import 'tinymce/plugins/autolink/plugin.min.js'
import 'tinymce/plugins/codesample/plugin.min.js'
import 'tinymce/plugins/help/plugin.min.js' // 각 버튼들 마우스 오버시 설명 표시

// 미사용 플러그인
// import 'tinymce/plugins/image/plugin.min.js'
// import 'tinymce/plugins/wordcount/plugin.min.js'
// import 'tinymce/plugins/visualblocks/plugin.min.js'
// import 'tinymce/plugins/anchor/plugin.min.js'
// import 'tinymce/plugins/preview/plugin.min.js'
// import 'tinymce/plugins/charmap/plugin.min.js'
// import 'tinymce/plugins/advlist/plugin.min.js'



import axios from "axios";
// import tinymce from "tinymce";
import {tinymce_default_init, addImageUploadButton, addPasteImage} from "./s-editor-options"

export default {
  components: {Editor},
  inheritAttrs: false,
  props: {
    height: {},
    placeholder: {type: String},
    // autoFocus: {type: Boolean, default: false},
  },
  data: function () {
    return {
      myInit: this.mergeInit(tinymce_default_init),
    }
  },
  mounted: function () {
    // setTimeout(()=> {
    //   // -- execCommand :         https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.root/#execCommand
    //   // -- execCommand 종류 :    https://www.tiny.cloud/docs/tinymce/6/editor-command-identifiers/
    //   // -- editor 매서드 종류 :  https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.html.writer/#getContent
    //   // this.$refs.ref_tiny.editor.execCommand("mceInsertContent",'false',"<div>qqqqqqqqqqqqqqqqq1123</div>");
    //   const ctx = this.$refs.ref_tiny.editor.getContent()
    //   console.log(`[Editor] getContent = ${ctx}`)
    // }, 5000)
  },
  methods: {
    mergeInit: function (init) {
      const newInit = {
        ...init,
        images_upload_handler: this.images_upload_handler,
        setup: (editor) => { // https://www.tiny.cloud/docs/configure/integration-and-setup/#setup
          // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#basicbuttonexampleandexplanation
          addImageUploadButton(editor, this.images_upload_handler)
          addPasteImage(editor, this.images_upload_handler)
          editor.on("keyup", e=> {
            // 크롬/macOS 에서 Backspace, Delete 키는 @input 이 발생되지 않아서 수동으로  발생시킴
            // console.log(e)
            if (e.key === "Backspace" || e.key === "Delete") {
              console.log("*** [tiny editor keyup] ----- ", e.key)
              this.update();
            }
          })
          editor.on("keydown", e=> {
            if (e.key === 's' && e.metaKey) {
              e.preventDefault();  // 기본 동작 방지
              // console.log('Command + S가 감지되었습니다.');
              this.$emit('save', this.getContent())
            }
          })

        },
        // save_onsavecallback: () => { this.$emit('save', this.getContent()) },
      }
      if (this.height) newInit.height = this.height
      if (this.placeholder) newInit.placeholder = this.placeholder
      return newInit
    },
    setContent: function() {
      this.$refs.ref_tiny.editor.setContent(value)
    },
    getContent: function() {
      return this.$refs.ref_tiny.editor.getContent()
    },
    update: function() {
      this.$emit('input', this.getContent())
    },

    // https://www.tiny.cloud/docs/general-configuration-guide/upload-images/
    /**
     * 서버로 이미지 업로드. 4가지 경우에 호출된다.
     * - A: tinymce 기본 플러그인 image = 이미지 업로더에서 호출 : init.images_upload_handler 로 설정
     * - B: tinymce 기본 플러그인 imagetools = 이미지 편집툴에서 호출 : init.images_upload_handler 로 설정된걸 같이 쓰는듯
     * - C: 내가 만든 이미지 업로더에서 호출 : addImageUploadButton
     * - D: 내가 만든 이미지 붙여넣기 에서 호출 : addPasteImage
     * @param blobOrFile
     * @param success
     * @param failure
     * @param progress
     * @param mode {"addImageUploadButton" | "addPasteImage" | undefined} 위의 C,D 를 위한 변수
     * @returns {Promise<void>}
     */
    images_upload_handler: async function(blobOrFile, success, failure, progress, mode) {
      // 내가 만든건 filetype으로 넘어오고. tinymce 기본제공은 blob 로 넘어온다.
      let isFileType
      if (mode === "addImageUploadButton") {
        isFileType = true
      } else if (mode === "addPasteImage") {
        isFileType = true
      } else {
        isFileType = blobOrFile instanceof File
      }

      console.log(" - instanceof File = ", isFileType)
      console.log(" - blobOrFile = ", blobOrFile)
      const fitem = {}
      if (isFileType) { // 내가 만든거에서 사용된 경우 ( 일반적인 input type=file
        const blob = new Blob([blobOrFile], { type: `${blobOrFile.type}`});
        fitem.name = blobOrFile.name
        fitem.size = blobOrFile.size
        fitem.type = blobOrFile.type
        fitem.blob = blob
      } else { // 기본 tinymce 이미지 업로더에서 호출된 경우
        // if (blobOrFile.filename instanceof Function) {
        //   console.log(" - blobOrFile.filename() = ", blobOrFile.filename())
        // }
        const blob = blobOrFile.blob()
        console.log(" - blob = ", blob)
        // 이미지 툴로 편집된경우 blobOrFile.filename() 으로만 이름을 구할 수 있다. 보통 "imagetools1.png" 이다.
        fitem.name = blobOrFile.filename instanceof Function ? blobOrFile.filename() : blob.name
        fitem.size = blob.size
        fitem.type = blob.type
        fitem.blob = blob
        if (!fitem.name && fitem.type) {
          fitem.name = "blob." + fitem.type.split(".")
        }
      }

      console.log(" - fitem.name = ", fitem.name) // blobOrFile.name =  Koala.jpg
      console.log(" - fitem.size = ", fitem.size) // blobOrFile.size =  780831
      console.log(" - fitem.type = ", fitem.type) // blobOrFile.type =  image/jpeg


      if (!fitem.blob || fitem.size === undefined || fitem.size < 1) {
        failure(`
파일을 업로드 할 수 없습니다.

isFile = ${isFileType}
name   = ${fitem.name}
size   = ${fitem.size}
type   = ${fitem.type}
        `)
        return
      }

      // 이미지 width, height 구하기
      const bitmap = await createImageBitmap(fitem.blob)
      const width = bitmap.width
      const height = bitmap.height

      // let idx = vm.quill.getSelection() ? vm.quill.getSelection().index : 0;
      let _form = new FormData();
      // let _inner = $("#fileKey").val();

      _form.append('upload', fitem.blob, fitem.name);
      // _form.append('paramText', _inner);
      // _form.append('resultData', idx);
      _form.append('resultData', 0);
      // _form.append('defaultView', _inner);
      _form.append('reason', 'editor');

      // {"name":"코알라.jpg","size":260462,"type":"image/jpeg","ext":null,"width":640,"height":480,"color":null}
      // {"name":"Koala.jpg","ext":null,"width":640,"height":480,"color":null}
      _form.append('paramOption', JSON.stringify({
        name      : fitem.name,
        size      : fitem.size, // getBytes().length, // .size,
        type      : fitem.type,
        sizeText  : humanFileSize(fitem.size), //blobOrFile.sizeText,
        ext       : null,
        width     : width,
        height    : height,
        color     : null
      }));

      // axios.post("/api/img/upload", _form, { headers: { 'Content-Type': 'multipart/form-data' }})
      axios.post("/api/editor/upload", _form, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then(res => {
            // console.log(" - res = ", res)
            // console.log(" - path = ", res?.data?.path)
            // let _data = res.data.result;
            // let _onePath = _data.substring(0,8);
            // let _filnalPath =  "/img/" + _onePath + "/" + _data;
            const baseAddress = location.href.substring(0, location.href.indexOf(location.pathname)) ?? ''
            const img_src = baseAddress + res?.data?.path;
            console.log(" - img_src = ", img_src)
            success(img_src, fitem.name)
            // vm.quill.insertEmbed(idx, 'image', _filnalPath);
            // vm.quill.setSelection(idx + 1);

            // if (res.data.code == 0) {
            //   success(res.data.data.url)
            // } else {
            //   failure('없로드 실패')
            // }
          })
          .catch(e => failure('업로드 오류 : ' + e))
    },
  }
}

function humanFileSize(bytes, si=true, dp=0) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + ' ' + units[u];
}



</script>
