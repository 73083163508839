import VueRouter from "vue-router";
import {getCurrentInstance} from "vue";


/** [setup() 전용] getCurrentInstance() 로부터 router 를 가져온다. <pre>
 * 안타까운 점은 composition api 에서는 무조건 null 이 나온다.
 * setup() 안쪽에서만 사용 가능하다.
 * script setup에서는 import {useRoute, useRouter} from "vue-router/composables" 를 하면 된다.
 * </pre> */
export const getRouterByInst = () : VueRouter | null => {
    const inst = getCurrentInstance()
    // console.log(`inst = `, inst);
    if (!inst) return null
    
    const { proxy } = inst
    if (proxy?.$router) return proxy.$router
    if (proxy?.$root?.$router) return proxy?.$root?.$router
    if (proxy?.$root?.$options?.router) return proxy?.$root?.$options?.router
    return null
}