
// https://www.tiny.cloud/docs/configure/integration-and-setup/
// 아쉽게도 vue 2 에서는 tinymce 5 가 최대버전 이다.

// import tinymce from "tinymce";
// import Swal from 'sweetalert2'

export const tinymce_default_init = {
  language: 'ko_KR',
  height: 500,
  menubar: false,
  // statusbar: false,
  // plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save
  // directionalityadvcode visualblocks visualchars fullscreen image link media mediaembed template codesample
  // table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount
  // tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen
  // pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable export',
  //
  plugins: [
    // advlist, charmap,  preview,  anchor, visualblocks, help, wordcount, image,   
    ' autolink, lists, link, help',
    'searchreplace, code, fullscreen, media, table',
    'code, imagetools codesample' // save, 
    // save 플러그인 사용시 init 에 save_onsavecallback: () => { this.$emit('save', this.getContent()) }, 를 추가해서 사용
    // 그런데 그냥 keydown 이벤트가 편한듯
  ],
  // help -- 각 버튼들 마우스 오버시 설명 표시
  // advlist -- 순서없는 리스트, 순서있는 리스트
  // autolink -- link 자동으로 걸어주는 기능
  // lists -- 순서없는 리스트, 순서있는 리스트
  // charmap -- 특수문자
  
  
  
  // -- https://www.tiny.cloud/docs/demo/full-featured/
  // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect |
  // alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist |
  // forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons |
  // fullscreen  preview save print | insertfile image media pageembed template link anchor codesample |
  // a11ycheck ltr rtl | showcomments addcomment',

  // toolbar:
  //   'undo redo | formatselect | bold italic backcolor | \
  //   alignleft aligncenter alignright alignjustify | \
  //   bullist numlist outdent indent | removeformat | code | image',

  toolbar:
    `formatselect |
    bold italic underline strikethrough |
    forecolor backcolor removeformat  |
    image-imd media a11ycheck link code codesample fullscreen |
    table |
    alignleft aligncenter alignright alignjustify |
    numlist bullist
    `, // image basicDateButton

  // contextmenu: false, // 우클릭메뉴 비활성화 - 이렇게 하면 원래의 우클릭 기능이 자동하여 복사하기, 붙여넣기 기능등이 메뉴에 나온다.
  cleanup: true,
  // language_url: './tinymce/langs/ko_KR.js',
  // content_css: true,
  fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',
  lineheight_formats: '0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5', // 行高配置，也可配置成"12px 14px 16px 20px"这种形式
  branding: false,
  // resize: false, // true , false,'both' https://www.tiny.cloud/docs/tinymce/6/editor-size-options/#controlling-user-editor-resizing
  elementpath: false, // 하단 status 바에 마크업 경로표시 기능 ex) table > tr > td
  content_style: 'p {margin-block-start: 0; margin-block-end: 0; color: #606D81; font-size: 14px;}; table { border: 1px}',
  body_class: 'editor-ctx',
  // css 설정방법  https://www.tiny.cloud/docs/configure/content-appearance/#content_css
  content_css: '/css/editor-ctx.css',
  // content_css: [
  //   '../../../design/common/css/normalize.css',  // 2023-07-18 에디터와 실제 화면에 보이는 것이 차이가 있다고 하여, 화면에 적용되는 css를 에디터에 적용.
  //   '../../../design/common/css/editorTiny.css?v=2023-11-28', // 2023-08-23 에디터에 스크롤이 나오도록 조치. (normalize.css 때문에 스크롤이 안나오는 것을 보완)
  // ],


  paste_data_images: true,
  // images_upload_handler: (blobInfo, success, failure) => {}
  image_uploadtab: true, //  -- https://www.tiny.cloud/docs/plugins/opensource/image/#image_uploadtab
  // 이미지 업로드 탭이 먼저 나오도록 하는 방법 (vue에선 안됨) -- https://stackoverflow.com/questions/66078766/make-upload-tab-the-default-in-insert-edit-image-dialog
  // a11y_advanced_options: true, // 웹 접근성 옵션
  // image_caption: true,
  // image_advtab: true,
  // image_title: true,
  // image_dimensions: false, // 이미지 사이즈 항목 보여줄까?


  // https://www.tiny.cloud/docs/plugins/opensource/imagetools/
  // -- https://www.tiny.cloud/docs/plugins/opensource/imagetools/#exampleusingimagetools_toolbar
  // imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions'
  imagetools_toolbar: 'editimage imageoptions'
  // imagetools_proxy: 'proxy.php'
  // imagetools_cors_hosts: ['mydomain.com', 'otherdomain.com'],
  // imagetools_credentials_hosts: ['mydomain.com', 'otherdomain.com']

  // imagetools_fetch_image: function (img) {
  //   return new tinymce.util.Promise(function (resolve) {
  //     // Fetch the image and return a blob containing the image content
  //   ...
  //     resolve(new Blob(...));
  //   });
  // }

}




export const addImageUploadButton = (editor, images_upload_handler) => {
  editor.ui.registry.addButton("image-imd", {
    icon: "image",
    // text: "이미지",
    tooltip: "이미지를 업로드 합니다",
    onAction: () => {
      // tslint:disable-next-line:no-console
      const dialogConfigIU = dialog_custom_img_upload(editor, images_upload_handler);
      editor.windowManager.open(dialogConfigIU);
    },
  });
}
const dialog_custom_img_upload = (editor, images_upload_handler) => {
  return {
    title: "사진등록",
    body: {
      type: "panel",
      items: [
        // {type: 'input'   , name: 'alt_text'  , label: 'alt 문자열 입력'},
        {type: "dropzone", name: "fileinput", label: ""},
      ],
    },
    buttons: [
      {type: 'cancel', name: 'closeButton', text: 'Cancel'},
      // {type: 'submit', name: 'submitButton', text: 'save', primary: true}
    ],
    // initialData: {
    //   // catdata: 'initial Cat',
    //   alt_text: '',
    // },
    onChange(api) {
      api.block("업로드중...") // 다이알로그 블럭 시작
      uploadImg(api, editor, images_upload_handler);
    },
  };
};

function escapeQuot(str) {
  if (str === undefined || str === null) return ""
  return str
    .replaceAll('"', '&quot;')
    .replaceAll("'", "\'")
}

export function uploadImg(api, editor, images_upload_handler) {
  const data = api.getData();
  const image = data.fileinput[0];
  // const alt_text = data.alt_text
  // const fileExt = image.name.split(".")[1];

  // 업로드 성공시
  const successCb = (uploadPath, filename) => {

    // Swal.fire({
    //   closeOnEsc: false, // esc 로 닫기 금지
    //   closeOnClickOutside: false, // 바깥에 클릭으로 닫기 금지
    //   title: "이미지 설명입력",
    //   icon: uploadPath,
    //   className: "s-show-icon",
    //   text: `시각장애인을 위해서 업로드 하신 "${filename}" 파일의 대체설명(ALT)을 입력해 주세요.`,
    //   content: {
    //     element: "input",
    //     attributes: {
    //       placeholder: "이미지 설명",
    //       type: "text",
    //       value: "",
    //     },
    //   },
    //   buttons : {
    //     confirm : {text : "확인", value : true, visible : true},
    //     // cancel : {text : "취소", value : false, visible : true}
    //   }
    // }).then(result=>{
    //   console.log("result = ", result)
    //
    //   let alt_text = escapeQuot(result)
    //   if (alt_text === "") {
    //     alt_text = filename
    //   }
    //
    //   console.log("uploadPath =======", uploadPath)
    //
    //   editor.insertContent(`<img src=${uploadPath} class="img-t" alt="${alt_text}" style="max-width: 100%"/>`);
    //   api.unblock()  // 다이알로그 블럭 해제
    //   api.close() // 다이알로그 닫기
    //
    // })

    const alt_text = escapeQuot(filename)
    editor.insertContent(`<img src=${uploadPath} class="img-t" alt="${alt_text}" style="max-width: 100%"/>`);
    api.unblock()  // 다이알로그 블럭 해제
    api.close() // 다이알로그 닫기


//     const tmp = prompt(`시각장애인을 위해서 업로드 하신
// "${filename}" 파일에 대한
// 설명(ALT=대체설명)을 입력해 주세요.`);
//     let alt_text = escapeQuot(tmp)
//     if (alt_text === "") {
//       alt_text = filename
//     }

    // editor.execCommand(
    //   "mceInsertContent",
    //   false,
    //   `<img src=${uploadPath} class="img-t" alt="${alt_text}" style="max-width: 100%"/>`
    // );
    // editor.insertContent(`<img src=${uploadPath} class="img-t" alt="${alt_text}" style="max-width: 100%"/>`);
    // api.unblock()  // 다이알로그 블럭 해제
    // api.close() // 다이알로그 닫기
  }

  // 업로드 실패시
  const failureCb = (errMsg) => {
    alert(errMsg) // 오류메세지 그대로 출력
    api.unblock() // 다이알로그 블럭 해제
    api.close() // 다이알로그 닫기
  }

  images_upload_handler(image, successCb, failureCb, undefined, "addImageUploadButton")

}

export function addPasteImage(editor, images_upload_handler) {
  editor.on('paste', function (e) { // -- https://www.tiny.cloud/docs/advanced/events/
    const imageBlob = retrieveImageFromClipboardAsBlob(e);
    if (!imageBlob) {
      return;
    }
    e.preventDefault();

    // console.log(" imageBlob = ", imageBlob)
    // console.log(" imageBlob JSON = ", JSON.stringify(imageBlob))

    // 업로드 성공시

    const successCb = (uploadPath, filename) => {

      // swal({
      //   closeOnEsc: false, // esc 로 닫기 금지
      //   closeOnClickOutside: false, // 바깥에 클릭으로 닫기 금지
      //   title: "이미지 설명입력",
      //   icon: uploadPath,
      //   className: "s-show-icon",
      //   text: `시각장애인을 위해서 지금 붙여넣기 하신 이미지의 대체설명(ALT)을 입력해 주세요.`,
      //   content: {
      //     element: "input",
      //     attributes: {
      //       placeholder: "이미지 설명",
      //       type: "text",
      //       value: "",
      //     },
      //   },
      //   buttons : {
      //     confirm : {text : "확인", value : true, visible : true},
      //     // cancel : {text : "취소", value : false, visible : true}
      //   }
      // }).then(result=>{
      //   console.log("result = ", result)
      //
      //   let alt_text = escapeQuot(result)
      //   if (alt_text === "") {
      //     alt_text = filename
      //   }
      //
      //   editor.insertContent(`<img src=${uploadPath} alt="${alt_text}" style="max-width: 100%"/>`);
      // })

      let alt_text = escapeQuot(filename)
      editor.insertContent(`<img src=${uploadPath} alt="${alt_text}" style="max-width: 100%"/>`);

//       const tmp = prompt(`시각장애인을 위해서 지금 붙여넣기 하신
// 이미지에 대한 설명(ALT=대체설명)을 입력해 주세요.`);
//
//       let alt_text = escapeQuot(tmp)
//       if (alt_text === "") {
//         alt_text = filename
//       }
//
//       editor.insertContent(`<img src=${uploadPath} alt="${alt_text}" style="max-width: 100%"/>`);

      // editor.execCommand(
      //   "mceInsertContent",
      //   false,
      //   `<img src=${uploadPath} class="img-t" alt="${alt_text}" style="max-width: 100%"/>`
      // );
    }

    // 업로드 실패시
    const failureCb = (errMsg) => {
      alert(errMsg)
    }

    images_upload_handler(imageBlob, successCb, failureCb, undefined, "addPasteImage")


    // uploadFile(imageBlob, function (response) {
    //   if ('location' in response) {
    //     if (editor) {
    //       // console.log('upload completed', response);
    //       editor.insertContent('<img src="' + response.location + '" />');
    //     } else {
    //       console.log('Tinymce editor not found!');
    //     }
    //   }
    // });

  });
}

function retrieveImageFromClipboardAsBlob(pasteEvent) {

  if (pasteEvent.clipboardData === false) {
    return false;
  }

  const items = pasteEvent.clipboardData.items;

  if (items === undefined) {
    return false;
  }

  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") === -1) {
      return false;
    }
    const blob = items[i].getAsFile();
    if (blob !== null) {
      return blob;
      // const reader = new FileReader();
      // reader.onload = function(e) {
      //   // console.log('result', e.target.result);
      // };
      // reader.readAsDataURL(blob);
      // return blob;
    }
  }
  return false;
}
