<template>
  <q-btn
    no-wrap
    :padding="dpTitle ? '1px 8px' : '0 2px'"
    class="imd-i-btn shadow-0"
    :class="{
      font_mal: size !== '12px'
    }"
    v-bind="{...$attrs, size, icon}"
    v-on="{...$listeners}"
    :label="dpTitle"
    v-shortkey="keys" @shortkey="emitClick"
  >
    <slot></slot>

    <!-- 툴팁 지정시  -->
    <s-tooltip v-if="tip" :content="tip" />

  </q-btn>
</template>

<script>
const isMacOS = navigator?.platform?.indexOf('Mac') > -1;
export default {
  inheritAttrs: false,
  props: {
    title: {type: String},
    tip:   {type: String},
    size:  {type: String, default: '12px'},
    icon:  {type: String},
    skey:  {type: String},
  },
  data() {
    return {
      keys : null,
      canClickEmit : true, // click emit 가능 여부
    }
  },
  computed: {
    dpTitle() {
      if (this.$q.screen.lt.md) return this.title;
      if (!this.skey) return this.title;
      return isMacOS
        ? this.title + '(' + this.skey.toUpperCase()
            .replaceAll('CTRL','⌘') + ')'
        : this.title + '(' + this.skey + ')'
    }
  },
  created() {
    if (this.skey) {
      if (isMacOS) {
        this.keys = this.skey.toLowerCase()
            .split("+")
            .map(k => {
              if (k === "ctrl") return "meta";
              return k;
            })        
      } else {
        this.keys = this.skey.toLowerCase().split("+")
      }
    }
  },
  methods: {
    // emitClick(e) { this.$emit('click', e) },

    /** 0.5초 이내 연속 누름 무시 */
    emitClick: _.throttle(function(e) {
      this.$emit('click', e)
    }, 500, {trailing: false}),
  },
}
</script>
