import T from './index'

export default {

  /**
   * [Not null Function] value가 Not null (그리고 Not undefined)일때 cb(value)를 실행한다<pre>
   *   ex) T.NF(this.nextInput(e.target), v=>v.focus())
   * </pre>
   * @param {*} val 평가대상 값
   * @param {Function} cb value에 값이 있을때 실행할 콜백 함수
   * @param {*} defaultVal value에 값이 없을때 반환할 값. 기본 null
   * @returns {*} value에 값이 있으면 (null or undefined 아님) cb(value) 결과값. 없으면 defaultVal ( null )
   */
  NF: (val, cb, defaultVal = null) =>
    T.isNotNU(val) && typeof cb === 'function' ? cb(val) : defaultVal,

  /**
   * [Value Function] value에 값이 있을때(null undefined 0 '' 등 안됨) cb(value)를 실행한다<pre>
   *   T.NF랑 차이점은 주로 문장 출력용이기 때문에 기본값이 null이 아니라 '' 인 것과
   *   콜백함수 대신 문장입력이 가능하며 이때 $v 는 결과값 변수로 사용된다.
   *
   *   list.length 가 10개 또는 0개라고 가정할때
   *
   *   T.VF(list.length, v=>`총 ${ v } 개`)   == '10개' 없으면 ''
   *   T.VF(list.length, '$v 개')             == '10개' 없으면 ''       -- 함수대신 문장사용
   *   T.VF(list.length, '$v 개', '미등록')   == '10개' 없으면 '미등록' -- 기본값 변경
   * </pre>
   * @param {*} val 평가대상 값
   * @param {Function} cb value에 값이 있을때 실행할 콜백 함수
   * @param {*} defaultVal value에 값이 없을때 반환할 값. 기본 ''
   * @returns {*} value에 값이 있으면 (null or undefined 아님) cb(value) 결과값. 없으면 defaultVal ( null )
   */
  VF: (val, cb, defaultVal = '') => {
    if (val) {
      if (typeof cb === 'function') return cb(val)
      if (typeof cb === 'string') {
        return cb.replace(/\$v/g, val)
      }
    }
    return defaultVal
  },

  /**
   * [Value Bracket] 값이 있을때 공백+괄호를 쳐준다.<pre>
   *   T.VB(123)       === ' (123)'
   *   T.VB(null)      === ''
   *   T.VB(123, '건') === ' (123건)'
   *   </pre>
   * @param {any}    val    값
   * @param {string} surfix 단위?
   * @constructor
   */
  VB: (val, surfix='') => T.VF(val,' ($v'+surfix+')'),

  // flag(당일-now, 1개월 전-1m, 6개월 전-6m, 1년 전-1y)에 따라 날짜 반환
  doSetSearchDt: (flag, format = 'YYYY-MM-DD') => {
    // 당일
    if (flag === "now") {
      return dayjs().format(format)
      // 1개월(1m), 6개월(6m), 1년(1y)
    } else {
      return dayjs().subtract(T.left(flag, 1)
        , T.right(flag, 1) === 'm' ? 'months' : 'year')
        .format(format)
    }

  },

  codeText: (code, value) =>code[_.findIndex(code, {'value': value})].text,

  now: (format = 'YYYY-MM-DD', date) => {
    if (date !== undefined) return dayjs(date).format(format)
    return dayjs().format(format)
  },

  /**
   * 현재 달에서 몇달을 더한다
   * @param {number} month
   * @returns {string} YYYY-MM-DD 형식
   */
  getDateAddMonth: month => dayjs().add(month || 0, "M").format('YYYY-MM-DD'),

  /**
   * List중의 문자열로 된 JSON 을 parse 한다
   * @param {string[]} list
   * @param {string[]} keys
   * @returns {string[]}
   */
  listItemParseJson: (list, keys) => {

    if (T.isEmptyArray(list)) return list
    if (T.isEmptyArray(keys)) return list

    return list.map(item => {
      keys.forEach(key => {
        if (item[key]) {
          try {
            item[key] = JSON.parse(item[key])
          } catch(e) {}
        }
      })
      return item;
    })
  },


  /*
    랜덤 문자열을 만든다.
  */
  random: (characters, length) => {
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },

  az: "abcdefghijklmnopqrstuvwxyz",
  AZ: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  NU: "0123456789",

  /** 랜덤 : A-Z */
  randomAZ: length => T.random(T.AZ, length),
  /** 랜덤 : a-z A-Z 0-9 */
  randomAz0: length => T.random(T.AZ + T.az + T.NU, length),

  // TODO CHECK :: 작업대상
  selectNotNullUndefined: (...args) => {
    for (let i = 0; i < args.length; i++) {
      if (!T.isNU(args[i]))
        return args[i]
    }
    return null
  },

  // TODO CHECK :: 작업대상
  selectNotEmpty: (...args) => {
    for (let i = 0; i < args.length; i++) {
      if (T.isNU(args[i])) continue
      if (typeof args[i] == 'string' && args[i].trim().length < 1) continue
      return args[i]
    }
    return null
  },

  /**
   * sql의 case 문, 오라클의 decode 와 비슷한 기능<pre>
   *   T.case('a', 'a', '에이', 'b'))          == '에이'   -- 기준값에 해당하는걸 찾음
   *   T.case('c', 'a', '에이', 'b'))          == 'b'      -- args 가 홀수인 경우 기준값을 찾지 못하면 마지막 값을 반환
   *   T.case('c', 'a', '에이', 'b','비'))     == 'c'     -- args가 짝수인데 기준값을 못찾아서 입력값 'c' 그대로 반환
   *   T.case('a', ()=>'a', '에이', 'd', 'w')) == '에이'   -- 추천 ( 필요할때만 함수가 실행됨 )
   *   T.case(''  , 'a' == 'a', '에이', 'b'))  == '에이'   -- 비추 ( 구문 실행 후 결과 삽입방식 )
   *   T.case(null, 'a' == 'a', '에이', 'b'))  == '에이'
   * </pre>
   * @param {*} value 기준값. 만약 빈값을 넣으면
   * @param {...*} args
   * @returns {*}
   */
  case: (value, ...args) => {
    const compareMode = T.isNotEmpty(value) // 첫번째 값이 있으면 그것과 비교 모드. 아니라면 각각 구분체크
    for (let i = 0; i < args.length; i += 2) {
      const v1s = args[i]
      const v2 = args[i + 1]
      const v1 = (typeof v1s == 'function') ? v1s() : v1s
      if (v2 === undefined) {
        return v1
      }
      if (compareMode) {
        if (v1 == value) return v2
      } else {
        if (v1 === true) return v2
      }
    }
    return value
  },

  /**
   * 숫자와 입력 가능하도록 제한하는 함수
   */
  inputFilterNum: (event: KeyboardEvent) => {
    // console.log(`event.key: ${event.key}`);
    // 허용된 키: 숫자, 백스페이스, 탭, 이스케이프, 엔터, 하이픈
    const allowedKeys = ['Backspace', 'Tab', 'Escape', 'Enter', 'ArrowLeft', 'ArrowRight'];
    const isNumberKey = event.key >= '0' && event.key <= '9';
    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault(); // 기본 이벤트와 입력 이벤트를 막음
    }
  },
}
