export default {
  created () {
    if (this.value === undefined) { // 최초 생성시 1
      this.$emit("input", "")
      this.$nextTick(e=>{
        if (this.value === undefined) {
          this.$emit("input", "")
        }
      })
    }
  },
  beforeUpdate() {
    if (this.value === undefined) { // 최초 생성시 1 이 안되는 경우 이걸로 해야 됨. 둘다 필수
      this.$emit("input", "")
    }
  },
}
