<template>
  <q-markup-table
    class="imd-table"
    dense flat square
    separator = "cell"
  >
<!--  <q-table-->
<!--    :loading  = "mList.loading"-->
<!--    :columns  = "mHeaders"-->
<!--    :data     = "mList.items"-->
<!--  >-->

    <!-- 헤더 ( bypass ) -->
<!--    <template v-slot:header="props" v-if="typeof $scopedSlots['header'] !== 'undefined'"> &lt;!&ndash; 미지정시 원래 스타일을 사용한다. &ndash;&gt;-->
<!--      <slot name="header" :header="props"></slot>-->
<!--    </template>-->

    <thead>
      <tr>
        <template v-for="(h,i) in mHeaders">
          <th :align="h.align" :key="i">{{ h.label }}</th>
        </template>
      </tr>
    </thead>

    <!-- 목록 -->
    <template v-if="T.isNotEmptyArray(innerData.items)">
      <slot name="item" v-bind="{items: innerData.items}">
        <draggable
          v-model="innerData.items"
          tag="tbody"
          :animation="200"
          handle=".dhandle"
        >
        <tr v-for="(item,ix) in innerData.items" :key="ix" @click="itemclick(item, $event)" :class="{ 'selectTr' : i_isCurrentItem(item) }" >

          <template v-for="(col,i) in i_applyItemHeaders(item)">

            <!--  특정 컬럼 형식지정 <template v-slot:item-cust_no="{value}">{{ T.comma(value) }}</template>-->

            <template v-if="typeof $scopedSlots['item-td-'+col.name] !== 'undefined'">
              <slot :name="['item-'+col.name]"
                    :value="col.value"
                    :item="item"
                    :i="ix"
                    :_no="totCnt - ix"
              />
            </template>
            
            <td v-else-if="typeof $scopedSlots['item-'+col.name] !== 'undefined'"
                  :key="col.name"
                  :align="col.align"
                  :style="col.style"
                  :class="col.classes"
            >
              <slot :name="['item-'+col.name]"
                    :value="col.value"
                    :item="item"
                    :i="ix"
                    :_no="totCnt - ix"
              />
            </td>

            <!-- 미지정인 경우 -->

            <td v-else
                :key="col.name"
                :align="col.align"
                :style="col.style"
                :class="col.classes"
            >
              <!-- ellipsis ( ellipsis width ) 가 지정된 경우. 참고:  https://poiemaweb.com/css3-centering   -->
              <div v-if="col.ewidth" class="imd-vac-wrapper">
                <div class="imd-vac-content">
                  {{ col.value }}
                  <s-tooltip :content="col.value" position="down" />
                </div>
              </div>
              <template v-else>
                {{ col.value }}
              </template>
            </td>

          </template>
        </tr>
        </draggable>
      </slot>
    </template>
    <template v-else>
      <tbody>
        <tr>
          <td :colspan="mHeaders.length || 1">
            <slot name="no-data">
              <div class="text-center q-pa-sm">
                {{ noDataText }} <!-- 데이터가 없습니다.-->
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </template>


  </q-markup-table>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import stable_setup, { stable_props } from 'src/components-ui/stable/s-table-logic'
import draggable from 'vuedraggable'
export default defineComponent({
  name: 's-drag-table',
  components: {draggable},
  props: stable_props,
  setup(props, {emit}) {
    return stable_setup(props, emit, 'S-DRAG-TABLE')
  }
})
</script>



