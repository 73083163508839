<template>
  <div class="full-width">
    <q-splitter
      v-if="fix || $q.screen.gt.sm"
      v-model="opt.leftWidthValue"
      @input="v=>$emit('change', v)"
      class="full-width"
      :limits="[0,Infinity]"
      :unit="opt.unit"
    >
      <template v-slot:before><slot name="split1"/></template>
      <template v-slot:after><slot name="split2"/></template>
    </q-splitter>
    <template v-else-if="rightTopSm">
      <slot name="split2"/>
      <slot name="split1"/>
    </template>
    <template v-else>
      <slot name="split1"/>
      <slot name="split2"/>
    </template>
  </div>
</template>

<script>
/**
 * 기본 컨테이너
 */
export default {
  props: {
    /** 왼쪽 사이즈(%) 기본값 30%  */
    leftWidth: {type: String, default: '50%'},
    /** sm일때 우측이 위로 올라가게한다.  */
    rightTopSm: {type: Boolean, default: false},
    fix: {type: Boolean, default: false},
  },
  data() {
    return {
      opt: this.getOptionFromProp()
    }
  },
  watch: {
    leftWidth(n,p) {
      T.setOverwrite(this.opt, this.getOptionFromProp())
    },
  },
  methods: {
    getOptionFromProp() {
      const lw = this.leftWidth || '50%'
      const unit = lw.endsWith('%') ? '%' : 'px'
      const leftWidthValue = Number(lw.replace(/(%|px)$/,''))
      return {unit, leftWidthValue}
    },
  }
}
</script>
