// noinspection CommaExpressionJS
/**
 * 로깅 및 로거 생성
 */


export const jsonStringifyReplacer = (k,v) => {
  if (v === null) return "NULL"
  if (v === undefined) return "UNDEFINED"
  return v
}


/**
 * 로그용 문자열로 변환<pre>
 *   const user = {id: 'abc', name: 'abc'}
 *   const env = {aaa: 'bbb'}
 *   T.toLogStr({user, env}) === 'user = {"id":"abc","name":"abc"}, enb = {"aaa":"bbb"}'
 * </pre>
 * @param map map 형태 object
 * @param delimiter 구분자(기본: ', ')
 */
const logStr = (map, delimiter="\n : ") =>
  Object.entries(map).map(([k,v])=>
    k + ' = ' +  JSON.stringify(v, jsonStringifyReplacer)
  ).join(delimiter)

class Logger {
  private readonly head: string;
  private level: string

  isDebug = true
  isInfo  = true
  isWarn  = true
  isError = true

  constructor(head, level: 'debug' | 'info' | 'warn' | 'error' = 'debug') {
    this.head  = head
    this.level = level

    switch(level) {
      // @ts-ignore
      case 'error': this.isWarn  = false;
      // @ts-ignore
      case 'warn' : this.isInfo  = false;
      case 'info' : this.isDebug = false;
    }
  }

  debug(...args) {this.isDebug && console.log("D[" + this.head + "]", ...args)}
  info (...args) {this.isInfo  && console.log("I[" + this.head + "]", ...args)}
  warn (...args) {this.isWarn  && console.log("W[" + this.head + "]", ...args)}
  error(...args) {this.isError && console.log("E[" + this.head + "]", ...args)}

  debugV(funcName, map, delimiter ?: string) {this.isDebug && console.log("D[" + this.head + "]", funcName + '\n :', logStr(map, delimiter))}
  infoV (funcName, map, delimiter ?: string) {this.isInfo  && console.log("I[" + this.head + "]", funcName + '\n :', logStr(map, delimiter))}
  warnV (funcName, map, delimiter ?: string) {this.isWarn  && console.log("W[" + this.head + "]", funcName + '\n :', logStr(map, delimiter))}
  errorV(funcName, map, delimiter ?: string) {this.isError && console.log("E[" + this.head + "]", funcName + '\n :', logStr(map, delimiter))}

  status() {
    console.log(`로거상태 [${ this.head }]
      isDebug = ${ ''+this.isDebug }
      isInfo  = ${ ''+this.isInfo }
      isWarn  = ${ ''+this.isWarn }
      isError = ${ ''+this.isError }
    `)
  }
}


export default {
  /**
   * console.log bypass 이것의 사용처는 vue template 내에서다.<pre>
   *   <a @click="T.log('123')">test</a>
   * </pre>
   * @param {...*} args
   */
  log: (...args) => console.log(...args),


  logV: (...args) => {
    console.log(...args.map(v=>{
      if (_.isPlainObject(v)) return logStr(v)
      return v
    }))
  },

  log_ret: (cmt, val) => (console.log(cmt, val), val),

  /**
   * 로거 함수를 반환한다.<pre>
   *   const log = T.newLogger('module') // 로거함수 생성
   *   log('val =',123)                  // 로그 찍기
   *   (콜솔출력) "[module] val = 123"
   * </pre>
   * @param {string} head
   * @param {boolean} isEnable (기본 true) 사용여부
   * @returns {Function} 로그함수
   */
  newLogger: (head, isEnable= true) => isEnable
    ? (...args) => console.log("[" + head + "]", ...args)
    : () => {},

  getLogger: (head, level: 'debug' | 'info' | 'warn' | 'error' = 'debug') => new Logger(head, level),

}
