<template>
  <i-input
      v-model="timeValue"
      mask="time"
      w="130"
      disable
      class="imd-i-input-time q-pl-sm"
  >
    <template v-slot:after>
      <q-icon name="access_time" class="cursor-pointer q-ml-sm">
        <q-popup-proxy transition-show="scale" transition-hide="scale">
          <q-time v-model="timeValue" :minute-options="[0]" format24h>
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="확인" color="primary" flat />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
      <i-btn
          icon="close"
          @click="clearTime"
          tip="시간 값을 비웁니다"
          class="q-ml-sm"
          color="grey"
      />
    </template>
  </i-input>
</template>

<script>
export default {
  name: 'TimeInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeValue: this.value
    };
  },
  watch: {
    timeValue(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.timeValue = newValue;
    }
  },
  methods: {
    clearTime() {
      this.timeValue = '';
    }
  }
};
</script>

<style scoped>
/* 필요한 경우 스타일을 추가하세요 */
</style>
