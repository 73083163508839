<!--

  모달용 윈도우 i-bar

  특징 : 좌측영역 .window-header 클래스 기본 지정

  * 주의사항
    1. slot:left 에 버튼 추가하면 이상해진다.
    2. 창 넓이를 줄이면 i-bar 에 비해서 이상하게 줄여진다...
       윈도우 버전 modal 탑 i-bar 용도 외에는 쓰지 말자.

-->
<template>
  <q-bar dense class="i-bar imd-i-bar-win row q-pl-none shadow-1">
    <div class="col row q-gutter-x-sm no-wrap items-center window-header">
<!--      <div style="font-size: 12px;">•</div>-->
      <div v-html="title" class="imd-i-bar-win-title" style="white-space: nowrap"/>
      <div v-html="cmt" class="ellipsis imd-i-bar-cmt"/>
      <div class="col-auto imd-sub-btn-ml-4">
        <slot name="left"/>
      </div>
    </div>
    <div class="col-auto imd-sub-btn-ml-4 q-gutter-x-xs">
      <slot />
    </div>
  </q-bar>
</template>

<script>
export default {
  props: {
    title: {type: String},
    cmt:   {type: String},
    icon:  {type: String},
    titleClass: {},
  }
}
</script>

<style scoped>
.imd-sub-btn-ml-4 >>> button { margin-left: 2px; }
</style>
