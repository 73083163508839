<template>
  <div v-if="$q.platform.is.name === 'ie'" class="imd-warn">
    <div :style="{'margin-bottom': top ? '0':'50px'}">
      <span class="tit">
        <q-icon name="warning" color="red" size="30px"/>
        [주의]
      </span>
        현재 <u>IE ( 인터넷 익스폴로러 )</u> 를 사용중이십니다. IE 도 사용은 가능하지만 <u>속도가 느리고, 일부 기능이 작동하지 않으며 간헐적으로 오작동 위험</u>이 있습니다.<br/>
        &nbsp; &nbsp; &nbsp; IE가 아닌 다른 웹브라우저를 사용해 주세요.
        [다운로드 링크]
        <a href="https://www.google.co.kr/chrome/" target="_blank">구글 크롬</a>(권장),
        <a href="https://www.microsoft.com/ko-kr/edge" target="_blank">MS 엣지</a>,
        <a href="https://whale.naver.com/" target="_blank">네이버 웨일</a>,
        <a href="https://www.mozilla.org/ko/firefox/new/" target="_blank">파이어폭스</a>,
        <a href="https://www.opera.com/ko" target="_blank">오페라</a>, 기타등등
        <u>IE만 아니면</u> 됩니다
    </div>
  </div>
</template>
<script>
export default {
  props: {
    top: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.imd-warn {
  margin-top: 10px;
  font-family: 'Apple SD Gothic Neo','맑은 고딕','Nanum Gothic','돋움',Dotum,Helvetica,Sans-serif,serif;
  font-size: 15px;
  color: #FF8C00;
  /*line-height: 2;*/
}

@keyframes blink-effect { 50% { opacity: 0.4; } }
.imd-warn .tit {
  color: red; font-size: 20px; font-weight: bold;
  animation: blink-effect 1s step-end infinite;
}
.imd-warn u {
  color: red; font-size: 18px; font-weight: bold;
  animation: blink-effect 1s step-end infinite;
}

.imd-warn a {
  font-weight: bold;
}
</style>
