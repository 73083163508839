import {computed, onMounted, reactive, toRefs, watch} from "vue";
import {PropType} from 'vue';
import {
  ItemSearchType,
  ItemType,
  MapType,
  OnChangeDataType,
  QTablePaginationType,
  STableApiDetailFuncType,
  STableApiListFuncType,
  STableApiListType,
  STableDataType,
  STableDetailParamFuncType,
  STableListParamFuncType,
  STableLoadOption,
  STablePageingType,
} from "src/components-ui/stable/types_stable";
import {
  applyHeaderAttrs,
  applyHeaderFormats,
  convert_qtablePagination_to_stablePageing,
  convert_stablePageing_to_qtablePagination,
  default_apiDetailFunc,
  default_apiListFunc,
  default_listParamFunc,
  makeHeadersUseItems
} from "src/components-ui/stable/s-table-lib";
import co_list_box_selected from "../../lib/co-list-box-selected";


/**
 * 남은작업
 * - innerData , props 코멘트 달다가 말았음;;
 * - co-location-stable.ts 안에 있는걸 여기에 구현해야 하는거 아닐까 ? 일단 두자..
 *
 */


export const stable_props = {
  name:          { type: String                   }, // s-table의 이름(디버깅용)

  // 목록 정의
  list:          { type: Object as PropType<STableDataType>, default: () => ({})}, // 목록 sync 객체  -- :list.sync="list"
  listApi:       { type: String,                  }, // 가져올 url
  /** listParam이 없으면 detail는 아에 미작동. 지정된 경우 결과가 null,undefined 인 경우 api 호출없이 detailReset()  */
  listParam:     { type: Function as PropType<STableListParamFuncType>, default: default_listParamFunc}, // 위에 사용될 param
  listResConv:   { type: Function as PropType<(data:STableApiListType)=>{list,totalCount}>,}, // list api 에서 가져올때 data 직접변환. 결과로 {list,totalCount}가 나와야 한다.
  listJsonCols:  { type: Array,                   }, // list api 에서 가져올때 JSON.parse 실행할 컬럼들 지정
  listFilter:    { type: Function,                }, // 목록 필터

  pageinfo:      { type: Object as PropType<STablePageingType> }, // (필요시) 페이징 정보 동기화

  /** qsKeyItem 지정시 생략 가능 */
  keys:          { type: [String, Array],             }, // headers 의 keys, String일 경우 컴마구분 ( 삭제예정 )
  // keys_array:    { type: Array as PropType<string[]>, }, // 앞으로는 이걸로 쓰자.
  headers:       { type: [String, Array],             }, // headers 정의
  formats:       { type: Object as PropType<{[key:string] : string | ((v)=>string)}>, }, // 셀 포멧 지정. 문자열 또는 함수. 문자열인경우 s_table_format 에서 뽑아쓴다.

  // detail 사용시
  detailApi:     { type: String,                  }, // detail api url
  detailParam:   { type: Function as PropType<STableDetailParamFuncType>, }, // detail url 에 사용될 param
  detail:        { type: Object as PropType<MapType>, }, // 클릭시 detail 객체 ( innerData.ditem 과 동기화 ) -- :detail.sync="ditem"
  detail_default:{ type: Object as PropType<MapType>, }, // detail (ditem) 기본값(map)

  // on/off 옵션
  onepage:       { type: Boolean, default: false, }, // 페이징 없이 한 페이지로 보여주기
  autoload:      { type: Boolean, default: false, }, // 자동로드. 처음에 load()를 호출하지 않아도 mount 되었을때 바로 load() 한다.
  autoHeader:    { type: Boolean, default: false, }, // 서버에서 가져온 데이터로 자동으로 헤더를 만든다.
  hideEmptyTr:   { type: Boolean, default: false, }, // 빈 tr 감추기
  hideHeader:    { type: Boolean, default: false, }, // 헤더 감추기
  hideFooter:    { type: Boolean, default: false, }, // 푸터 감추기
  nowrap:        { type: Boolean, default: false, }, // 전체 nowrap
  watchItems:    { type: Boolean, default: false, }, // list.items 감시여부
  debug:         { type: Boolean, default: false, }, // 디버깅 여부

  // 스타일 속성 옵션
  headerThClass: {}, // 헤더에 tr에 지정할 css class
  limitEmptyTr:  { type: Number, default: 30, }, // 보여주는 줄수가 해당 갯수를 넘어가면 빈 tr을 보여주지 않는다.

  // 서버통신 정의 (보통은 필요 없는데 테스트(가상 서버 통신)를 위해서 그냥 만들었다)
  apiListFunc:    { type: Function as PropType<STableApiListFuncType>  , default: default_apiListFunc  }, // 목록용
  apiDetailFunc:  { type: Function as PropType<STableApiDetailFuncType>, default: default_apiDetailFunc}, // 상세용

  onChangeData:   { type: Function as PropType<OnChangeDataType>}, // (목록 또는 상세) 데이터 변경시

  // qsKeyPage:      { type: [Object, String], default: () => ({pageNo: 'pageNo', pageSize: 'pageSize', orderBy: 'orderBy', orderDesc: 'orderDesc'})}, // qs 에 지정할 페이징 키
  // qsKeyItem:      { type: [Object, String]}, // qs 에 지정할 item 키
  // [qsKeyPage, qsKeyItem 를 props 로 못하는 이유]
  //   qs 를 해석하기 위해 ( T.queryStringToMap(numberMap) ) 서는 파라미터 타입을 먼저 확인해야 한다.
  //   이때 stable_default 를 생성하는데 기본값들의 key 들을 정할때 (컨버트 할때) qsKeyPage가 필요하다.
  //   그런데 이 작업은 컴포넌트들이 마운트 되기 전이라 ref 를 사용할 수 없기에 props 자체를 읽을 수 있는 방법이 전혀 없다.
  //   그래서 qsKeyPage 는 반드시 state 에서만 선언 가능하다....

  noDataText:     { type: String, default: "데이터가 없습니다."},

  convQsToData:   { type: Function },
  convDataToQs:   { type: Function },
}



const stable_setup = (props, emit, name = '') => {

  const props_list = props.list as STableDataType

  // 내부 함수정의
  const apiListFunc   = props.apiListFunc as STableApiListFuncType   // 목록조회 함수
  const apiDetailFunc = props.apiDetailFunc as STableApiDetailFuncType // 상세조회 함수
  const _loggerName = name + T.NF(props.name || props.list.name, v => ' ' + v, '')
  const logLevel = typeof props_list.debug === 'boolean' ? (props_list.debug ? 'debug' : 'info')
                 : typeof props_list.debug === 'string' ? props_list.debug
                 : props.debug ? "debug" : "info"
  const log = T.getLogger(_loggerName, logLevel)
  const log_p = T.getLogger(_loggerName + "_p", "info") // 페이징용 로그

  //--------------------------------------------------------------------------------------------------------------------
  //-- STATE 만들기
  //--------------------------------------------------------------------------------------------------------------------
  log.debug("-- setup() 시작 -----------------------------------------------------------------")

  //---[ headers 작업 ]-------------------------------------------------------------------------------------------------
  const headersSrc = props_list.headers || props.headers  // props.headers 우선. 없다면 props_list.headers
  const formats    = props_list.formats || props.formats // props.headers 우선. 없다면 props_list.headers
  const headerArr  = _.isArray(headersSrc) ? headersSrc  // 이미 배열이라면 아무것도 안하고
    : typeof (headersSrc) === "string" ? T.makeJson(headersSrc) // 문자열이라면 makeJson 으로 배열로 만들고
      : makeHeadersUseItems(props?.list?.items) // 그래도 없다면 데이터(items)의 첫 row 의 key 들로 만든다.
  const headers = applyHeaderFormats(headerArr, formats, log) // 헤더 배열에 포멧을 적용
  const {mHeaders, headerIndex} = applyHeaderAttrs(headers, props.nowrap, 'setup', log) // 기타 속성 적용


  //---[ pageing 작업 ]-------------------------------------------------------------------------------------------------
  const pageing: STablePageingType = {
    // 페이징 정보
    pageNo:     props_list.pageing?.pageNo     || props.pageinfo?.pageNo     || 1,
    pageSize:   props_list.pageing?.pageSize   || props.pageinfo?.pageSize   || 10,
    orderBy:    props_list.pageing?.orderBy    ?? props.pageinfo?.orderBy    ?? undefined,
    orderDesc:  props_list.pageing?.orderDesc  ?? props.pageinfo?.orderDesc  ?? true,
    totalCount: props_list.pageing?.totalCount || props.pageinfo?.totalCount || 0,
  }
  log_p.isDebug && log_p.debugV("setup pageing", {
    "props_list.pageing?.pageNo  " : props_list.pageing?.pageNo,
    "props.pageinfo?.pageNo      " : props.pageinfo?.pageNo,
    "최종 pageing.pageNo         " : pageing.pageNo,
    "props_list.pageing?.pageSize" : props_list.pageing?.pageSize,
    "props.pageinfo?.pageSize    " : props.pageinfo?.pageSize,
    "최종 pageing.pageSize       " : pageing.pageSize,
  })

  //---[ keys, keys_array 작업 ]----------------------------------------------------------------------------------------

  let keys_array = props_list.keys_array || props.keys_array
  const keys = props_list.keys || props.keys
  log.debugV("keys", {keys, props_list_keys: props_list.keys, props_keys: props.keys})
  if (T.isEmptyArray(keys_array)) {
    keys_array = T.stringToArray(keys)  // key 의 type 에 따라서 배열을 만든다
  }
  // let keys_array = T.stringToArray(keys)  // key 의 type 에 따라서 배열을 만든다

  const qsKeyItem = props_list.qsKeyItem
  // 의미없다 이거..
  // let qsKeyItem = props.qsKeyItem || props_list.qsKeyItem
  // if (T.isNU(qsKeyItem) && T.isNotEmptyArray(keys_array)) { // 만약 qsKeyItem 이 비어있고, 대신 keys_array 가 있으면 그걸로 만든다.
  //   qsKeyItem = keys_array.reduce((map,key)=>T.mapPut(map, key, key), {})
  // }

  if (T.isEmptyArray(keys_array) && T.isNotEmptyMap(qsKeyItem)) { // 반대로 keys_array 가 비더있고 qsKeyItem 이 있으면 그걸로 keys_array 를 만든다.
    keys_array = Object.keys(qsKeyItem)
  }

  /**
   * 데이터 로드 ( 서버 API 호출 )<pre>
   * 단, LIST 서버 API 는 다음의 경우 호출하지 않는다.
   *   - hasInitItems ( 초기 items 가 있는 경우 ) 인
   *   - callListApi 를 false 로 한 경우
   * </pre>
   * @return {boolean} search가 사용되어 데이터를 찾은경우에만 true ( defaultFirstClick 으로 클릭된 경우 false )
   */
  const load = async (opt: STableLoadOption = {}) : Promise<boolean> => { // 첫 페이지로 로딩
    const {
      go1page           = false,
      search            = undefined,
      defaultFirstClick = false,
      clear             = false,
      callListApi       = true,
      detailReset       = undefined,
    } = opt

    log.debug("load(" + JSON.stringify({go1page, search, defaultFirstClick}) + ") call")
    // log.debugV("load() call",  + {go1page, search, defaultFirstClick})

    // STEP 01 -- go1page : 지정시 reqPageing 에 셋팅
    const reqPageing : STablePageingType = {}

    log_p.isDebug && log_p.debugV("load-1", {go1page,clear,"reqPageing.pageNo": reqPageing.pageNo})
    if (go1page || clear) reqPageing.pageNo = 1
    log_p.isDebug && log_p.debugV("load-2 : reqPageing =", reqPageing)

    if (clear) {
      log_p.isDebug && log_p.debugV("load-2-1 clear : reqPageing =", reqPageing)
      _clear()

      // state.innerData?.pageing_default 가 있을때 그것으로 초기화 한다.
      if (T.isNotNU(state.innerData?.pageing_default)) {
        Object.keys(state.pageing)
          .forEach(k => state.pageing[k] = state.innerData.pageing_default[k])
        Object.keys(state.innerData.pageing)
          .forEach(k => state.innerData.pageing[k] = state.innerData.pageing_default[k])
      }

      // if (!hasInitItems) {
      //   state.pageing.totalCount = 0
      //   state.innerData.items = []
      // }
      // state.innerData.loading = false
      // detailClear()
    }
    if (detailReset !== undefined) {
      _detailReset(detailReset)
    }

    log_p.isDebug && log_p.debugV("load-3 : 서버호출 직전 reqPageing =", reqPageing)

    // STEP 02 -- API 서버호출
    if (!hasInitItems && callListApi)
      await i_apiGetListData(reqPageing, "load()")

    // STEP 03 -- search, defaultFirstClick : 결과에서 아이템 찾거나 첫번째꺼 자동선택
    return await itemSearchClick(search, defaultFirstClick)
  }

  const loadFirst = async (): Promise<boolean> => {
    return await load({search: innerData.initSearchItem})
  }


  const items        = props_list.items || []
  const hasInitItems = T.isNotEmptyArray(items) // 처음부터 데이터를 가지고 있나?


  let checkbox_key  = props_list.checkbox_key || props.checkbox_key
  if (!checkbox_key) {
    // checkbox_key 가 지정되지 않았더라도 _checkbox_ 가 있으면
    // keys_array 가 하나일경우 그것을 사용한다.
    if (T.hasKey(headerIndex, "_checkbox_") && keys_array && keys_array.length === 1) {
      checkbox_key = keys_array[0]
    }
  }

  //---[ innerData 정의 ]-----------------------------------------------------------------------------------------------
  const innerData = {

    /**
     * 항목 구분
     * [공통]      abc 키 조회시 props.abc || props.list.abc 방식으로 결정
     * [공통.가공] 공통 방식으로 한 다음에 나름의 로직으로 가공
     * [only emit] innerData ( == props.list } 로 쏘기만 하는 항목 ( 값 참조 안함 )
     */

    onepage           : props_list.onepage         || props.onepage    || false,

    // innderData 전용
    /* [only emit] */  loading        : false,     // 목록 로딩중 여부
    /* [only emit] */  detail_loading : false,     // 상세 로딩중 여부
    /* [only emit] */  detail_default : props_list.detail_default    || props.detail_default,  // detail ( ditem ) 기본값
    /* [only emit] */  showPageSize   : props_list.showPageSize      || 10,
    /* [only emit] */  items,
    /* [only emit] */  keys_array,

    /** props . list 공통부분 ( 우선순위는 props > props.list ) */

    hasInitItems,
    /* [공통]      */ pageing, // 공통이긴 한데 기본값 적용함
    /* [공통]      */ pageing_default : props_list.pageing_default || props.pageing_default || {}, // 단순 전달
    /* [공통     ] */ keys,
    /* [공통.가공] */ headers,
    /* [only emit] */ headerIndex, // heders 의 index
    /* [only emit] */ sitem       : null, // itemclick 으로 선택한 아이템
    /* [only emit] */ sitemKeyMap : null, // itemclick 으로 선택한 아이템 key map ( keys_array 필요 )
    /* [only emit] */ ditem       : null, // detail api 로 로딩된 아이템

    // 기타
    /* [공통] */ name            : props_list.name             || props.name        || 'NONAME',
    /* [공통] */ listApi         : props_list.listApi          || props.listApi,
    /* [공통] */ listParam       : props_list.listParam        || props.listParam,
    /* [공통] */ autoload        : props_list.autoload         || props.autoload    || false,
    /* [공통] */ detailApi       : props_list.detailApi        || props.detailApi,
    /* [공통] */ detailParam     : props_list.detailParam      || props.detailParam,

    // 전달용 for co-location
    /* [공통] */ onChangeData    : props_list.onChangeData     || props.onChangeData,
    /* [공통] */ qsKeyPage       : props_list.qsKeyPage,
    /* [공통] */ qsKeyItem,
    /* [공통] */ convQsToData    : props_list.convQsToData     || props.convQsToData,
    /* [공통] */ convDataToQs    : props_list.convDataToQs     || props.convDataToQs,
    /* [공통] */ initSearchItem  : props_list.initSearchItem   || props.initSearchItem,

    /* [공통] */ checkbox_key,
    /* [공통] */ selected_list    : props_list.selected_list     || props.selected_list || [],
    /* [공통] */ selected_cnt     : props_list.selected_cnt      || props.selected_cnt  || 0,


    load: load,

    cmd: {
      loadFirst
    }

  } as STableDataType

  log_p.isDebug && log_p.debugV("setup set innerDate 직후 : innerData.pageing_default", innerData.pageing_default)


  //---[ q-table Pagination 속성 동기화용 객체]-------------------------------------------------------------------------
  // const options:QTablePaginationType = {
  //   /* <q-table> 의 pagination 하고 동기 (.sync) 화 하는 정보
  //    * 즉 이 변수들은 내가 정의한게 아니고 퀘이사의 것을 그대로 사용한다.
  //    * [주의사항]
  //    * page: 1 로 고정한다. 왜냐하면 처음부터 1일때는 문제가 없는데 2페이지 부터 시작할때는 아무것도 안나오는 버그가 있다.
  //    * rowsPerPage: innerData.pageSize 하고 동기화 한다.
  //    */
  //   sortBy     : null,
  //   descending : true,
  //   page       : innerData.pageNo,
  //   // page       : 1, /// 1로 고정
  //   rowsPerPage: props.onepage ? 0 : (innerData.pageSize || 10),
  // }

  // log.debug("data() options =", JSON.stringify(options));
  log.debug("data() end - innerData 를 초기화 합니다.", innerData);

  //------------------------------------------------------------------------------------------------------------------
  //-- STATE
  //------------------------------------------------------------------------------------------------------------------
  const state = reactive({
    /* 자체 데이터                */ innerData,
    /* 페이징,정렬 (q-table 싱크) */ pageing, // options,
    /* (임시) 옵션 변경 횟수      */ optChanged: 0,
    /* list의 해더 배열           */ mHeaders,
    /* list의 key 배열            */ keys_array,
    // loadedOne : null,
  })

  emit('update:pageinfo', state.pageing, '초기셋팅')
  emit('update:list', state.innerData)
  // 이유는 모르겠으나 이렇게 한번만 해두면 이후부터는 따로 emit 을 하지 않아도 자동 반영된다.
  // 특히 innerData.sitem 의 경우 emit(update:props_list) 를 하지 않아도 변화를 인지함.
  // 이것은 최초 한번 update:props_list 하고 나면 옵져버가 연결되고 연결된 옵져버가 유지되기 때문으로 추측된다.
  // 결국 emit(update:detail)대신 watch(()=>state.props_list.sitem) 을 사용해도 완전히 동일한 결과.

  //------------------------------------------------------------------------------------------------------------------
  //-- WATCH
  //------------------------------------------------------------------------------------------------------------------

  // 이건 언제 호출되는거지 ??
  watch(() => state.innerData.headers, (v) => {
    log.debug("watch(data innerData.headers)", v);
    // _copyHeaders('watch(data innerData.headers)')
    const nformats = state.innerData.formats || formats // 포멧도 혹시 바뀌었으면 새걸로
    const headers = applyHeaderFormats(headerArr, nformats, log) // 헤더 배열에 포멧을 적용
    const {mHeaders, headerIndex} = applyHeaderAttrs(headers, props.nowrap, 'watch', log);
    state.mHeaders = mHeaders
    state.innerData.headerIndex = headerIndex
  })

  // 하단의 페이지 사이즈 수정시 호출된다
  // watch(() => state.pageing.pageSize, (v) => {
  //   log.debug("watch(data innerData.pageSize)", v);
  //   state.pageing.pageNo = 1 // 페이지 사이즈를 바꾸면 무조건 1페이지로 이동한다.
  //   // state.options.page = 1 // 미사용
  //   // state.pageing.pageSize = v
  //   // noinspection JSIgnoredPromiseFromCall
  //   i_apiGetListData('watch(data innerData.pageSize)')
  // })

  // props_list.items 로 데이터 주입시. state.innerData.items 로 복사한다.
  // 단 props.watchItems 이 true인 경우에만
  watch(() => props_list.items, (v) => {
    if (props.watchItems) {
      log.debug("watch(props props_list.items)", v);
      state.innerData.items = v;
      i__setVirtualColumn() // 가상컬럼 셋팅
    }
  })

  //------------------------------------------------------------------------------------------------------------------
  //-- COMPUTED
  //------------------------------------------------------------------------------------------------------------------

  /** 총 페이지 수 */
  const totPages = computed(() => Math.ceil(totCnt.value / state.pageing.pageSize))
  // const totPages = computed(() => Math.ceil(totCnt.value / 10))

  /** 총 건수 */
  const totCnt = computed(() => Math.max(state.pageing?.totalCount ?? 0, state.innerData.items?.length ?? 0))

  /** tr 빈줄 체우기 */
  const leftCount = computed(() => {
    if (innerData.onepage || props.hideEmptyTr) return 0 // 빈줄감추기 옵션지정이면 빈줄은 무조건 0
    const showItems = state.innerData && state.innerData.items && state.innerData.items.length || 0 // 현재 출력된 아이템 수
    const perItems = Math.min(state.pageing.pageSize, props.limitEmptyTr) // 최대 보여줄 아이템 수
    let cnt = Math.max(perItems - showItems, 0) // 모자란 줄 수

    if (!state.innerData.items || state.innerData.items.length < 1) cnt-- // 데이터가 하나도 없을땐 첫줄에 뭐라뭐라 메세지가 나오므로 그거 한줄은 빼준다
    return (perItems > 50 && showItems > 50) ? 0 : cnt // 50 줄이 넘어갈 경우에는 페이지달 줄 수를 무시한다.
  })

  /** filterdList */
  const filterdList = computed(() => {

    const items      = state.innerData.items
    if (T.isEmptyArray(items)) return items // 목록 없으면 아무것도 안함

    const filterFunc = props.listFilter
    if (!T.isFunction(filterFunc)) return items // 필터가 없어도 암무것도 안함

    return items.filter(filterFunc) // items 에 필터 적용
  })

  /** [호환성-어뎁터] <q-table pagination 어뎁터 */
  const qtable_pagination_computed = computed({
    get: () => {
      const pagination = convert_stablePageing_to_qtablePagination(state.pageing, innerData.onepage)
      log.debug("qtable_pagination_computed get : ", JSON.stringify(pagination))
      return pagination
    },
    set: (pagination: QTablePaginationType) => {
      log.debug("qtable_pagination_computed set : ", JSON.stringify(pagination))
      const pageing = convert_qtablePagination_to_stablePageing(pagination)
      T.setOverwrite(state.pageing, pageing);
      emit('update:pageinfo', state.pageing, 'compute set')
      // i_onChangeData('pageing computed set')
    },
  })


  //------------------------------------------------------------------------------------------------------------------
  //-- METHODS
  //------------------------------------------------------------------------------------------------------------------

  /** 데이터 ( 목록 또는 상세 ) 변경시 콜백
   * @param {string} kind 무엇이 바뀌었나
   */
  const i_onChangeData = (kind : string) : void => {
    log.debugV("i_onChangeData()", { kind })
    if (T.isFunction(innerData.onChangeData)) { // 콜백 있을경우 호출
      const keyMap = _.pick(innerData.sitem, state.innerData.keys_array)
      innerData.onChangeData(state.pageing, keyMap, kind)
    }
  }

  /** 페이지를 1페이지로 옮겨서 서버 호출 */
  const doSearch = async () : Promise<void> => {
    log.debug("doSearch() call")
    await load({go1page: true})
  }

  /** 페이지를 1페이지로 옮겨서 서버호출 후 자동으로 제일 위에 row를 클릭한다. */
  const doSearchAndFirstClick = async () : Promise<void> => {
    log.debug("doSearchAndFirstClick() call")
    await load({go1page: true, defaultFirstClick: true})
  }

  /**
   * 서버호출 후 아이템 검색클릭
   * @param {MapType|Function} search 찾을 아이템. Map 형식 또는 (item:Map) => boolean 형식의 검색함수<br/><br/>
   *   - Map 형식의 경우 속성이 아무리 많더라도 keys_array 에 있는 것만 뽑아서 비교한다. (qs 유지용)<br/>
   *   - 함수형식의 경우엔 제약없이 위에부터 순서대로 각 row (item)마다 호출하여 처음으로 true 가 나오는걸 선택한다.
   * @param {boolean} defaultFirstClick 검색에 실패한 경우 첫번째 꺼라도 대신 클릭할래 ?
   * @return {boolean} 검색 성공시 true ( defaultFirstClick 옵션으로 못찾아서 첫번째 꺼 클린된 경우는 false )
   */
  const doSearchAndClick = async (search, defaultFirstClick = false) : Promise<boolean> => {
    log.debug("doSearchAndClick() call")
    return await load({go1page: true, search: search, defaultFirstClick: defaultFirstClick})
  }

  /** 현재 페이지 reload */
  const reload = async () => (log.debug("reload() call"), await load())

  /**
   * map 또는 function 으로 아이템을 찾는다
   * @param {ItemSearchType} mapOrFunc
   */
  const itemSearch = (mapOrFunc : ItemSearchType) : ItemType => {

    if (T.isEmptyArray(state.innerData?.items))
      return log.debug("itemSearch() 내부데이터(items)가 없다."), undefined

    if (T.isNU(mapOrFunc))
      return log.warn("itemSearch() 찾을 item 이 없다 :", mapOrFunc), undefined

    // log.debug("typeof mapOrFunc = ", typeof(mapOrFunc), mapOrFunc)

    // Map 타입으로 찾기
    if (T.isNotEmptyMap(mapOrFunc)) {

      if (T.isEmptyArray(state.innerData.keys_array))
        return log.error("itemSearch() keys_array가 없다."), undefined

      const searchMap = _.pick(mapOrFunc, state.innerData.keys_array) // search 객체 만들고

      if (_.isEmpty(searchMap))
        return log.debug("itemSearch() searchMap 를 만들어보니 비었다"), undefined

      // const ret = _.find(state.innderData.items, searchMap) // 그럼 이제 찾아보자
      // _.find(state.innderData.items, searchMap) 숫자 문자 비교 문제가 발생함.
      // qs 로 들어온건 no=123 이라고 해서 no가 "123"문자인데
      // innerData.items 의 no 는 123 이라고 숫자타입이라 찾지를 못한다.
      // 그렇다고 qs 파싱을 number로 하는 방법도 있지만 그러면 i-input-sel 등에서 useyn 1 0 에서 문제가 생긴다
      // 결국 선택해야 하는 qs 는 개념 자체가 전부 문자열이기 때문에 qs는 문자열로 파싱하는게 기본이라고 본다.
      // 그렇다면 items 를 찾을때 no 를 number 변환하거나 아니면 === 대신 == 를 사용할 수 밖에 없는데
      // 어떤 컬럼이 숫자인줄 알고 변환한단 말인가 ? 그냥 === 대신 == 로 비교해서 찾는걸로 간다.
      const maps = Object.entries(searchMap) // 미리 [[k,v],[k,v],...] 형식으로 변환 ( 성능 )
      return _.find(state.innerData.items, item => maps.every(([k,v]) => v == item[k])) // "==="가 아니라 "==" 로 비교가 핵심
    }
    // 함수 타입으로 찾기
    if (T.isFunction(mapOrFunc)) {
      return _.find(state.innerData.items, mapOrFunc)
    }
    // 기타
    log.error("itemSearch() 타입오류. Map 또는 함수만 가능. 입력 type:", typeof(mapOrFunc))
    return undefined
  }

  /** 아이템을 찾아서 클릭한다.
   * @param {ItemSearchType} mapOrFunc 찾을 아이템. Map 형식 또는 (item:Map) => boolean 형식의 검색함수<br/><br/>
   *      - Map 형식의 경우 속성이 아무리 많더라도 keys_array 에 있는 것만 뽑아서 비교한다. (qs 유지용)<br/>
   *      - 함수형식의 경우엔 제약없이 위에부터 순서대로 각 row (item)마다 호출하여 처음으로 true 가 나오는걸 선택한다.
   * @param {boolean} defaultFirstClick 못찾은 경우 대신 첫번째꺼라도 클릭할지 여부 (기본:false)
   * @return {Promise<boolean>} 찾은경우 true -- 못찾아서 defaultFirstClick 으로 첫번째꺼를 클릭했더라도 못찾은거니깐 false */
  const itemSearchClick = async (mapOrFunc : ItemSearchType, defaultFirstClick = false) : Promise<boolean> => {

    if (T.isFunction(mapOrFunc) || T.isNotEmptyMap(mapOrFunc)) { // mapOrFunc 가 정의된 경우
      const item = itemSearch(mapOrFunc) // 찾아봐서
      if (item) // 있으면
        return await itemclick(item), true // 클릭하고 self 반환
    }

    defaultFirstClick && await itemFirstclick()
    return false
  }

  /**
   * 목록의 첫번째 아이템을 클릭한다.<pre>
   * 아이템이 하나도 없어서 실패한 경우 상세 (sitem, ditem) 도 클리어 한다
   * @returns {Promise<boolean>} 성공 여부
   * </pre> */
  const itemFirstclick = async () : Promise<boolean> => {
    if (T.isNotEmptyArray(state.innerData?.items)) // items 가 있으면
      return await itemclick(state.innerData?.items[0]), true // 클릭하고 true 반환
    detailReset() // 데이터 없으면 상세(sitem, ditem) 도 초기화 -- TODO CHECK :: 이게 진짜 필요한 부분인가 ? 없앨까 ?
    return false
  }

  /** 해당 아이템을 클릭한다.<pre>
   * 1. sitem 으로 저장 ( i_isCurrentItem 에서 sitem 으로 현재 선택된 row 인지 판단한다 )
   * 2. emit itemClick 호출
   * </pre> */
  const itemclick = async (item:ItemType, $event?:any) : Promise<void> => { // row 클릭
    log.debug("itemclick() call", item)
    state.innerData.sitem = item
    state.innerData.sitemKeyMap = _.pick(item, state.innerData.keys_array)
    await i_detailLoad(item)
    emit("itemClick", state.innerData.sitem, $event)
    i_onChangeData('OK: detail set')
  }

  /** sitem 을 셋팅한다. (무조건) <pre>
   * - 지정된 값으로 sitem (현재 선택된 item)을 설정한다.
   * - 셋트인 sitemKeyMap 도 keys_array 를 이용하여 같이 설정한다.
   * * items ( 로딩된 데이터 ) 에서 검색하지 않고 무조건 설정한다.
   *
   * 이것은 그냥 목록에서 미리 선택되어 있도록 표시해 주는 효과만 있다.
   * 주로 모달에서 autoload 를 설정한 경우 opened 이벤트에서 효출한다.
   * click 이라던가 detailSync 라던가 관련된 작업은 아무것도 하지 않는다.
   * 그런게 필요하면 itemSearchClick 같은걸 써야 한다.
   * </pre> */
  const setSitem = (item:ItemType) : void => { // row 클릭
    log.debug("setSitem() call", item)
    if (T.isNotEmptyMap(item)) {
      state.innerData.sitem = item
      state.innerData.sitemKeyMap = _.pick(item, state.innerData.keys_array)
    } else {
      state.innerData.sitem = null
      state.innerData.sitemKeyMap = null
    }
  }

  /** 상세데이터를 clear 한다.<br/>
   * [주의] 기본값 detail_default 로 체우는게 아니라 그냥 모든 속성을 다 없앤다. */
  const detailClear = () => detailReset({})

  /** 상세데이터(ditem) 재설정<pre>
   * 작업 내용
   *   1. sitem ( 목록에서 선택한 item ) 은 무조건 null 로 설정 -- TODO CHECK :: 이걸 왜 여기서 한담 ??
   *   2. ditem 을 파라미터(initObj)에 따라 3가지중 하나로 작동한다.
   *      - detailReset({content:'aa'}) map 형태의 데이터가 들어오면 그대로 반영
   *      - detailReset({}) 비어있는 맵 호출시 detail_default 무시하고 모든 속성 날림
   *      - detailReset() 로 호출시 (null, undefined 포함) 에는 detail_default 를 반영
   *        (단 detail_default가 없을경우 {} 을 반영 == 모든속성만 날림)
   *   3. 상세변경보고 emit('update:detail', ditem)
   * </pre>
   * @param {{}} initObj 재설정할 map, 미지정시 detail_default 를 대신 사용한다.
   */
  const detailReset = (initObj ?: MapType) : void => { // 상세 클리어
    state.innerData.sitem = null // detail 하고 sitem은 다르다
    state.innerData.sitemKeyMap = null
    // sitem  은 ( props props_list.sitme == data innerData.sitem ) 은 현재 목록에서 선택된 row item 이고
    // detail 은 detail-api 사용시 서버로부터 받아온 상세 데이터이다. 즉 좀더 양이 많고 실시간 데이터다.

    // 초기화 맵 결정
    const myInitObj = _.isPlainObject(initObj) ? initObj // initObj 이 맵이라면 그것을 사용 ( 빈맵({})도 가능 )
      : T.anyToMap(props.list?.detail_default, {})  // 아니라면 props으로 지정 props_list.detail_default 를 사용. 그것도 안되면 그냥 {}

    // 초기화 맵을 clone 해서 상세데이터(ditem)에 설정
    state.innerData.ditem = _.clone(myInitObj)

    log.debug("detailReset() =", state.innerData.ditem)
    emit('update:detail', _.clone(state.innerData.ditem)) // 상세 변경됭었으니 보고
    i_onChangeData('OK: detail reset')
  }
  const _detailReset = detailReset // 호환성 ㅠㅠ

  /** 현재 상세를 새로고침한다.<pre>
   * detailApi 를 호출한다.
   * TODO CHECK :: 목록에서 클릭시에는 sitem ( items 에서 클릭한 row item ) 를 파라미터로 넘긴다.
   *               detailReload 도 결국은 그 행위를 다시 하는거라서 마찬가지로 sitem 을 파라미터로 해야 하지만
   *               이상하게도 props.detail ( emit('update:detail', ditem) 하기 때문에 결국 ditem 하고 동일하다 ) 을 파라미터로 한다
   *               이걸.. sitem으로 바꿔야 할꺼 같은데 괜찮을까 ??
   *               내가 뭔가 착각해서 sitem 으로 안하고 detail ( === ditem ) 으로 한걸까 ?
   *               아니, 착각을 했다면 detail 이 아니라 ditem 으로 했을 것 같은데
   *               굳이 ditem 이 아니라 props.detail로 했다는건 sitem 으로 했을때 뭔가 버그가 있어서 그랬던게 아닐까 ?
   *               모르겠다... 생각이 나지 않는다 ㅠㅠ
   *               만약 버그였다면 sitem 을 여기저기 마구 초기화 해버려서 믿을 수 없기에
   *               얌전하게 보고하고 건들지 못했던 props.detail 를 쓴걸까
   */
  const detailReload = async (from ?: string) : Promise<void> => { // 상세 릴로드
    log.debug("detailReload() call", from)
    if (!state.innerData.detailApi) return // 상세기능 설정이 없으면 PASS
    if (_.isEmpty(state.innerData.sitem)) return // 선택 sitem이 비어 있으면 PASS
    await i_detailApiLoad(state.innerData.sitem)
  }

  /** 선택한 아이템(sitem) 으로 상세(ditem)를 로딩한다. */
  const i_detailLoad = async (sitem) : Promise<void> => { // 상세 로딩
    log.debug("i_detailLoad(sitem) call, sitem =", sitem)
    if (!state.innerData.detailApi) return // 상세기능 설정이 없으면 PASS
    if (_.isEmpty(sitem)) {
      emit("update:detail", {})
      return
    }
    await i_detailApiLoad(sitem)
  }



  /** API 서버조회 : 상세 데이터
   * @param sitem innerData.sitem 이 기본이긴 하지만 props.detail 또는 innerData.ditem 으로 바꿀지도 모름. 지금 고민중.
   */
  const i_detailApiLoad = async (sitem) : Promise<void> => {
    log.debug("i_detailApiLoad() call", sitem)
    // STEP 01 -- 서버요청 파라미터로 사용할 paramData 를 만든다.
    const paramData = state.innerData.detailParam(sitem, state.innerData);
    log.debug("i_detailApiLoad() paramData", JSON.stringify(paramData))

    if (T.isNU(paramData)) { // 파람의 결과값이 없으면 리셋한다.
      log.info("i_detailApiLoad() : 파람결과가 없어서 종료")
      return
    }

    // STEP 02 -- API 섭버 호출
    state.innerData.detail_loading = true
    state.innerData.ditem = await apiDetailFunc(state.innerData.detailApi, paramData)
    state.innerData.detail_loading = false

    // STEP 03 -- 보고하고 끝
    emit("update:detail", state.innerData.ditem)
    // i_onChangeData('detail api load')
  }

  /** 내부 데이터 초기화<pre>
   * - 페이징의 총 갯수(+emit 페이징)
   * - innerData.items = []
   * - loading = false
   * </pre> */
  const clear = (emitPageing = true) : void => { // innerData 클리어
    log.debug("clear() call")

    if (!hasInitItems) {
      state.innerData.items = []
      state.pageing.totalCount = 0
      if (emitPageing)
        emit('update:pageinfo', state.pageing, 'clear()')
    }
    state.innerData.loading = false
    detailClear()

    // state.innerData.items = []
    // state.pageing.totalCount = 0;
    // emit('update:pageinfo', state.pageing, 'clear()')
    // state.innerData.loading = false
    // detailClear()
  }
  const _clear = clear // 이름이 겹쳐서 ㅠㅠ

  /** 목록 조회 서버 API 호출 */
  const i_apiGetListData = async (reqPageing : STablePageingType = null, from:string = '') : Promise<void> => {
    // log.debug("i_apiGetListData() call - from", from, ", options =", JSON.stringify(state.options));
    log.debugV("i_apiGetListData() call", {reqPageing, from})

    log_p.isDebug && log_p.debugV("i_apiGetListData-1", {reqPageing, from})

    const paramData = i__makeApiListParam(reqPageing)

    log.debugV("i_apiGetListData()", {paramData})
    log_p.isDebug && log_p.debugV("i_apiGetListData-2", {paramData})

    // 검색의 검색을 하는경우 검색조건이 적절하지 않을때 listParam 실행 결과 null 이 나오게 한다/
    // 즉 상위 목록에서 선택을 취소한 경우에 대항한다.
    // 그런 경우( null 인 경우 ) 검색을 하지 않고 따라서 나도 초기화를 하고 종료한다.
    if (T.isNU(paramData)) { // 파람의 결과값이 없으면 리셋한다.
      log.info("i_apiGetListData() : 파람결과가 없어서 리셋하고 종료 : ", paramData)
      return detailReset(), undefined
    }

    const {list, totalCount} = await i__callListApi(paramData)

    i__parseJsonColumns(list) // json 컬럼 파싱

    // autoHeader 처리
    if (props.autoHeader || list.autoHeader) {
      if (T.isEmptyArray(state.mHeaders) && T.isNotEmptyArray(list)) {
        state.innerData.headers = makeHeadersUseItems(list)
      }
    }



    T.setOverwrite(state.pageing, reqPageing) // 요청 페이징 정보 페이징에 반영
    T.setOverwrite(state.innerData.pageing , reqPageing) // 요청 페이징 정보 페이징에 반영
    state.innerData.items = list
    state.innerData.pageing.totalCount = totalCount
    state.pageing.totalCount = totalCount // 이 부분으로 인해서 computed(totPages) 가 변경되고 그래서 실제로는 페이징 버튼을 클릭하지 않아도 _onChangePage 가 호출된다.
    i__setVirtualColumn() // 가상컬럼 셋팅
    emit('update:pageinfo', state.pageing, '서버수신 완료')
    i_onChangeData('OK: props_list api load')
    emit("loadData", state.innerData)
  }

  /** list api 에 요청할 param 를 만든다 */
  const i__makeApiListParam = (reqPageing : STablePageingType = null) => {
    log_p.isDebug && log_p.debugV("i__makeApiListParam-1", {reqPageing})
    const tmpPageing = T.setOverwrite(_.clone(state.pageing), reqPageing)
    log.debugV("i__makeApiListParam()", {tmpPageing})
    log_p.isDebug && log_p.debugV("i__makeApiListParam-2", {"state.pageing":state.pageing,tmpPageing})

    // 요청 json 만들기 ( 파람 함수 실행 )
    const param_pageing   = _.omit(tmpPageing, ['totalCount']);
    log_p.isDebug && log_p.debugV("i__makeApiListParam-3", {param_pageing})
    const param_innerData = state.innerData
    return innerData.listParam(param_pageing, param_innerData);
  }

  const i__callListApi = async (paramData) => {
    // API 서버 호출
    state.innerData.loading = true
    const resData : STableApiListType = await apiListFunc(innerData.listApi, paramData)
    state.innerData.loading = false

    const convData = T.isFunction(props.listResConv) // props.listResConv 가 지정되어 있으면
      ? props.listResConv(resData) // 변환을 한다 ( 결과로 {props_list, totalCount} 가 나와야 한다 )
      : resData // 지정이 안되어 있을때는 아무것도 안한다. convData = resData

    const list       = T.ifNU(convData.list, []);
    const totalCount = T.ifNU(convData.totalCount, resData.list?.length || 0);
    return {list, totalCount}
  }

  const i__parseJsonColumns = (list) => {
    // json 타입 컬럼 작업
    // TODO CHECK :: 로직 개선 필요 -- header 에 type json 지정으로 바꿀까 ?
    //               formats 으로는 안될꺼 같다. 그건 보여줄때니깐
    if (_.isArray(props.listJsonCols)) {
      list.forEach(item => {
        props.listJsonCols.forEach(key => {
          if (typeof(item[key]) === 'string') {
            item[key] = JSON.parse(item[key])
          }
        })
      })
    }
  }


  /** 가상컬럼 값 설정 */
  const i__setVirtualColumn = () => {
    const hasCount     = T.hasKey(state.innerData.headerIndex, '_count_')
    const hasCountDesc = T.hasKey(state.innerData.headerIndex, '_count_desc_')
    if (hasCount || hasCountDesc) {
      const totalCount = innerData.pageing.totalCount
      const isReverse = (innerData.pageing.orderBy === '_count_desc_' || innerData.pageing.orderBy === '_count_')
                        && innerData.pageing.orderDesc === false
      if (innerData.onepage) {
        state.innerData.items.forEach((item, index) => {
          if (hasCount    ) item['_count_'     ] = isReverse ? totalCount - index : index + 1
          if (hasCountDesc) item['_count_desc_'] = isReverse ? index + 1 : totalCount - index
        })
      } else {
        const offset = state.innerData.pageing.pageSize * (state.innerData.pageing.pageNo - 1)
        state.innerData.items.forEach((item, index) => {
          if (hasCount    ) item['_count_'     ] = isReverse ? totalCount - offset - index : offset + index + 1
          if (hasCountDesc) item['_count_desc_'] = isReverse ? offset + index + 1 : totalCount - offset - index
        })
      }
    }

  }

  /**
   * 컬럼을 끄거나 킨다.
   * @param {string|string[]|Function} cols 끄거나 켤 컬럼 지정
   * @param isView 사용여부
   */
  const columnOnOff = (cols, isView) => {
    const colsArray = T.anyToArray(cols) // 자동변환
    if (T.isEmptyArray(colsArray)) return // 빈값이면 아무것도 안한다.
    state.innerData.headers = _.clone(state.innerData.headers).map(cell => {
      if (colsArray.includes(cell.name)) cell.view = isView
      return cell
    })
  }

  /** 현재 아이템인가 */
  const i_isCurrentItem = (item) => { // keys 가 지정되어 있을때 그게 현재 아이템인지 판단한다.
    // TODO CHECK :: 성능개선의 여지가 있다. 조건이 너무 많아..
    if (!item || !state.innerData.sitem || T.isEmptyArray(state.innerData.keys_array)) return false // 클릭안되었거나 keys_array가 없으면 아무것도 안함
    return state.innerData.keys_array.every(key => item[key] === state.innerData.sitem[key])
  }

  /**
   * s-drag-table 전용<pre>
   *   header 를 clone 하여 각 column에 대해서
   *   파라미터로 넘어온 item[key]에 format을 적용 후 .value로 설정한다.
   *
   *   이것은 s-drag-table 에서 각 row 들을 출력할때마다 호출한다.
   *   s-drag-table은 <q-table이 아니라 <q-markup-table을 사용하기 때문에
   *   q-table에서 자동으로 되는 부분을 수동으로 직접 처리해야 하기 때문이다.
   * </pre>
   * @param item
   */
  const i_applyItemHeaders = (item) => {
    const vcol = _.clone(state.mHeaders)
    vcol.forEach(col=>{
      const orgValue = item[col.name]
      col.value = typeof col.format === 'function' ? col.format(orgValue, item) : orgValue
    })
    return vcol
  }

  /** 테이블 하단에 페이지 번호 클릭 */
  const i_onChangePage = async (pageNo: number) : Promise<void> => {
    log.isDebug && log.debugV("_onChangePage() call", {
      newPageNo: pageNo, prevPageNo: state.innerData.pageing.pageNo,
    })
    if (state.innerData.pageing.pageNo === pageNo) {
      log.debug("페이징이 실제로는 변경되지 않아서 서버요청을 하지 않는다.")
      // 이 부분에 도달하는 경우는 1페이지에서 검색조건을 변경하여 조회한 경우
      // totalCount 가 변경되어 computed(totPages) 가 변경되고 그래서 실제로는 페이징 버튼을 클릭하지 않아도
      // <q-pagination 의 @input 에 연결되어 _onChangePage 가 호출된다.
      return
    }
    await i_apiGetListData({pageNo}, '_onChangePage')
  }

  /** 페이지 사이즈 변경시 무조건 서버 목록 호출 */
  const i_onChangePageSize = async(pageSize) => {
    await i_apiGetListData({pageSize}, 'i_onChangePageSize(페이지 사이즈 변경으로 인함)')
  }


  /** (for q-table) 상단 헤더의 컬럼명을 클릭하여 정렬순서를 변경했을때 호출된다.<pre>
   * 단, 서버페이징모드에서만 호출되며. onepage모드에서는 그냥 지가 자동으로 소팅한다.
   * - 서버페이징모드란 : 목록 데이터를 API서버로부터 호출하는 방식으로
   *                      state.pageing.rowsNumber 키가 있으면 서버모드로 작동한다.
   * - onepage모드란    : 목록데이터를 외부에서 주입하거나
   *                      또는 서버에서 호출(listApi, listParam)하더라도 페이징을 안하는 모드로서
   *                      props.onepage = true로 설정하면
   *                      내부 로직이 state.pageing.rowsNumber 키를 설정하지 않아서
   *                      q-table이 서버페이징을 안하고 스스로 하게 되어
   *                      이 함수(_onChangeSortInfo)가 호출하지 않는다.
   * </pre> */
  const i_onChangeSortInfo = async (v) => { // 서버모드 : state.pageing.rowsNumber 가 있을때 헤더 클릭하면 이거 실행된다.
    // v = {"pagination":{"sortBy":"title","descending":true,"page":1,"rowsPerPage":10,"rowsNumber":25}}
    // const { page, rowsPerPage, sortBy, descending } = v.pagination
    log.debug("-------------------------------------------------")
    const pageing = convert_qtablePagination_to_stablePageing(v.pagination)
    await i_apiGetListData(pageing, '_onRequest(sort 변경)')
    log.debug("-------------------------------------------------")
  }

  /** 지정된 row만 새로고침 한다. <pre>
   * 조건은 keyMap 에 상세조회 가능한 map을 지정해야 한다
   *   ref_list.value.refreshRow({no: item.no})
   * </pre> */
  const refreshRow = async (keyMap: MapType) => {

    log.infoV("refreshRow", {keyMap})

    // 기본 list api params 생성
    const paramData = i__makeApiListParam()

    // 상세 키 추가
    // const keyMap = _.pick(item, state.innerData.keys_array);

    T.setOverwrite(paramData, keyMap)

    // 서버요청
    const {list, totalCount} = await i__callListApi(paramData)

    if (totalCount === 1) { // 조회 성공인 경우
      i__parseJsonColumns(list) // json 컬럼 파싱
      const targetItem = _.find(state.innerData.items, keyMap)
      if (targetItem) T.setOverwrite(targetItem, list[0])
    }

  }

  // log.infoV("checkbox_key", {checkbox_key})

  // 체크박스
  const { selected_list, selected_cnt, isSelected, doSelectClear, computed_top_checkbox } = checkbox_key
    ? co_list_box_selected(()=>state.innerData.items, state.innerData.checkbox_key)
    : {
      selected_list        : null,
      selected_cnt         : null,
      isSelected           : null,
      doSelectClear        : null,
      computed_top_checkbox: false,
    };

  if (checkbox_key) {
    watch(selected_list, (n,p) => {
      log.debugV("선택변경", {n,p})
      state.innerData.selected_list = selected_list.value
      state.innerData.selected_cnt  = selected_cnt.value
    })
  }



  // 자동 로드 처리
  if (innerData.autoload) {
    onMounted(loadFirst)
  }


  log.debug("-- setup() 종료 -----------------------------------------------------------------")


  //------------------------------------------------------------------------------------------------------------------
  //-- RETURN
  //------------------------------------------------------------------------------------------------------------------
  return {
    // STATE
    ...toRefs(state),
    // 함수 : (외부용) ref 방식으로 호출
    load,      // 기본 함수
    loadFirst, // 최초 로드 == load({search: innerData.initSearchItem}))
    reload,
    clear,
    doSearch, doSearchAndClick, doSearchAndFirstClick,
    itemSearchClick, itemFirstclick, itemclick, setSitem,
    detailClear, detailReset, detailReload,
    columnOnOff,
    // (내부용) 함수 : q-table 연결
    i_onItemclick: itemclick,
    i_isCurrentItem,
    i_onChangePage,
    i_onChangePageSize,
    i_onChangeSortInfo,
    // (내부용) COMPUTED
    totPages, totCnt, leftCount, filterdList, qtable_pagination_computed,
    // s-drag-table 전용 (item에 foramt하고 value를 적용한다. s-table은 자동인데 s-drag-table은 이렇게 직접 해야한다.)
    i_applyItemHeaders: i_applyItemHeaders,

    /** 하나의 row 새로고침*/
    refreshRow,

    // 다중선택 기능
    /** 선택된 목록   */ selected_list,
    /** 선택된 갯수   */ selected_cnt,
    /** 선택 초기화   */ doSelectClear,
    /** 선택 여부     */ isSelected,
    /** 전체선택 여부 */ computed_top_checkbox,

  }
}

export default stable_setup

