/**
 * map 형태 object 를 다루는 함수들
 */

import T from './index'
import {jsonStringifyReplacer} from "./tools_log";
import {
  forEach as l_forEach,
  isObject as l_isObject,
} from 'lodash'

export default {

  /**
   * 비어있지 않은 맵인가 ?<pre>
   *   비어있지 않아야 하고 (!_.isEmpty)
   *   맵 형태여야 한다 (_.isPlainObject)<pre>
   * @param map
   */
  isNotEmptyMap: (map:any) => !_.isEmpty(map) && _.isPlainObject(map),

  /**
   * 객체 정보를 타입과 함께 보여준다.
   * @param {*} obj
   * @returns {string}
   */
  objectDetail: (obj) => {
    if (obj === undefined) return "(UNDEFINED)"
    if (obj === null) return "(NULL)"
    const typeOf = typeof obj
    if (typeOf === 'string') return "(STRING \"" + obj + "\")"
    if (typeOf === 'number') return "(NUMBER " + obj + ")"
    if (typeOf === 'object') return "(OBJECT " + JSON.stringify(obj) + ")"

    const json = JSON.stringify(obj)
    return `type = ${typeOf}, json = ${json}`
  },

  /**
   * object 출력 가능한 모든 속성을 console.log로 찍는다.
   * @param obj
   * @returns {null}
   */
  trace: obj => {
    if (T.isNU(obj)) return null;
    for(const key in obj) {
      if (key === "_events") continue;
      let value = "";
      try { value = "" + obj[key]; }
      catch(e) { value = 'error' }
      if (value.length> 100) value = value.substring(0, 100);
      console.log(key + " = " + value);
    }
  },

  debug_str: obj => T.isNU(obj) ? null : JSON.stringify(obj, jsonStringifyReplacer, 2),

  /**
   * 객체를 이쁘게 표시한다. pretty json
   * @param {Object} obj
   * @returns {null|string}
   */
  debug: obj => T.isNU(obj) ? null : "<xmp>" + T.debug_str(obj) + "</xmp>",

  debug_console: (cmt, obj) => console.log(cmt, JSON.stringify(obj, null, 2)),

  /**
   * 모든 속성을 지정된 값으로 설정한다.
   * T.setAllAttr({a:1, b:2, c:3}, null) === {a:null, b:null, c:null}
   * @param {Object} obj
   * @param {*} value
   * @returns {Object} 첫번째 파라미터 obj 그대로 반환
   */
  setAllValue: (obj, value) => _.forEach(obj, (v,k) => obj[k] = value),

  /**
   * 모든 속성을 null 로 바꾼다.
   * T.setNullAttrs({a:1, b:2, c:3}) === {a:null, b:null, c:null}
   * @param {Object} obj
   * @returns {Object} 첫번째 파라미터 obj 그대로 반환
   */
  setAllNull: obj => T.setAllValue(obj, null),

  /**
   * null하고 undefined 속성들은 제외한 맵을 만든다.<pre>
   * T.exceptNullMap({a: 111, b: null, c: undefined} === {a: 111}
   * </pre>
   * @param {Object} obj
   * @returns {Object} null 하고 undefined 값은 제외된 object
   */
  exceptNullMap: obj => Object.entries(obj).reduce((t,[k,v])=> {
    if (T.isNotNU(v)) t[k] = v;
    return t;
  }, {}),

  /**
   * empty 속성들은 제외한 맵을 만든다.<pre>
   * T.exceptEmptyMap({a: 111, b: null, c: undefined, d: ''} === {a: 111}
   * </pre>
   * @param {Object} obj
   * @returns {Object} empty 값은 제외된 object
   */
  exceptEmptyMap: obj => Object.entries(obj).reduce((t,[k,v])=> {
    if (T.isNotEmpty(v)) t[k] = v;
    return t;
  }, {}),


  /**
   * obj 에 지정된 key 의 값이 비어있으면 value 를 설정한다
   * @param {Object} obj
   * @param {string} key
   * @param {*} defaultVal
   */
  setDefault: (obj, key, defaultVal) => {
    if (T.isNUAny(obj, key)) return
    if (T.isNU(obj[key]) || obj[key] === "") {
      obj[key] = defaultVal
    }
  },

  /**
   * obj 의 요소들이 비어있는걸 체워준다. (이미 있는건 패쓰)<pre>
   * T.setDefaults({a:1,b:2},{a:1111,c:3},{d:4}) === {a:1, b:2, c:3, d:4}
   * (a:1111 를 설정하려 했으나 이미 a가 있어서 셋팅되지 않음)
   * </pre>
   * @param {Object} obj
   * @param {...Object} maps
   * @returns {Object} 첫번째 파라미터 obj 그대로 반환
   */
  setDefaults: (obj:{[key:string]:any}, ...maps) => {
    if (_.isPlainObject(obj)) { // map 타입일때
      _.forEach(maps, map =>
        _.isPlainObject(map) && _.forEach(map, (v, k) =>
          T.setDefault(obj, k, v)))
    }
    return obj // 무조건 obj 반환
  },

  /**
   * obj 의 요소들을 덮어쓴다 (이미 있는 값 overwrite)<pre>
   * T.setOverwrite({a:1,b:2},{a:1111,c:3},{d:4}) === {a:1111, b:2, c:3, d:4}
   * (기존에 a값이 1이라고 있었지만 a:1111 로 오버라이트 됨)
   * </pre>
   * @param {Object} obj 작업대상 map object
   * @param {...Object} maps Map Object list
   * @returns {Object} 첫번째 파라미터 obj 그대로 반환
   */
  setOverwrite: (obj, ...maps) => {
    if (!_.isPlainObject(obj)) return obj
    _.forEach(maps, map => _.isObject(map) && _.forEach(map, (v, k) => {
      obj[k] = v // 이유는 모르겠지만 이걸 중괄호{} 로 반드시 감싸야 reactive 의 요소가 제대로 들어간다... 정말 노이해;
    }))
    return obj
  },

  /**
   * setOverwrite 와 동일한데 우측(right)에 적용하려는 Map의 value가 null 이나 undefined 는 제외하고 적용한다. <pre>
   *   const a = {a:11, b:22}
   *   const b = {a:22, b:null, c:33}
   *   setOverwriteHasValue(a, b) === {a:22, b:22, c:33} -- b의 값은 null이라서 적용하지 않음.
   * </pre>*/
  setOverwriteRightHasValue: (obj, ...maps) => {
    if (!_.isPlainObject(obj)) return obj
    _.forEach(maps, map => _.isObject(map) && _.forEach(map, (v, k) => {
      if (T.isNotNU(v)) obj[k] = v // 적용하려고 하는 값이 null 또는 undefined 가 아닐 경우에만 적용
    }))
    return obj
  },

  /**
   * setOverwrite 하고 동일하지만 _.isPlainObject() 체크를 생략한다.
   * Blob, File 등의 object는 _.isPlainObject() 결과과 false 로 나오기 때문이다 ㅠㅠ
   * @param obj
   * @param maps
   */
  setOverwriteForce: <T>(obj: T, ...maps) => {
    // 같은 로직인데 이렇게 풀어써야 정상작동한다... 
    l_forEach(maps, map => {
      if (l_isObject(map)) {
        l_forEach(map, (v, k) => {
          obj[k] = v
        })
      }
    })
    return obj
  },

  /**
   * 좌측에key가 있어야 overwrite 한다
   * @param obj
   * @param maps
   */
  setOverwriteHasKey: (obj, ...maps) => {
    _.forEach(maps, map => _.isObject(map) && _.forEach(map, (v, k) => {
      if (obj.hasOwnProperty(k)) // 키가 있는것만
        obj[k] = v // 설정
    }))
    return obj
  },

  ///// 이하 최적화 안함 ////////////


  /**
   * 원본 map 에서 default map의 값과 다른 것만 ( 결국 기본값은 제외 ) 하여 새로운 맵을 만든다.
   * @param except 여기에 나열된 키들은 무조건 사용한다 ( obj 것으로 )
   */
  exceptSameValues: (obj, defaultObj, ...except : string[]) => {
    if (T.isNUAny(obj, defaultObj)) return
    const ret = {}
    for (const key in obj) {
      if (obj[key] !== defaultObj[key]) {
        ret[key] = obj[key]
      }
    }

    if (T.isNotEmptyArray(except)) {  // except 에 있는건 제외 == 무조건 추가
      except.forEach(key => ret[key] = obj[key])
    }

    return ret;
  },

  /**
   * item=>T.pick(item, 'membclsf,membno')
   * 아래와 동일
   * item=>_.pick(item, ['membclsf','membno'])
   */
  pick: (item, values) => _.pick(item, T.split(values)),


  /**
   * 지정된 기본값들은 제외하고 지정된 key들로 map 을 뽑아낸다<pre>
   *   [작업단계]
   *      1. obj 에서 지정된 keys 들만 뽑아낸다.
   *      2. 값이 null 이나 undefined 인 속성을 삭제한다.
   *      3. 값이 defaultObj 의 값하고 동일한 속성들도 삭제한다.
   *
   *   T.pickExceptDefault({a:1, b:2, c:3, d:null}, ['a','b'], {a:2, b:2}) === {a:1}
   *      1. d 는 null이라서 제외됨
   *      2. a,b 를 뽑아냄
   *      3. a는 1 인데 defaultObj 의 a 값 2와 달라서 살아남음
   *         b는 2 인데 defaultObj 의 b 값 2와 같아서 삭제
   *      최종결과 : {a:1}
   * </pre>
   * @param {{}} obj 대상 obj
   * @param {string[]} keys 지정된 키들만 뽑아낸다
   * @param {{}} defaultObj 기본값 obj 이 값들과 동일한 속성들은 제외된다
   * @returns {{}}
   */
  pickExceptDefault: (obj, keys, defaultObj) => {
    const ret = T.exceptNullMap(_.pick(obj, keys))
    // const ret = _.pick(obj, keys)
    return T.exceptSameValues(ret, defaultObj)
  },


  getAttrs: (obj, keys) => {

    if (typeof keys == 'string' && keys.indexOf(',') >= 0)
      keys = keys.trim().split(/\s*,\s*/)

    return keys.reduce((t, p) => {t[p] = obj[p]; return t}, {})
  },

  /**
   * fromObj 의 값들을 toObj로 put 한다.
   * @param {Object} toObj
   * @param {Object} fromObj
   * @param {string|Array} keys 지정하지 않으면 fromObj의 모든 key
   * @return {Object} toObj toObj 그대로 반환
   */
  putAttrs: (toObj, fromObj, keys) => {

    if (!fromObj)
      return toObj

    if (!keys)
      keys = Object.keys(fromObj)
    else if (typeof keys == 'string' && keys.indexOf(',') >= 0)
      keys = keys.trim().split(/\s*,\s*/)

    console.log("toObj 1", toObj)
    if (keys)
      keys.forEach(k=> toObj[k] = fromObj[k])
    console.log("toObj 2", toObj)

    return toObj
  },

  allTrim: obj => _.forEach(obj, (v,k) => obj[k] = _.trim(v)),

  /**
   * 지정 객체의 모든 값들을 해제한다.
   * let item = {a: 'aa', b: 'bb'}
   * attrClear(item) // {a: null, b: null}
   * attrClear(item, {a: '11'}) // {a: '11', b: null}
   * 반환 : item 원본을 그대로 반환한다.
   */
  attrClear: (item, clearValue = null) => {
    if (T.isNU(item)) return {} // item 없으면.. 채이닝 오류방지를 위해서 빈 obj 반환
    for (const key in item) {
      item[key] = clearValue
    }
    return item
  },

  /**
   * 객체를 설정한다
   * item = {...item, a:1, b:2} 이렇게 하는것과 차이점은
   * item 은 그대로 유지한다는 것이다.
   * 반환 : item 그대로.
   */
  attrSet: (item, attrItem) => {
    if (T.isNU(item)) return {} // item 없으면.. 채이닝 오류방지를 위해서 빈 obj 반환
    if (T.isNU(attrItem)) return item // attrItem 이 없으면 일 안함
    for (const key in attrItem) {
      item[key] = attrItem[key]
    }
    return item
  },

  /**
   * setClear 하고 setAttr 을 같이 실행한다.
   */
  attrClearSet: (item, clearValue, attrItem) => T.attrSet(T.attrClear(item, clearValue), attrItem),

  /**
   * number 컬럼의 디비 업데이트를 위해서 '' 인 항목은 null 로 변경한다.
   * @param {Object} item
   * @param {Array} attrs
   */
  attrEmptyNumberToNull: (item, attrs) => {
    if (T.isEmpty(attrs) || T.isEmpty(item)) return item
    for (const key of attrs) {
      if (item[key] != null && T.isEmpty(item[key])) { // null 이 아닌데 비어 있으면
        item[key] = null // null 로 설정
      }
    }
    return item
  },

  /**
   * param 을 array로 변환한다.<pre>
   *   T.anyToArray(['force','useyn'])   === ['force','useyn']      // 원래부터 배열이라 아무것도 하지 않음
   *   T.anyToArray('force')             === ['force']              // 배열이 아닌게 넘어오면 하나짜리 배열 만든다.
   *   T.anyToArray(123)                 === [123]                  // 배열이 아닌게 넘어오면 하나짜리 배열 만든다.
   *   T.anyToArray(()=>[1,2,3])         === [1,2,3]                // 함수가 넘어오면 그것을 실행후 결과를 사용한다.
   *   T.anyToArray(null)                === []                     // null이나 undefined 가 넘어오면 기본값([])
   *   T.anyToArray(undefined)           === []                     // null이나 undefined 가 넘어오면 기본값([])
   *   T.anyToArray(null, null)          === null                   // 기본값은 두번째 파라미터로 지정가능
   * @param {Function|array|string} param
   * @param {any} defaultVal (기본:[]) 전환실패시 반환값
   * @returns {Array|any} 성공시 배열 실패시 defaultVal
   */
  anyToArray: (param, defaultVal = []) => {
    const workValue = typeof param === 'function' ? param() : param
    if (_.isArray(workValue)) return workValue
    if (T.isNU(workValue)) return defaultVal
    return [workValue]
  },

  /**
   * param 을 Map 으로 변환한다<pre>
   *   1. param 이 함수라면 그것을 실행하여 대체한다. (아니면 그대로)
   *   2. 1의 결과가 Map 이라면 그대로 반환. 아니라면 defaultVal 반환 ( 기본값 {} )
   */
  anyToMap: (param, defaultVal = {})  => {
    const workValue = typeof param === 'function' ? param() : param
    return _.isPlainObject(workValue) ? workValue : defaultVal;
  },


  /**
   * 배열로부터 map 을 생성한다.
   * 배열의 값들이 key가 되며 value들은 전부 ''으로 지정된다. (지정가능)
   * @param {string[]} arr 배열
   * @param {any} defaultVal 값 지정 ( 기본 = '' )
   */
  newMapFromArray: (arr, defaultVal='') => {
    if (!_.isArray(arr)) return {}
    return arr?.reduce((v,t)=>((v[t]=defaultVal),v),{})
  },

  /**
   * map 에 put 하고 map을 반환.
   * @param {Object} map
   * @param {string} key
   * @param {any} value
   */
  mapPut: <MT>(map:MT, key, value): MT => {
    map[key] = value
    return map
  },

  /** 함수인가 */
  isFunction: func => typeof func === 'function',

}

