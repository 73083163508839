<template>
  <q-btn
    no-wrap no-caps
    :padding="dpTitle ? '1px 8px' : '0 2px'"
    size="12px"
    class="imd-i-bar-btn shadow-0"
    :class="{
      font_mal: size !== '12px'
    }"
    v-bind="{...$attrs, size, icon, disable}"
    v-on="{...$listeners}"
    :label="dpTitle"
    v-shortkey="keys" @shortkey="emitClick"
  >
<!--    :class="color ? 'i-btn-color_' + color : ''"-->
    <slot></slot>

    <!-- 툴팁 지정시  -->
    <s-tooltip v-if="tip" :content="tip" />

  </q-btn>
</template>

<script>
import {throttle} from 'lodash'

export default {
  name: "i-bar-btn",
  inheritAttrs: false,
  props: {
    title: {type:String},
    tip: {type:String},
    // 단축키 지정 : skey="F6" 취소 skey="F7" 검색
    size:  {type: String, default: '12px'},
    skey: {type:String,},
    // color: {type:String,},
    icon: {type:String,},
    disable: {default: false},
  },
  // props: ['type', 'skey',],
  data() {
    return {
      keys : null,
      canClickEmit : true, // click emit 가능 여부
    }
  },
  computed: {
    dpTitle() {
      return this.skey ? this.title + '(' + this.skey + ')' : this.title;
    }
  },
  created() {
    this.keys = this.skey && this.skey.toLowerCase().split("+") || null
  },
  methods: {
    // emitClick(e) { this.$emit('click', e) },

    /** 0.5초 이내 연속 누름 무시 */
    emitClick: throttle(function(e) {
      this.$emit('click', e)
    }, 500, {trailing: false}),
  },
}
</script>
<!--
F7 검색
F6 취소(새등록)

Ctrl+S 저장
Alt+F5 선택처리

F3 전체선택
F4 삭제


F2 적용 / 소속변경
F8 엑셀 / 승인
F9 반려 / 저장 + 인쇄
F10 인쇄 미리보기

-->
