// noinspection ExceptionCaughtLocallyJS

/**
 * API 서버통신 관련
 */
import T from './index'
import Log from './tools_log'
import axios from 'axios'

const log = Log.getLogger("TOOLS-HTTP", 'info')

interface HttpErrorOption {
  kind ?: string
  type ?: string
  data ?: any
  error ?: any
}

class HttpError extends Error {
  kind: string
  type: string
  data: any
  cause: any

  constructor(msg: string, opt: HttpErrorOption = {}) {
    super(msg);
    this.name = "HttpError"
    this.kind = opt.kind
    this.data = opt.data
    this.type = opt.type
    opt.error && (this.cause = opt.error)
  }
  
  detail() {
    return `
    - kind = ${this.kind}
    - type = ${this.type}
    - msg = ${this}
    - data = ${JSON.stringify(this.data)}, cause = ${this.cause}`
  }
}

/*

가능한 url 형태

  /mgr_{mtype}_{rno}/dir/page.wt
  /wm/                +  session._roleno
  /shop_{brand}/

 REF http://artandheart.imdlocal.com:8080/mgr_admin_2/display/display.wt
 REF http://localhost:3000/display
 * /v1/api/display/detail?_r=0.3995934887159267&no=79
 * 그렇다면 referer 를 분석해서 권한지정이 가능.

 */


const setAxiosConfig = (config, method, url, data) => {

  // 메소드 및 url 설정
  config.method = method
  config.url    = url

  // 메소드별 데이터 설정
  if (method === "get") {
    config.params = {...data, _r: Math.random()} // get 인경우 랜덤 값 추가
  } else if (method ==="post") {
    config.data = data
  } else {
    throw new HttpError('잘못된 호출입니다. method 는 get 또는 method 만 가능합니다.', {kind: 'catch'})
  }

  // 헤더 설정
//  T.NF(localStorage.getItem('referer'), v=> config.headers = { "api-referer": v } )

  // 데이터 파싱 설정 (안해도 json 은 자동임. 만약 설정한다면 반드시 JSON.parse()는 포함 해야함)
  // config.transformResponse = [data=>JSON.parse(data)]

  return config;
}


const procInputError = (data) => {
  const errors = data.errors

  /*     {
        "status": "input-error",
        "msg": "입력에 오류가 있습니다.",
        "errors": [
          {
            "msg": "이름은 필수입력 입니다.",
            "ref": "name",
            "name": "name",
            "value": null
          },
          {
            "msg": "이메일은 필수입력 입니다.",
            "ref": "email1",
            "name": "email",
            "value": null
          }
        ]
      } */

  if (errors.length > 1) {

    let buf = "입력오류입니다\n\n";
    for (let i = 0; i < errors.length; i++) {
      buf += " - " + errors[i].msg + "\n"
    }
    T.alert(buf).then();

  } else {

    const error = errors[0]

    T.alert(error.msg).then();

    // 포커스
    // if (error.ref && vm.$refs[error.ref]) {
    //   try { vm.$refs[error.ref].focus() } catch(e) {}
    // } else if (error.name && vm.$refs[error.name]) {
    //   try { vm.$refs[error.name].focus()} catch(e) {}
    // }
  }

}

const apiRequest = async (method = 'get', path, data:any = {}, cb_succ = null, cbMap:any = {}) => {

  log.isDebug && log.debug("(서버 요청)", method.toUpperCase(), path, ", data =", (method === 'get' ? data : '(POST)'))

  const cb_fail = cbMap.fail
  const cb_failAfter = cbMap.failAfter
  const cb_catch = cbMap.catch
  const cb_catchAfter = cbMap.catchAfter
  const cb_final = cbMap.final

  // const config = setAxiosConfig({params: {_r: Math.random(), ...data}})
  let config = {};
  try {
    config = setAxiosConfig(config, method, path, data)
  } catch (e) {
    throw await T.errorAlert(e)
  }

  let res

  try {
    res = await axios(config)

    log.debug("(서버 응답)", res)
    const json = res.data || {};
    const status = json.status
    const { msg, kind } = json

    // 정상응답일때
    if (status === "ok") {
      cb_succ && cb_succ(json, res) // 콜백 있으면 실행
      return json
    }

    // 정의된 오류 응답
    if (status === 'error') {
      if (cb_fail) { // 콜백 있으면 실행
        cb_fail(json, res)
        throw new HttpError(msg, {kind: status, data: json})
      } else { // 없으면 alertError
        if (json?.type === "alert") {
          await T.alert(msg)
          cb_failAfter && cb_failAfter(json, res)
          throw new HttpError(msg, {kind: status, data: json, type: json.type})
        } else {
          await T.alertError(msg)
          cb_failAfter && cb_failAfter(json, res)
          throw new HttpError(msg, {kind: status, data: json})
        }
      }
    }

    // 정의된 오류 응답
    if (status === 'input-error') {
      procInputError(json) // 입력오류면 별도 조치
      cb_failAfter && cb_failAfter(json, res); // procInputError 가 cb_fail 역할을 했으니 cb_failAfter 만 있으면 실행
      throw new HttpError(msg, {kind: status, data: json})
    }

    const errMsg = "정의되지 않은 오류가 발생했습니다 CODE[JRS0231]\n\n" + (msg && ("message: " + msg) || "")

    // 기타 오류
    if (cb_fail) { // 콜백 있으면 실행
      cb_fail(json, res)
      throw new HttpError(errMsg, {kind: status || 'unknown', data: json})
    } else { // 없으면 alertError
      await T.alertError(errMsg)
      cb_failAfter && cb_failAfter(json, res)
      throw new HttpError(errMsg, {kind: status || 'unknown', data: json})
    }

  } catch (error) {

    // log.error("catch(error) :", error)
    // log.error("catch(error) debug :", T.debug(error))
    log.error("catch(error) json :", JSON.stringify(error, null, 2))
    log.error("catch(error.response) :", error.response)
    // log.error("error instanceof :", typeof(error))
    // log.error("error instanceof HttpError :", (error instanceof HttpError))

    // instanceof HttpError 는 작동하지 않는다.. 대신 .name 을 사용한다.
    if (error.name === "HttpError" || error instanceof HttpError) throw error

    if (cb_catch) {
      cb_catch(error)
      throw new HttpError(error.message, {kind: 'catch', error: error})
    } else {
      await T.alertError(getAxiosErrorMessage(error))
      cb_catchAfter && cb_catchAfter(data, res)
      throw new HttpError(error.message, {kind: 'catch', error: error})
    }

  } finally {
    cb_final && cb_final()
  }
}

const getAxiosErrorMessage = (error) => {
  if (T.isNU(error)) return "오류가 발생했습니다 CODE[UXOWK301]"
  if (error.response && error.response.status) {
    if (error.response.status === 504)
      return "오류가 발생했습니다 CODE[UXOWK302]" +
        "\n\nAPI서버로부터 응답을 받을 수 없습니다." +
        "\n네트워크를 사용할 수 없거나 서버상태가 불안정 합니다." +
        T.NF(error.message, v=>"\n\nMessage: "+v, "")
  }
  return "오류가 발생했습니다 CODE[UXOWK303]" + T.NF(error.message, v=>"\n\n message: "+v, "")
}

export default {

  apiRequest,

  /**
   * get 방식으로 http json api 를 호출한다.
   * 응답시 data 는 res.data 이다. res는 통시관련 정보를 담고 있다. res.data 또는 data 를 쓰면 된다.
   * @param {string}   path             요청 주소
   * @param {{}}       data             전송 데이터
   * @param {Function} cb_succ          성공시 콜백  함수
   * @param {{}}       cbMap            콜백 맵 - 여러가지 케이스
   * @param {Function} cbMap.fail       [콜백맵] 응답상태(status)가 "ok"가 아닌 경우 처리 전담
   * @param {Function} cbMap.failAfter  [콜백맵] 응답상태(status)가 "ok"가 아닌 경우 alert 처리는 자동으로 하고 그 이후 실행 (단, cbMap.fail 사용시에는 무시된다)
   * @param {Function} cbMap.catch      [콜백맵] 통신 자체에서 오류가 발생한 경우
   * @param {Function} cbMap.catchAfter [콜백맵] 통신 자체에서 오류가 발생한 경우
   * @param {Function} cbMap.final      [콜백맵] 완료던 오류던 어쨌든 종료 후 무조건 실행
   * @returns {Promise<any>}            <pre>성공시 resolve(data, res)
   * 실패시 reject(reason, res|errorObject) (3가지)
   *    'input', data, res   : 입력값 체크오류 (status = 'input-error')
   *    'error', data, res   : 서버응답 오류 (status = 'error'인 응답)
   *    'not_ok', data, res  : 응답을 받긴 했는데 error, input-error, ok 전부 아닌경우.
   *    'catch', errorObject : 전송자체에서 오류가 발생한 경우. 그래서 data도 res도 없다.
   * </pre>
   */
  apiGet: async <T = any>(path, data = {}, cb_succ = null, cbMap = {}): Promise<T> =>
    await apiRequest('get', path, data, cb_succ, cbMap),

  /**
   * post 방식으로 http json api 를 호출한다.
   * 응답시 data 는 res.data 이다. res는 통시관련 정보를 담고 있다. res.data 또는 data 를 쓰면 된다.
   * @param {string}   path             요청 주소
   * @param {{}}       data             전송 데이터
   * @param {Function} cb_succ          성공시 콜백  함수
   * @param {{}}       cbMap            콜백 맵 - 여러가지 케이스
   * @param {Function} cbMap.fail       [콜백맵] 응답상태(status)가 "ok"가 아닌 경우 처리 전담
   * @param {Function} cbMap.failAfter  [콜백맵] 응답상태(status)가 "ok"가 아닌 경우 alert 처리는 자동으로 하고 그 이후 실행 (단, cbMap.fail 사용시에는 무시된다)
   * @param {Function} cbMap.catch      [콜백맵] 통신 자체에서 오류가 발생한 경우
   * @param {Function} cbMap.catchAfter [콜백맵] 통신 자체에서 오류가 발생한 경우
   * @param {Function} cbMap.final      [콜백맵] 완료던 오류던 어쨌든 종료 후 무조건 실행
   * @returns {Promise<unknown>}        <pre>성공시 resolve(data, res)
   * 실패시 reject(reason, res|errorObject) (3가지)
   *    'input', data, res   : 입력값 체크오류 (status = 'input-error')
   *    'error', data, res   : 서버응답 오류 (status = 'error'인 응답)
   *    'not_ok', data, res  : 응답을 받긴 했는데 error, input-error, ok 전부 아닌경우.
   *    'catch', errorObject : 전송자체에서 오류가 발생한 경우. 그래서 data도 res도 없다.
   * </pre>
   */
  apiPost: async <T = any>(path, data = {}, cb_succ = null, cbMap = {}): Promise<T> =>
    await apiRequest('post', path, data, cb_succ, cbMap),

  apiAuto: (path, data = {}, cb_succ = null, cbMap = {}) => {

    const hasObject = Object.values(data).some(v=>{
      const vtype = typeof v
      if (vtype === 'string' || vtype === 'number') return false
      if (_.isArray(v)) return true
      if (_.isPlainObject(v)) return true
      return false
    })

    const method = hasObject ? 'post' : 'get'
    log.debug("API AUTO : method = " + method + " path = " + path)

    return apiRequest(method, path, data, cb_succ, cbMap)
  },

  axiosGet  : (path:string, data:{[key:string]:any}) => axios(setAxiosConfig({}, "get", path, data)),
  axiosPost : (path:string, data:{[key:string]:any}) => axios(setAxiosConfig({}, "post", path, data)),


  apiPostItem: async <T=any>(path, data = {}):Promise<T> => (await apiRequest('post', path, data, null, {})).item,
  apiPostList: async <T=any>(path, data = {}):Promise<T> => (await apiRequest('post', path, data, null, {})).list,
  apiGetList : async <T=any>(path, data = {}):Promise<T> => (await apiRequest('get' , path, data, null, {})).list,
  apiGetItem : async <T=any>(path, data = {}):Promise<T> => (await apiRequest('get' , path, data, null, {})).item,

}



