<template>
<!--    {{currentPage}} / {{pageCount}}-->
<!--    <div style="height: 500px; overflow-y: auto;">-->
<!--    border: 1px solid blue-->
  
<!--  <div style="max-height: 700px;">-->
    <pdf
      ref="pdf_ref"
      class="s-pdf-viewer"
      :source="src"
      @loaded="onLoaded"
      @rendered="onRendered"
      @loading-failed="onLoadingFailed"
      @rendering-failed="onRenderingFailed"
    />
<!--  </div>-->
</template>

<script setup lang="ts">
import {computed, PropType, ref, watch} from "vue";
// https://github.com/hrynko/vue-pdf-embed/tree/v1
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed' // https://github.com/hrynko/vue-pdf-embed#readme

const pdf_ref = ref()
const props = defineProps<{ src ?: string }>()

function onLoaded() {
  console.log(`PDF 파일 로딩 완료 : ${props.src}`)
}

function onRendered() { // 로딩 완루 후 랜더인 완료
  console.log(`PDF 파일 랜더링 완료 : ${props.src}`)
}

function onLoadingFailed() {
  console.warn(`PDF 파일 로딩 실패 : ${props.src}`)
}

function onRenderingFailed() {
  console.warn(`PDF 파일 랜더링 실패 : ${props.src}`)
}

function render() {
  console.log("랜더")
  pdf_ref.value?.render()
}
defineExpose({ render })
</script>

<style scoped>
.s-pdf-viewer { width: 100% }
</style>