
const _isLocal = /(192\.168|local)/.test(location.host)


const tools_debug = {
  http: false,
}

const is_debug = {
  http: false,
  s_table: false,
  s_drag_table: false,
}

export default {

  mode: 'dev',

  mode_is_dev: true,

  tools_debug,
  is_debug,

  API_PATH : "/emp/api",

  LOGIN_PATH  : "/emp/login",
  LOGOUT_PATH : "/emp/logout",

  AUTH_TOKEN_NAME   : "emptoken",
  AUTH_TOKENTS_NAME : "emptokents",

  IS_LOCAL : _isLocal,
  // IS_LOCAL : false,

  TEST_HOST : "http://artandheart.imdlocal.com:8080",
}

