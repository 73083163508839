<template>
  <div class="s-mini-table-wrap">
    <table class="s-mini-table">
      <thead>
        <tr>
          <th v-for="(th, i) in Object.keys(data)" :key="i">
            {{ th }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(td,i) in Object.values(data)" :key="i">
            {{ td }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    data: { default: {} }
  }
}
</script>

<style scoped>
.s-mini-table-wrap {
  padding: 2px;
  width: 100%
}
.s-mini-table {
  width: 100%;
  /*border: 1px solid #d3d3d3;*/
}
.s-mini-table >>> th {
  font-weight: normal;
  background-color: #EFEFEF;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.s-mini-table >>> td {
  text-align: center;
  /*border: 1px solid #E0E0E0;*/
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
</style>
