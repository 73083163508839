<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script setup lang="ts">
import {co_login} from '@/lib/co-login'
import {onBeforeMount} from "vue";
const {getLoginStatus} = co_login()

/** 첫 호출시 자동으로 현재 로그인 상태 조회 */
onBeforeMount(() => getLoginStatus('[co-login.ts] onBeforeMount'))
</script>
