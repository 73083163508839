<template>
  <div>
    <input type="file" id="file-input" multiple @change="onInputChange" ref="ref_input_file"/>
  
    <DropZone :class="dropZoneClass" @files-dropped="addFiles" #default="{ dropZoneActive }">
      <label id="label-file-input" for="file-inputXXXX">
        <slot name="dropZone" :dropZoneActive="dropZoneActive" />
      </label>
    </DropZone>
  
    <slot/>
  </div>
<!--  <pre>{{ JSON.stringify(files, null, 2) }}</pre>-->
</template>


<script setup lang="ts">
import {computed, PropType, ref, watch} from "vue";
import {AttachFile} from "./uploader/file-model";
import DropZone from "./uploader/DropZone.vue";
// import Vue from 'vue'
// const VUE3 = Vue.version.startsWith('3.')

const props = defineProps({
//modelValue: {type: Array as PropType<AttachFile[]>}, // vue 3.x
  value     : {type: Array as PropType<AttachFile[]>}, // vue 2.7
  dropZoneClass: {type: String, default: 'drop-area'},
  /** 첨부파일을 추가한다 <pre>
   * - resize 같은 조작을 해도 된다.
   * - 확장자 체크를 해도 된다.
   * - 체크해서 마음에 안들경우 null 을 반환하면 추가되지 않는다.
   * </pre> */
  addAttachFile: {type: Function as PropType<(afile:AttachFile, files:AttachFile[])=>Promise<AttachFile|null>>},
})
// const emit = defineEmits(['update:modelValue', 'added'])
const emit = defineEmits<{
//(e: 'update:modelValue', attachFiles: AttachFile[]): void  // vue 3.x
  (e: 'input'            , attachFiles: AttachFile[]): void // vue 2.7
  // (e: 'added'            , addedAttachFiles: AttachFile[]): void
}>()

/** 파일 목록*/
const files = ref<AttachFile[]>([])
const ref_input_file = ref()

//watch(() => props.modelValue, (n) => { // vue 3.x
watch(() => props.value, (n) => {  // vue 2.7
  files.value = n
})

/** 업로드 가능여부 : 업로드 완료랑 업로드중이 아닌 다른거 있으면 가능*/
const canUpload = computed(() =>
  files.value.some(({status: s}) => s !== "uploaded" && s !== "uploading"))

// const updateFiles = (list:AttachFile[]) => emit('update:modelValue', files.value = list)
const updateFiles = (list:AttachFile[]) => emit('input', files.value = list)

const uploadedFiles = computed(() => files.value.filter(({status: s}) => s === "uploaded"))
const queuedFiles = computed(() => files.value.filter(({status: s}) => s !== "uploaded"))
const removeUploadedFiles = () => updateFiles(queuedFiles.value)
const removeQueuedFiles   = () => updateFiles(uploadedFiles.value)

defineExpose({
  removeFile,
  pickFiles: () => ref_input_file.value.click(),
  reset: () => updateFiles([]),
  canUpload,
  uploadedFiles, queuedFiles,
  removeUploadedFiles, removeQueuedFiles,
})


/** 파일들 추가 */
async function addFiles(newFiles: File[]) {

  let cnt = 0

  for (const file of newFiles) {
    let afile = new AttachFile(file) // File 을 AttachFile 로 변환

    if (fileExists(afile.id)) {
      console.log("이미 있는 파일 : ", afile)
      continue
    }

    if (props.addAttachFile) { // 리사이즈 콜백이 있으면 실행한다.
      afile = await props.addAttachFile(afile, files.value)
      if (afile === null) continue
    }

    files.value.push(afile)
    cnt++
  }

  if (cnt < 1) return

  updateFiles(files.value) // 기존 files 에 추가
  // files.value = files.value.concat(newAttachFiles)
  // emit('update:modelValue', files.value)
}

/** 파일이 이미 있나 */
function fileExists(otherId) {
  return files.value.some(({id}) => id === otherId)
}

/** 파일 삭제 */
function removeFile(file) {
  const index = files.value.indexOf(file)
  if (index > -1) {
    files.value.splice(index, 1)
  }
  updateFiles(files.value)
}

async function onInputChange(e) {
  // console.log("e.target.files = ", e.target.files)
  await addFiles(e.target.files) // 여기서 wait 하지 않으면 이 다음에 e.target.value = null 에 의해서 하나만 업로드 된다.
  e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
}





</script>

<!--<script setup lang="ts">-->
<!--import DropZone from "./uploader/DropZone.vue";-->
<!--import useFileList from "./uploader/file-list";-->
<!--</script>-->

<style scoped lang="sass">

// input file 태그 감추기
#file-input
  position: absolute !important
  width: 1px !important
  height: 1px !important
  padding: 0 !important
  margin: -1px !important
  overflow: hidden !important
  clip: rect(0, 0, 0, 0) !important
  white-space: nowrap !important
  border: 0 !important

// 레이블 클릭되는거라서 커서를 포인터로 설정
//#label-file-input
//  cursor: pointer


// :deep(.drop-area) -- 호출하는 곳에서는 이런식으로 사용
// 그리고 drop-area 라는 클래스는 drop-zone-class 속성으로 변경 가능. 기본값이  drop-area 일 뿐이다.
.drop-area
  //padding: 10px
  //margin: 10px
  background: #ffffff55
  transition: .2s ease
  border: 1px solid #CCCCCC
  border-radius: 5px

  &[data-active=true]
    background: #d7ffab

</style>
