<template>
  <q-checkbox
    class="i-input-chk"
    v-bind="{...$attrs, val, trueValue, falseValue, disable}"
    v-on="$listeners"
    size="32px"
    dense
    :label="label"
  >
    <!-- 툴팁 지정시  -->
    <s-tooltip v-if="tip" :content="tip" />

  </q-checkbox>
</template>

<script>
export default {
  inheritAttrs: false,
  // model: {
  //   prop: 'value',
  //   event: 'input'
  // },
  props: {
    trueValue:  { default: '1' },  // true/fales 모드 사용시 -- 체크했을때 값
    falseValue: { default: '0' },  // true/fales 모드 사용시 -- 체크 안했을때 값
    val:        {},                // array 모드 사용시
    label:      { type: String },
    tip:        { type: String },
    disable:    { type: Boolean, default: false },
  }
}
</script>
<!--

  * 일반 true/false 모드

  <i-input-chk v-model="flag" true-value="1" false-value="0"/>

  * array 모드

  <i-input-chk v-model="media_selected" :val="item.no"/>

-->
