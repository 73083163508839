/**
 * alert, confirm, prompt, notify 꾸미기
 */

import T from './index'
// import alertify from "alertifyjs";
import iziToast from 'izitoast'
import Swal, {SweetAlertInput, SweetAlertOptions} from 'sweetalert2'

iziToast.settings({
  timeout: 4000,
  position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
  closeOnEscape: true,
  closeOnClick: true,
  // resetOnHover: true,
  // icon: 'material-icons',
  // transitionIn: 'flipInX',
  // transitionOut: 'flipOutX',
  // onOpening: function(){console.log('callback abriu!');},
  // onClosing: function(){console.log("callback fechou!");},
});

export default {

  /**
   * Swal alert
   * @param {string} msg 메세지
   * @param {string} title (기본:알림) 창 제목
   * @returns {Promise<unknown>}
   */
  alert: async (msg: string, title ?: string) => {
    const opt = {
      icon: 'info',
      title,
      allowOutsideClick: false,
      confirmButtonText: '확인',
      customClass: {
        htmlContainer: 'swal-content',
        confirmButton: 'swal-confirmButton',
      }
    } as SweetAlertOptions

    if (msg.indexOf("\n") !== -1) {
      opt.html = msg.replace(/\n/g, '<br/>')
    } else {
      opt.text = msg
    }

    return await Swal.fire(opt)
  },

  /**
   * 이쁜 alert
   * @param {string} msg 메세지
   * @param {string} title (기본:오류) 창 제목
   * @returns {Promise<unknown>}
   */
  alertWarn: async (msg: string, title = '경고') => {

    const opt = {
      icon: 'warning',
      title,
      allowOutsideClick: false,
      confirmButtonText: '확인',
      customClass: {
        htmlContainer: 'swal-content',
        confirmButton: 'swal-confirmButton',
      }
    } as SweetAlertOptions

    if (msg.indexOf("\n")) {
      opt.html = msg.replace(/\n/g, '<br/>')
    } else {
      opt.text = msg
    }

    return await Swal.fire(opt)
  },


  /**
   * 이쁜 alert
   * @param {string} msg 메세지
   * @param {string} title (기본:오류) 창 제목
   * @returns {Promise<unknown>}
   */
  alertError: async (msg: string, title = '오류') => {

    const opt = {
      icon: 'error',
      title,
      allowOutsideClick: false,
      confirmButtonText: '확인',
      customClass: {
        htmlContainer: 'swal-content',
        confirmButton: 'swal-confirmButton',
      }
    } as SweetAlertOptions

    if (msg.indexOf("\n")) {
      opt.html = msg.replace(/\n/g, '<br/>')
    } else {
      opt.text = msg
    }

    return await Swal.fire(opt)
  },

  /**
   * Error Alert
   * 오류메세지를 Alert 하고 오류객체를 반환한다.
   * 만약 오류객체가 아니라 문자열이면 new Error(문자열) 결과를 반환한다.
   * @param {Error|string} error 오류객체 또는 오류메세지
   */
  errorAlert: async (error: Error | string) => {
    const errorObject = error instanceof Error ? error : new Error(error)
    const msg = T.newlineToBr(errorObject.message);
    await T.alertError(msg);
    return errorObject;
  },


  /**
   * 이쁜 confirm
   * @param {string} msg
   * @param {string} title
   * @param opt
   * @returns {Promise<unknown>} 예 true 아니오 false
   */
  confirm: async (msg: string, title ?: string, opt ?:AlertOption): Promise<unknown> => {
    const option = {
      icon: 'question',
      title,
      allowOutsideClick: false,
      confirmButtonText: '예',
      showCancelButton: true,
      cancelButtonText: '아니오',
      customClass: {
        htmlContainer: 'swal-content',
        confirmButton: 'swal-confirmButton',
      }
    } as SweetAlertOptions

    if (opt?.icon === "none") {
      option.icon = null
    }

    if (msg.indexOf("\n") !== -1) {
      option.html = msg.replace(/\n/g, '<br/>')
    } else {
      option.text = msg
    }

    return (await Swal.fire(option)).isConfirmed
  },

  /**
   * 이쁜 prompt
   * @param {string} msg
   * @param {string} curValue
   * @param {string} title
   * @param {string} type text, password
   * @returns {Promise<unknown>}
   */
  prompt: async (msg: string, curValue, title: string = '입력', type : SweetAlertInput = 'text') => {
    const opt = {
      input: type,
      // input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      icon: 'question',
      title,
      allowOutsideClick: false,
      confirmButtonText: '확인',
      showCancelButton: true,
      cancelButtonText: '취소',
      customClass: {
        input: 'swal-inputBox',
        htmlContainer: 'swal-content',
        confirmButton: 'swal-confirmButton',
      }
    } as SweetAlertOptions

    if (msg.indexOf("\n")) {
      opt.html = msg.replace(/\n/g, '<br/>')
    } else {
      opt.text = msg
    }

    const ret = await Swal.fire(opt)
    // console.log("ret = " + JSON.stringify(ret, null, 2))

    if (!ret.isConfirmed) return undefined
    return ret.value;
  },

  /**
   * @typedef {('success'|'error'|'warning')} NotiType - 알림 형태
   * @typedef {('top-left',   'top-center',   'top-right'
   *        ,'bottom-left','bottom-center','bottom-right')} NotiPosition - 알림 위치
   */

  /**
   * notify 를 띄운다.
   * @param {string}          msg              메세지
   * @param {{}}              option           옵션객체
   * @param {NotiType}        option.type      알림형태 (기본:custom)
   * @param {NotiPosition}    option.position  위치 (기본:top-center)
   * @param {number}          option.delay     딜레이 (기본5(초))
   * @param {Function}        option.callback  notify 끝날때 호출되는 Function
   */
  notify: (msg, option = {}) => {
    // https://izitoast.marcelodolza.com/
    // izitoast.show({
    iziToast.info({
      message: msg,
      // theme: 'light', //light,  dark
      position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
      closeOnEscape: true,
      closeOnClick: true,
      timeout: 4000,
    })
    return true
  },

  /** T.notify 와 동일한데 type 기본값이 success 이다. */
  noti_success: (msg, opt = {}) => iziToast.success({message: msg, ...opt}),

  /** T.notify 와 동일한데 type 기본값이 error 이다. */
  noti_error  : (msg, opt = {}) => iziToast.error({message: msg, ...opt}),

  /** 현재 떠있는 모든 notify 를 제거한다. */
  notify_clear: () => iziToast.destroy(), // notify 전체 없애기

}

export interface AlertOption {
  icon ?: null | 'none' | string
}
