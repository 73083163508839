<template>
  <span>
    <q-icon
      @click="toggle_tip"
      :name="iconName"
      :size="iconSize"
      style="margin-top: -1px; cursor: pointer"
      class="self-center no-padding"
      :class="{ 'q-ml-xs': marginLeft }"
    >
    </q-icon>
    <span>
      <s-tooltip
        v-model="show_tooltip"
        :content-class="T.mergeClass('imd-tooltip', contentClass)"
        :content-style="contentStyle"
        :content="content"
      >
        <slot/>
      </s-tooltip>
    </span>
  </span>
</template>

<script lang="ts">

import {defineComponent, onMounted, reactive, ref, toRefs} from "vue"

export default defineComponent({
  props: {
    iconName:         { type: String, default: 'help' }, // 아이콘 이름
    iconSize:         { type: String, default: '15px' }, // 아이콘 사이즈
    contentClass:     { type: [String,Object], default: 'null' },
    contentStyle:     { type: [String,Object], default: 'null' },
    content:          { type: String},
    marginLeft:       { type: Boolean, default: false }, // 왼쪽 마진 지정 여부 ( 전의 내용이 태그로 끝날때 필요하다 )
  },
  setup() {
    const show_tooltip = ref(false);
    const toggle_tip = () => show_tooltip.value = !show_tooltip.value
    return { show_tooltip, toggle_tip }
  }
})
</script>
