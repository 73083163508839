
// 이미지 리사이즈 & 회전 기능

import loadImage from 'blueimp-load-image' //  https://github.com/blueimp/JavaScript-Load-Image
import 'blueimp-canvas-to-blob/js/canvas-to-blob.min' // [ie 호환성] toBlob ie polyfill
import T from 'lib/tools'

const maxPixcel = 2048; // 가로세로 최대길이 // 대략 1.5메가.. 일단 이걸로 가자.
// const maxPixcel = 2560; // 가로세로 최대길이 // 대략 2메가..

/**
 * 기본속성복사.
 * 날짜는 지금날짜... 보통 촬영시간 있을테니깐 그거 쓰라지.
 */
function copyBaseAttr_old(orgFile, newFile) {
  newFile.lastModifiedDate = new Date();
  // newFile.lastModifiedDate = orgFile.lastModifiedDate
  newFile.name    = orgFile.name;
  newFile.orderNo = orgFile.orderNo
  newFile.resized = true; // 속성복사는 리사이즈 작업 종료 후기 때문에 true 고정
  return newFile;
}

/**
 * 기본속성복사.
 * 날짜는 지금날짜... 보통 촬영시간 있을테니깐 그거 쓰라지.
 */
function copyBaseAttr(orgFile: File, newFile: Blob): File {
  // console.log("newFile = ", newFile)
  const retFile = new File([newFile], orgFile.name, {
    lastModified: new Date().getTime(),
    type: newFile.type ?? 'image/png',
  });
  if ('orderNo' in orgFile) retFile['orderNo'] = orgFile['orderNo']
  // retFile['resized'] = true
  return retFile
}

export type ImageRotateKind = 'left' | 'right' | 'init' | 'none'

export interface LoadImageOption {
  maxWidth  ?: number
  maxHeight ?: number
  /** meta 정보 복사할지 말지 여부 */
  metaCopy  ?: boolean
  /** 이미지 회전 옵션*/
  rotate    ?: ImageRotateKind
}

const _loadImageOptionDefault: LoadImageOption = {
  maxWidth : maxPixcel,
  maxHeight: maxPixcel,
  metaCopy : true,
  rotate   : 'none'
}

function succCheckImg(orgFile, newFile) {
  const newSize = newFile?.size ?? 0
  console.log("new size =", newSize)
  if (newSize < (100 * 1024)) { // 사이즈가 없거나 100K 이내라면  
    console.log("리사이즈 용량이 100K 보다 적어서 원본을 사용합니다.")
    orgFile.resized = true
    return orgFile
  }
  return copyBaseAttr(orgFile, newFile)
}

// 코어함수. 리사이즈는 무조건. rotate 옵션을 주면 회전도 한다.
export const loadImageAsync = (orgFile: File, opt: LoadImageOption = {}): Promise<File> => new Promise((resolve, reject)=> {

  const {maxWidth, maxHeight, metaCopy, rotate} = T.setDefaults(opt, _loadImageOptionDefault)

  // blueimp-load-image 플러그인 옵션 설정  //  https://github.com/blueimp/JavaScript-Load-Image
  const loadImageOption:any = {
    meta: true, /// 이건 필수... 옵션으로 들어온 meta랑 상관 없이 필수. false 로 하면 무조건 오류난다 ㅠㅠ
    canvas: true,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    // orientation: true,
    // orientation: T.case(rotate, 'right', 6, 'left', 8, 1),
    imageSmoothingEnabled: false, // 이걸 true 로 하면 회전할때마다 저질된다... 아니 될꺼 같아서 그냥 false 로 했다 .안해봄. 귀찮..
    // imageSmoothingQuality: 'high', // 'low', 'medium', 'high' -- https://github.com/blueimp/JavaScript-Load-Image#imagesmoothingquality
  }

  // 회전이 필요한 경우
  if (rotate === 'right') loadImageOption.orientation = 6 // https://github.com/blueimp/JavaScript-Load-Image#orientation
  if (rotate === 'left') loadImageOption.orientation = 8
  if (rotate === 'init') loadImageOption.orientation = 1 // 최초 로딩시에 exif 정보를 읽을 수 있다면 똑바로 위치 시킨다. IE 11 미작동
  // console.log("loadImageOption.orientation ======> ", loadImageOption.orientation)

  loadImage(orgFile, (eventOrImage, data) => {

    // png 같은건 imageHead 가 없다..
    // if (!data.imageHead) return reject(Error('이미지 정보를 읽을 수 없습니다.'));

    // console.log("eventOrImage.toBlob = ", eventOrImage.toBlob)
    // console.log('Original image head: ', data.imageHead) // IE11에서도 된다 ?
    // console.log('Exif data: ', data.exif) // requires exif extension -- 이게 뭔가 그럴싸한 정보가 많다.
    // console.log('IPTC data: ', data.iptc) // requires iptc extension

    // if (data.exif) {
    //   const orientation = data.exif.get('Orientation')
    //   const orientationText = data.exif.getText('Orientation')
    //   console.log("orientation = ", orientation)
    //   console.log("orientationText = ", orientationText)
    // }

    // Event | HTMLCanvasElement | HTMLImageElement
    // (eventOrImage as Event).type
    // (eventOrImage as HTMLCanvasElement).toBlob()
    // (eventOrImage as HTMLImageElement).toBlob()

    // if (eventOrImage?.type === 'error') {
    if (eventOrImage instanceof Event && eventOrImage.type === 'error') {
      console.log("오류 객체 :", eventOrImage)
      T.noti_error('이미지 로딩 실패 : ' + orgFile['name'])
      orgFile['resized'] = true
      console.log("리사이드 된척하고 반환", orgFile)
      resolve(orgFile)
      
      // return reject(Error("이미지 로딩 오류입니다.(ERROR) 이미지가 맞는지 확인해 주세요."))
      // return resolve(orgFile)
    }

    if (!("toBlob" in eventOrImage)) { // 이미지가 아닌경우
      console.log(orgFile)
      // resolve(orgFile)
      return reject(Error("이미지 로딩 오류입니다.(NO BLOB) 이미지가 맞는지 확인해 주세요."))
    }

    
    // noinspection TypeScriptUnresolvedReference
    eventOrImage.toBlob(blob => { // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      // console.log("toBlob result =", blob)
      if (metaCopy) { // meta 정보 복사여부  ( 촬영시각, 제작자등 )
        loadImage.replaceHead(blob, data.imageHead, (newBlob) => {
          if (newBlob === null) { // 실패하면 ( png 같은건 실패한다)
            resolve(succCheckImg(orgFile, blob)) // 그냥 원래꺼
          } else {
            resolve(succCheckImg(orgFile, newBlob))
          }
        })
      } else {
        resolve(succCheckImg(orgFile, blob))
      }
    }, 'image/jpeg',0.92)  // quality : IE11는 9.2에 근접
  }, loadImageOption)

})

export function imageResize(orgFile, metaCopy = true): Promise<File> {
  return loadImageAsync(orgFile, {metaCopy, rotate: 'init'});
}

export function imageRotate(orgFile: File, rotate: ImageRotateKind, metaCopy = true): Promise<File> {
  return loadImageAsync(orgFile, {metaCopy, rotate,});
}


const imageToBlobAsync = (image):Promise<Blob> => new Promise((resolve, reject) => {
  image.toBlob(blob => { // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      // if (metaCopy) { // meta 정보 복사여부  ( 촬영시각, 제작자등 )
      //   loadImage.replaceHead(blob, data.imageHead, newBlob => resolve(newBlob))
      //   return
      // } else {
      resolve(blob)
      //   return
      // }
    }, 'image/jpeg'
    // ,0.9  // quality : 기본값은 0.92 그런데 IE는 quality 옵션 지원안함... 그냥 기본값 유지하자.
  )
})

export const imageLoadUrlToBlob2 = async (url, metaCopy = true):Promise<Blob> => {

  // 로딩 옵션
  const loadImageOption: any = {
    meta: true, /// 이건 필수... 옵션으로 들어온 meta랑 상관 없이 필수. false 로 하면 무조건 오류난다 ㅠㅠ
    canvas: true,
    // maxWidth: maxWidth,
    // maxHeight: maxHeight,
    imageSmoothingEnabled: false, // 이걸 true 로 하면 회전할때마다 저질된다... 아니 될꺼 같아서 그냥 false 로 했다 .안해봄. 귀찮..
    // imageSmoothingQuality: 'high', // 'low', 'medium', 'high' -- https://github.com/blueimp/JavaScript-Load-Image#imagesmoothingquality
  }

  const data = await loadImage(url, loadImageOption)
  if (!data          ) throw new Error('이미지 로딩에 실패했습니다. NO DATA')
  if (!data.imageHead) throw new Error('이미지 정보를 읽을 수 없습니다. NO HEAD')
  if (!data.image    ) throw new Error('이미지 로딩에 실패했습니다. NO IMAGE')

  console.log("data = ", data)
  console.log('Original image head: ', data.imageHead)
  console.log('Exif data: ', data.exif)
  console.log('IPTC data: ', data.iptc)
  console.log('data image: ', data.image)

  // blob 로 변환
  const blob = await imageToBlobAsync(data.image)
  // metaCopy일 경우 복사 후 반환
  if (metaCopy) return await loadImage.replaceHead(blob, data.imageHead)
  // 아니면 그냥 반환
  return blob
}

// export const imageLoadUrlToBlobFile = async (url, filename, metaCopy = true):Promise<File> => {
//   const blob = await imageLoadUrlToBlob(url, metaCopy)
//   console.log('blob = ', blob)
//   return new File([blob], filename, {
//     type: blob.type // 희안하게 blob에도 type이 있지만 복사가 안된다... 그냥 지정해야 한다.
//   })
// }

// new File([Blob], `my_image${new Date()}.jpeg`, {
//   type: "image/jpeg",
//   lastModified: new Date(),
//   size: 2,
// });


/**
 * URL 를 Blob로 로딩 <pre>
 *   ex)
 *   const blob = await imageLoadUrlToBlob('/_File/cust/gallery/2021/2021-10-07/002/20211007221243790z8spmg3v.jpg')
 *   blob['name'] = 'bbbbb.jpg'
 * </pre>
 * @param url
 * @param metaCopy
 * @param attr
 */
export const imageLoadUrlToBlob = (url, metaCopy= true, attr = {}):Promise<Blob> => new Promise((resolve, reject)=> {

  // blueimp-load-image 플러그인 옵션 설정  //  https://github.com/blueimp/JavaScript-Load-Image
  const loadImageOption:any = {
    meta: true, /// 이건 필수... 옵션으로 들어온 meta랑 상관 없이 필수. false 로 하면 무조건 오류난다 ㅠㅠ
    canvas: true,
    imageSmoothingEnabled: false, // 이걸 true 로 하면 회전할때마다 저질된다... 아니 될꺼 같아서 그냥 false 로 했다 .안해봄. 귀찮..
    // imageSmoothingQuality: 'high', // 'low', 'medium', 'high' -- https://github.com/blueimp/JavaScript-Load-Image#imagesmoothingquality
  }

  loadImage( url, (img, data) => {
    // console.log("ddddddddd img = ", img)
    // console.log("ddddddddd data = ", data)
    // id 에서는 imageHead가 없다 ㅠㅠ
    // if (!data.imageHead) return reject(Error('이미지 정보를 읽을 수 없습니다.'));

      
    if (!("toBlob" in img)) {
      return
    }

    img.toBlob(blob => { // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
        if (metaCopy && data.imageHead) { // meta 정보 복사여부  ( 촬영시각, 제작자등 )
          loadImage.replaceHead(blob, data.imageHead, newBlob => {
            T.setOverwriteForce(newBlob, attr)
            resolve(newBlob)
          })
        } else {
          T.setOverwriteForce(blob, attr)
          return resolve(blob)
        }
      }, 'image/jpeg'
      // ,0.9  // quality : 기본값은 0.92 그런데 IE는 quality 옵션 지원안함... 그냥 기본값 유지하자.
    )}, loadImageOption)

})



// IE11 에서 new File() 이 작동불가로 포기
// export const imageLoadUrlToBlobFile = async (url, filename, metaCopy = true):Promise<File> => {
//   const blob = await imageLoadUrlToBlob(url, metaCopy)
//   console.log('blob = ', blob)
//   return new File([blob], filename, {
//     type: blob.type // 희안하게 blob에도 type이 있지만 복사가 안된다... 그냥 지정해야 한다.
//   })
// }
