<template>
  <div>
<!--    <s-content class="col-6">-->
    
<!--      <i-bar title="첨부 파일">-->
<!--        <i-btn title="PICK" @click="ref_uploader?.pickFiles()"/>-->
<!--        <i-btn title="RESET" @click="ref_uploader?.reset()"/>-->
<!--      </i-bar>-->

<!--      <q-list class="" style="min-width: 100px" bordered separator dense>-->

      <m-uploader ref="ref_uploader" v-model="files" :addAttachFile="doAddAttachFile">
        <template #dropZone="{dropZoneActive}">
          <!--            :style="{backgroundColor: dropZoneActive ? '#C4C4C4CC' : 'white'}"-->
          <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
            <!--          <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />-->
            <div class="col-auto">
              <div class="q-uploader__title">첨부</div>
              <!--            <div class="q-uploader__subtitle">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>-->
            </div>

            <div class="col text-center">
              <span v-if="dropZoneActive">
                여기에 드롭하면 파일이 추가되요.
              </span>
              <span v-else>
                여기로 파일을 끌어다 놓으세요.
              </span>
            </div>

            <i-btn title="업로드된 파일 제거" @click="ref_uploader?.removeUploadedFiles()"
                       v-if="ref_uploader?.uploadedFiles.length > 0"/>
            <i-btn title="추가 취소" @click="ref_uploader?.removeQueuedFiles()"
                       v-if="ref_uploader?.queuedFiles.length > 0"/>
<!--              <i-btn title="업로드" @click="doUpload" :disable="!canUpload"/>-->
            <i-bar-icon name="upload" tip="업로드" @click="doUpload" v-if="canUpload"/>
            <!--      <i-btn title="이미지 선택" @click="ref_uploader?.pickFiles" v-if="scope.canAddFiles" />-->
<!--              <i-btn title="파일 추가" @click="ref_uploader?.pickFiles()"/>-->
            <i-bar-icon name="add" tip="파일 추가" @click="ref_uploader?.pickFiles()"/>
            <!--          <q-btn v-if="scope.isUploading" icon="clear" @click="scope.abort" round dense flat />-->
          </div>
        </template>

        <q-list style="border-top: none;" bordered separator dense v-show="files?.length">
            
          <!--          vue 3.x -->
          <!--          <draggable-->
          <!--            v-model="files"-->
          <!--            :animation="200"-->
          <!--            handle=".dhandle">-->
          <!--            <template #item="{element: afile, index}: {afile: AttachFile}">-->
          <!--              <div>-->
          <!--                <q-separator/>-->

          <!--                <q-item>-->
          <!--                  <q-item-section>-->
          <!--                    <q-item-label class="full-width ellipsis">{{ afile.name }}</q-item-label>-->
          <!--                    <q-item-label caption>상태: {{ afile.statusName() }}</q-item-label>-->
          <!--                    &lt;!&ndash;                  <q-item-label caption>{{ afile.sizeLabel }} / {{ file.__progressLabel }}</q-item-label>&ndash;&gt;-->
          <!--                    <q-item-label caption>{{ afile.sizeLabel }}</q-item-label>-->
          <!--                    <q-linear-progress :value="afile.uploadProgress" color="accent" animation-speed="70"/>-->
          <!--                  </q-item-section>-->

          <!--                  <q-item-section v-if="!afile.no_img" thumbnail class="gt-xs cursor-pointer" @click="popup(afile)">-->
          <!--                    &lt;!&ndash;                <q-item-section v-if="!afile.no_img" thumbnail class="gt-xs cursor-pointer">&ndash;&gt;-->
          <!--                    <img :src="afile.url" style="border-radius: 5px;">-->
          <!--                  </q-item-section>-->

          <!--                  <q-item-section middle side>-->
          <!--                    <q-btn icon="delete" class="gt-xs" size="12px" flat dense round-->
          <!--                           @click="ref_uploader.removeFile(afile)"/>-->
          <!--                    <q-icon name="drag_handle" class="dhandle cursor-pointer"/>-->
          <!--                  </q-item-section>-->
          <!--                </q-item>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </draggable>-->

          
          <!--          vue 2.7 -->

          
            
          <draggable
              v-model="files"
              :animation="200"
              handle=".dhandle">
            <div v-for="(afile,idx) in files" :key="afile.id">

              <q-separator v-if="idx>0"/>

              <q-item>
                <q-item-section>
                  <q-item-label class="full-width ellipsis">{{ afile.name }}</q-item-label>
                  <q-item-label caption>상태: {{ afile.statusName() }}</q-item-label>
                  <!--                  <q-item-label caption>{{ afile.sizeLabel }} / {{ file.__progressLabel }}</q-item-label>-->
                  <q-item-label caption>{{ afile.sizeLabel }}</q-item-label>
                  <q-linear-progress :value="afile.uploadProgress" color="accent" animation-speed="70"/>
                </q-item-section>

                <q-item-section v-if="!afile.no_img && afile.is_image" thumbnail class="gt-xs cursor-pointer" @click="popup(afile)">
                  <!--                <q-item-section v-if="!afile.no_img" thumbnail class="gt-xs cursor-pointer">-->
                  <img :src="afile.url" style="border-radius: 5px;" alt="">
                </q-item-section>

                <q-item-section middle side>
                  <q-btn icon="delete" class="gt-xs" size="12px" flat dense round
                         @click="ref_uploader.removeFile(afile)"/>
                  <q-icon name="drag_handle" class="dhandle cursor-pointer"/>
                </q-item-section>
              </q-item>

            </div>
          </draggable>

        </q-list>

      </m-uploader>

      

      <xmp v-if="debug">{{ JSON.stringify(files, null, 2) }}</xmp>

<!--    </s-content>-->


    <!-- 이미지 크게보기   -->
    <q-dialog v-model="modal_imageViewer"
              v-if="sitem"
              full-width square
              transition-show="none" transition-hide="none"
    >
      <q-card class="no-scroll fit">
        <q-img contain :src="sitem.url" class="bg-grey-7 fit">
          <div class="text-subtitle2 absolute-top text-right q-gutter-sm">
            <i-btn title="왼쪽으로 회전" @click="doRotate(sitem, 'left')"/>
            <i-btn title="오른쪽으로 회전" @click="doRotate(sitem, 'right')"/>
            <!--            <i-btn title="저장"/>-->
            <i-btn title="닫기(ESC)" @click="modal_imageViewer=false"/>
          </div>
        </q-img>
      </q-card>
    </q-dialog>


  </div>


</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import {AttachFile} from "@/components-ui/uploader/file-model";
import {computed, PropType, ref, watch} from "vue";
import MUploader from "@/components-ui/m-uploader.vue";
import axios, {AxiosProgressEvent} from "axios";
import {imageRotate, ImageRotateKind, loadImageAsync} from "@/lib/img_utils";

const props = defineProps({
//modelValue: {type: Array as PropType<AttachFile[]>}, // vue 3.x
  value     : {type: Array as PropType<AttachFile[]>}, // vue 2.7
  uploadUrl : {type: String, default: '/api/wiki/board.do?cmd=upload_file'},
  debug     : {type: Boolean, default: false},
  /** 첨부파일을 추가한다 <pre>
   * - resize 같은 조작을 해도 된다.
   * - 확장자 체크를 해도 된다.
   * - 체크해서 마음에 안들경우 null 을 반환하면 추가되지 않는다.
   * </pre> */
  // addAttachFile: {type: Function as PropType<(afile:AttachFile, files:AttachFile[])=>Promise<AttachFile|null>>},
})

const emit = defineEmits<{
//(e: 'update:modelValue', attachFiles: AttachFile[]): void  // vue 3.x
  (e: 'input'            , attachFiles: AttachFile[]): void // vue 2.7
  // (e: 'added'            , addedAttachFiles: AttachFile[]): void
}>()

const ref_uploader      = ref<InstanceType<typeof MUploader> | null>(null)
const files             = ref<AttachFile[]>([])
const modal_imageViewer = ref<boolean>(false)
const sitem             = ref<AttachFile | null>(null) // 이미지 상세보기 모달 호출 관련



watch(() => props.value, (n) => {  // vue 2.7
  console.log("props.value 변경")
  files.value = n
}, {immediate: true})

watch(() => files.value, (n) => {  // vue 2.7
  console.log("files 변경")
  emit('input', n)
})


const canUpload = computed(() => ref_uploader.value?.canUpload);



const popup = item => {sitem.value = item;modal_imageViewer.value = true}
const doRotate = async (afile: AttachFile, rotate: ImageRotateKind) => {
  afile.updateFile(await imageRotate(afile.file, rotate, true))
}

/** 파일 추가시 리사이즈 등등 처리 */
const doAddAttachFile = async (afile: AttachFile, files: AttachFile[]) => {
  // console.log("리사이즈 전 meta", await loadImage.parseMetaData(afile.file))
  // console.log("리사이즈 전 data", await loadImage(afile.file))
  // console.log("files?.length = ", files?.length)
  if (files?.length >= 1) {
    await T.alertError("파일은 최대 1개까지만 첨부하실 수 있습니다.")
    return null
  }

  if (!afile.is_image) { // 이미지가 아니면 리사이즈 안함
    afile.status = "resizfail"
    afile.no_img = true
    return afile
  }

  try {
    afile.status = "resizing"
    const resizedFile = await loadImageAsync(afile.file, {maxWidth: 1280, metaCopy: true})
    afile.updateFile(resizedFile)
    afile.status = "resized"
  } catch (e) {
    afile.status = "resizfail"
    afile.no_img = true
  }
  // console.log("리사이즈 후 meta", await loadImage.parseMetaData(afile.file))
  // console.log("리사이즈 후 data", await loadImage(afile.file))
  return afile
}

/** 실제 업로드 처리 */
async function doUpload() {

  for (const afile of files.value) {
    if (afile.status === "uploaded") continue; // 이미 업로드 완료된건 제외
    
    const data = new FormData();
    data.append('uploadFiles', afile.file, afile.file.name);
    data.append('from', 'm-uploader');
    // {"name":"코알라.jpg","size":260462,"type":"image/jpeg","ext":null,"width":640,"height":480,"color":null}

    try {
      afile.status = "uploading"
      // noinspection JSUnusedGlobalSymbols
      const ret = await axios.post(props.uploadUrl, data, {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: ({progress}: AxiosProgressEvent) => afile.uploadProgress = progress,
      })
      afile.uploadResult = ret.data
      afile.status = "uploaded"
      console.log("업로드 하나 완료", ret.data)
    } catch (e) {
      afile.status = "uploadfail"
    }
    // "uploadResult": {
    //   "status": "ok",
    //     "field_name": "uploadFiles",
    //     "org_filename": "Jellyfish.jpg",
    //     "saved_filename": "temp_20230321133422356pt63orsy.jpg",
    //     "ext": "jpg",
    //     "size": 775702,
    //     "content_type": "image/jpeg"
    // }
  }

  console.log("업로드 전체 완료")
}


defineExpose({
  // removeFile,
  // pickFiles: () => ref_input_file.value.click(),
  // reset: () => updateFiles([]),
  canUpload,
  // uploadedFiles, queuedFiles,
  // removeUploadedFiles, removeQueuedFiles,
  doUpload,
})
</script>


<style scoped lang="sass">
//:deep(.drop-area)
//  padding: 10px
//  background: #ffffff55
//  transition: .2s ease
//  border: 1px solid blue
//  margin: 10px
//  border-radius: 5px
//
//  &[data-active=true]
//    background: rgba(196, 196, 196, 0.8)
</style>
