<template>
<code class="imd-sm-code"><xmp v-html="content"></xmp></code>
</template>

<style scoped>
code.imd-sm-code {font-size: 12px; font-family: "돋움체", DotumChe,serif; margin: 0; padding: 0 2px; font-weight: normal; }
code.imd-sm-code xmp { margin: -14px 0; padding: 0;}
</style>

<script>
export default {
  props: {
    content: {type: String}
  }
}
</script>
