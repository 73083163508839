<template>
<!--  ref="qinput"-->
  <q-input
    :autogrow="multiline"
    :type="multiline ? 'textarea' : type"
    dense outlined
    hide-bottom-space
    v-bind="{...$attrs, value, clearable, disable}"
    v-on="$listeners"
    :id="v_id"
    class="imd-input-text"
    :style="style"
    :class="{
      w100p: 'full' in $attrs,
      'centered-input': 'center' in $attrs,
    }"
    :bg-color="disable ? 'grey-3' : 'white'"
    @clear="onClear"
    :input-style="contentStyle"
  >

    <!-- 모든 슬롯 랩핑 -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>

    <slot/>
  </q-input>
</template>

<script>
import InputSet from './mixin/input-set';
export default {
  inheritAttrs: false,
  mixins: [ InputSet ],
  props: {
    value: {},
    w:            {type: [Number, String]},
    type:         {type: String , default: 'text'}, // text, password, textarea, email, search, tel, file, number, url, time, date
    multiline:    {type: Boolean, default: false}, // type=textarea랑 차이점은 textarea는 원래 넓은거에서 스크롤바 생김.  multiline은 한줄인데 엔터치면 쭉쭉 늘어남
    clearable:    {type: Boolean, default: false},
    disable:      {type: Boolean, default: false},
    contentStyle: {type: [String,Object]},
  },
  watch: {
    value(n) {
      if (this.value === undefined) { // clear()시 호출됨.
        this.$emit("input", "")
      }
      // q-input 에 mask 사용시 초기화 한 다음에 focus 되지 않은 상태에서 값을 설정할때 ( ex. i-input-date )
      // 값 자체는 반영되지만 시각적으로는 값이 아니라 마스크를 보여주는 버그가 있다.
      // 그래서 값이 변할때 실제로 보여주는 부분 ( innerValue ) 를 체크해서 실제 값 ( this.value ) 이랑 다른경우
      // 임의로 설정해 준다. 내 생각에 quasar 버전이 올라가다 보면 버그가 해결되지 않을까 싶다.
      // 그럴때는 아래 코드는 삭제해도 된다.
      // console.log("WATCH - i-input n =", n, ", value = ", this.value, ", innerValue =", this.$refs.qinput.innerValue)
      // if (T.hasKey(this.$refs.qinput, "innerValue")) {
      //   if (T.isNotBlank(this.value) && this.value !== this.$refs.qinput.innerValue) {
      //     console.log("강제설정! ", this.value)
      //     // this.$refs.qinput.innerValue = this.value
      //   }
      // }
    }
  },
  computed: {
    v_id() {
      return this.$vnode.data.model && 'input-'+this.$vnode.data.model.expression || 'v'
    },
    style() {
      const w = this.w
      if (w === undefined || w === null) return undefined
      if (typeof w == 'number') return {width: w+"px"}
      if (typeof w == 'string') {
        if (/^\d+$/.test(w.trim())) return {width: w+"px"}
        return {width: w}
      }
      return undefined
    },
  },
  methods: {
    onClear() { // 클리어서 기본 null 로 셋팅되는걸 "" 으로 셋팅되게 바꾼다..
      this.$emit('input','')
    },
    focus() {
      this.$nextTick(() => {
        let ar = this.$el.getElementsByTagName("input")
        if (ar.length < 1) ar = this.$el.getElementsByTagName("textarea")
        if (ar.length > 0) ar[0].focus()
      })
    }
  },
}
</script>
