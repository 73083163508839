import { boot } from 'quasar/wrappers';

import Vue from 'vue'

 // import VueCompositionApi from 'vue';
// Vue.use(VueCompositionApi);

// 전역설정 모듈 : T, _, axios, dayjs, alertify, Vue
// 전역설정 위치 : 1. .eslintrc.js > globals
//                 2. quasar.conf.js > webpack.ProvidePlugin
//                 3. 각각의 webpack 에 externals 부분
// quasar.conf.js 에서 webpack.ProvidePlugin 설정으로 전역으로 지정했기 때문에 import가 필요 없어졌지만
// 필요없어도 import 문장은 어딘가에 선언해 둬야 intellij가 인식하여 parameter hints 를 사용할 수 있다.

import _config from '../inc/config_dev'
import T from 'lib/tools'
import _ from 'lodash'
import axios from 'axios'
import dayjs from 'dayjs'

console.log("[global config]", _config)

// import alertify from "alertifyjs"
import 'dayjs/locale/ko' // dayjs 한국어 모듈
dayjs.locale('ko') // dayjs 한국어 설정

//---------------------------------------------------------------------------------------------------------------------------------------
// alertify 설정 -- [동시수정] : /src/export/export-default.js
//---------------------------------------------------------------------------------------------------------------------------------------
// css [이동함] 일반적으론 아래와 같이 quasar 는 quasar.conf.js 의 css:[] 부분에서 해준다. 그쪽으로 이동시킴
// - 꼭 이동해야 하는 이유는 css 우선순위 때문에 내가 수정한 css가 나중에 적용되 우선순위를 높혀야 적용되기 때문이다.
// - 내가 수정한건 components-ui.css 하단에 있으며 quasar.conf.js 의 css:[] 의 alertify 바로 밑에다 두었다.
// import 'alertifyjs/build/css/themes/default.min.css'
// import 'alertifyjs/build/css/alertify.min.css'
// import '../css/alertify.css'
// import '../css/alertify-custom.css'
// alertify.defaults.transitionOff = true
// alertify.defaults.transition = 'fade'; // fade slide zoom
// alertify.defaults.theme.ok = "ui positive button";
// alertify.defaults.theme.cancel = "ui black button";

// intellij content assist 지원. 다른 효과는 전혀 없음;;
window._config  = _config
window.T        = T
window._        = _
// window.axios    = axios
window.dayjs    = dayjs
// window.alertify = alertify

// vue template 에서도 를 사용할 수 있도록
// 여기 추가/수정시 export-default.js 의 하단에도 동일하게 수정 필수!
// Object.defineProperty(Vue.prototype, '_config', { value: _config });
// Object.defineProperty(Vue.prototype, '_', { value: _ });
// Object.defineProperty(Vue.prototype, 'T', { value: T });
// Object.defineProperty(Vue.prototype, 'dayjs', { value: dayjs });

// vue 필터 설정 : vue 3 에서는 필터가 없어졌기 때문에.. 나중을 위해서 가능하면 쓰지는 말자
// Vue.filter('comma', T.comma) // 금액 컴마 찍어주는 필터
// Vue.filter('insertHyphen', T.insertHyphen) // 하이픈 필터
// Vue.filter('insertColon', T.insertColon) // 콜론 필터
// Vue.filter('left' , T.left)  // left 필터
// Vue.filter('right', T.right) // right 필터
// Vue.filter('absComma', T.absComma) // 금액 컴마 및 절대값 반환
// Vue.filter('removeZero', T.removeZero) // 금액 컴마 및 절대값 반환

// vue modal 설정
import VModal from 'vue-js-modal' // modal -- https://github.com/euvl/vue-js-modal
// https://euvl.github.io/vue-js-modal/Intro.html#dynamic-modals
// Vue.use(VModal)
// Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(VModal, {
  dynamic: true,  // 다이나믹 모달 사용여부. 반드시 이 방식으로 해야 IE9 에서도 작동한다
  injectModalsContainer: true, // <modals-container /> 를 선언하지 않아도 자동으로 body 안에 <modals-container /> 를 넣어준다.
  // 반드시 이 방식으로 사용해야 하는이유는
  // 모달안의 모달 호출후 다시 모달을 호출할때 이 옵션이 아니면 작동하지 않기 때문이다.
  // ex) sample 의 컴펌 -> 증표 인쇄 컴펌 -> 다시 sample 컴펌 ( 이 방식이 아니면 작동 안함 )
  dynamicDefaults: {
    // width: "700",
    height: "auto",
    reset: true,
    resizable: true,
    adaptive: true,
    draggable: ".window-header",
    scrollable: true,
    clickToClose: false,
  }
})
// Vue.use(VModal, { dynamic: true })

import VueShortke from 'vue-shortkey' // 단축키 --  https://github.com/iFgR/vue-shortkey/blob/master/README.md
Vue.use(VueShortke)

// ui components 로딩
import '../components-ui'

export default boot(({ Vue }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Vue.prototype._config = _config
  Vue.prototype._ = _
  Vue.prototype.T = T
  Vue.prototype.dayjs = dayjs
});
