<template>
  <div class="q-pa-sm">
    <div class="shadow-4 bg-white rounded-borders overflow-hidden">
<!--    <div class="bg-white overflow-hidden">-->
      <i-bar :title="title" v-if="title"/>
      <slot name="header"/>

      <div :class="calcContentClass" :style="contentStyle">
        <slot/>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
/**
 * 기본 컨테이너
 */
import {computed, defineComponent} from 'vue';
export default defineComponent({
  props: {
    /** 지정할 경우 i-bar 를 자동으로 추가한다 */
    title: {type: String},
    /** 안쪽 여백 및 아이템간들의 간격 설정 (padding,gutter) : xs, sm, md, lg, xl */
    gutter: {type: String},
    /** 본문용 클레스 */
    contentClass: { type: [String, Object], default: null },
    /** 본문용 스타일 */
    contentStyle: { type: [String, Object], default: null },
  },
  setup(props) {
    const calcContentClass = computed(() => {
      if (props.gutter) {
        return T.mergeClass('q-pa-' + props.gutter + ' q-gutter-' + props.gutter, props.contentClass)
      } else {
        return T.ifNU(props.contentClass)
      }
    })
    return {
      calcContentClass
    }
  },
})
</script>
