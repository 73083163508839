import { RouteConfig } from 'vue-router';
import {apiGetEnv, apiGetLoginStatus} from "lib/co-login";
import {NavigationGuard, NavigationGuardNext, Route} from "vue-router/types/router";

const children1: RouteConfig[] = [
  { path: '/main', name: 'main.wt',             component: () => import('pages/Index.vue') },
  { path: '/',
    // redirect: async to => {
    //   const loginStatus = await apiGetLoginStatus()
    //   return loginStatus.login
    //     ? '/wiki/board'
    //     : '/login'
    // }
    beforeEnter: (to, from, next) => {
      apiGetLoginStatus('routes.ts').then(ret => {
        if (ret.login) {
          next('/main'); // 로그인 상태면 대시보드로 리디렉션
        } else {
          next('/login'); // 로그인 상태가 아니면 로그인 페이지로 리디렉션
        }
      });
    }
  },
  
  // 서비스
  { path: '/member/user_mgr',                component: () => import('pages/member/user_mgr.vue') },
  { path: '/member/com_mgr' ,                component: () => import('pages/member/com_mgr.vue') },
  { path: '/member/cust_mgr',                component: () => import('pages/member/cust_mgr.vue') },
  { path: '/member/member_mgr',              component: () => import('pages/member/member_mgr.vue') },
  { path: '/member/admin_mgr',               component: () => import('pages/member/admin_mgr.vue') },
  { path: '/apply/apply_mgr',                component: () => import('pages/apply/apply_mgr.vue') },
  { path: '/apply/apply_mi_cust',            component: () => import('pages/apply/apply_mi_cust.vue') },
  { path: '/apply/apply_visit_mgr',          component: () => import('pages/apply/apply_visit_mgr.vue') },
  { path: '/apply/apply_visit_cust',         component: () => import('pages/apply/apply_visit_cust.vue') },
  { path: '/apply/apply_visit_sch',          component: () => import('pages/apply/apply_visit_sch.vue') },
  { path: '/apply/apply_visit_cal',          component: () => import('pages/apply/apply_visit_cal.vue') },
  { path: '/apply/apply_online_mgr',         component: () => import('pages/apply/apply_online_mgr.vue') },
  { path: '/apply/apply_online_status',      component: () => import('pages/apply/apply_online_status.vue') },
  { path: '/apply/apply_online_status_o',      component: () => import('pages/apply/apply_online_status_o.vue') },
  { path: '/apply/apply_online_sch',         component: () => import('pages/apply/apply_online_sch.vue') },
  { path: '/apply/apply_online_cal',         component: () => import('pages/apply/apply_online_cal.vue') },
  { path: '/apply/apply_visit_status',       component: () => import('pages/apply/apply_visit_status.vue') },

  { path: '/apply/apply_after_status',     component: () => import('pages/apply/apply_after_status.vue') },

  // 일정표 달력
  { path: '/apply/apply_resultc_sch',     component: () => import('pages/apply/apply_resultc_sch.vue') },
  { path: '/apply/apply_resultc_cal',     component: () => import('pages/apply/apply_resultc_cal.vue') },
  { path: '/apply/apply_afdeep_sch',     component: () => import('pages/apply/apply_afdeep_sch.vue') },
  { path: '/apply/apply_afdeep_cal',     component: () => import('pages/apply/apply_afdeep_cal.vue') },
  { path: '/apply/apply_after_mgr' ,     component: () => import('pages/apply/apply_after_mgr.vue') },

  { path: '/apply/apply_all_sch'  ,     component: () => import('pages/apply/apply_all_sch.vue') },
  { path: '/apply/apply_all_cal'  ,     component: () => import('pages/apply/apply_all_cal.vue') },

  { path: '/system/off_day_mgr',             component: () => import('pages/system/off_day_mgr.vue') },
  { path: '/system/term_mgr',                component: () => import('pages/system/term_mgr.vue') },
  { path: '/system/agency_mgr',              component: () => import('pages/system/agency_mgr.vue') },
  { path: '/system/alim_mgr',                component: () => import('pages/system/alim_mgr.vue') },

  // 테스트
  { path: '/test/t_attach-files',            component: () => import('pages/test/t-attach-files2.vue') },
  { path: '/test/t_seditor.wt',              component: () => import('pages/test/t_seditor.vue') },
  { path: '/test/t_qtable.wt',               component: () => import('pages/test/t_qtable.vue') },
  { path: '/test/t_uploader.wt',             component: () => import('pages/test/t_uploader.vue') },
  { path: '/test/t_organ.wt',                component: () => import('pages/test/t_organ.vue') },
  { path: '/test/t_qeditor_font_color.wt',   component: () => import('pages/test/t_qeditor_font_color.vue') },

  // 샘플
  { path: '/sample/stable/s_stable_01_basic.wt', component: () => import('pages/sample/stable/s_stable_01_기본.vue') },
  { path: '/sample/stable/s_stable_01_tiny.wt',  component: () => import('pages/sample/stable/s_stable_01_최소.vue') },
  { path: '/sample/stable/s_stable_02_opt.wt',   component: () => import('pages/sample/stable/s_stable_02_옵션따로.vue') },
  { path: '/sample/stable/s_stable_03_fmt.wt',   component: () => import('pages/sample/stable/s_stable_03_포멧기능.vue') },
  { path: '/sample/stable/s_stable_04_page1.wt', component: () => import('pages/sample/stable/s_stable_04_페이징유지1.vue') },
  { path: '/sample/stable/s_stable_04_page2.wt', component: () => import('pages/sample/stable/s_stable_04_페이징유지2.vue') },
  { path: '/sample/stable/s_stable_04_page3.wt', component: () => import('pages/sample/stable/s_stable_04_페이징유지3.vue') },

];

const log = T.getLogger("ROUTE", "info")

const REGEXP_URL에서_path만_제외 = new RegExp(/(https?:\/\/[^\/]+).*/)

//------------------------------------------------------------------------------
// 모든 요청에 _e 를 주입한다.
const setEnvFunction = (to: Route, from: Route, next: NavigationGuardNext): any => {
  // if (store.state.accessToken !== '') {
  //   return next();
  // }
  // next('/login');
  log.isDebug && log.debug("beforeEnter() : API GET ENV 호출") // to.path = /mgr_academy/group/cust
  // T.trace(to)
  // 이대로 ENV 를 요청하면 가려고 하는 곳의 주소가 아니라. 기존(과거)의 URL로 ENV 요청이 들어가서 엉뚱한 결과가 나온다.
  // MPA 방식의 웹에서는 상관 없지만 SPA 개발모드는 routes 로 이동하기에 이 부분이 문제가 된다.
  // 그래서 임의로 to.path 를 이용하여 가려고 하는 주소를 미리 조합하여 ENV 요청시 파라미터 로 보낸다.
  const api_referer = location.href?.replace(REGEXP_URL에서_path만_제외,"$1") + to.path
  apiGetEnv(api_referer).then(v=> {
    log.isDebug && log.debug("beforeEnter() : API GET ENV 응답", JSON.stringify(v, null, 2))
    to.meta.env = v
    if (!v.login) {
      T.alertError("로그인이 필요합니다.") 
        .then(() => next('/login'))
    } else {
      next()
    }
  })
}

children1.forEach((item: RouteConfig) => {
  if (!item.beforeEnter)
    item.beforeEnter = setEnvFunction
})
//------------------------------------------------------------------------------

const routes: RouteConfig[] = [
  { path: '/login',                   component: () => import('pages/login/login.vue') },
  { path: '/member/user_cert_popup',  component: () => import('pages/member/user_cert_popup.vue') },
  { path: '/apply/result_report_popup', component: () => import('pages/apply/result_report_popup.vue') },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    // component: () => import('layouts/WikiLayout.vue'),
    children: children1, // [canweed] menu 로 이동시킴. 메뉴에서도 같이 쓰려고
    // children: [{ path: '', component: () => import('pages/Index.vue') }]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
];

export default routes;
