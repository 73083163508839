<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <slot/>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
}
</script>
