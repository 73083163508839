<template>
  <div class="row">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    /** 하위 s-td 클레스 강제 지정
     * ```
     * 단 s-td 에서 ignore-std-class 속성을 지정하면 무시 된다.
     * ``` */
    stdClass: { type:String, default: null, required: false },
    sthWidth: { type:[Number,String], default: null, required: false },
  },

  // mounted() {
  //   console.log("$children = ", this.$children)
  // },
}
</script>
