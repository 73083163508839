<template>
  <q-radio
    dense inline size="27px"
    class="i-input-radio-one"
    v-bind="{...$attrs, value}"
    v-on="{...$listeners}"
  />
</template>

<script>

// const log = T.newLogger("i-input-radio-one", false)

export default {
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input'
  },
  props: ['value'],
}
</script>


<!--

  <i-input-radio v-model="search.useyn" itemsp="/전체 | 1/사용 | 0/미사용" />
  <i-input-radio v-model="search.forceyn" itemsp="/전체 | Y/강세 | N/선택" />

-->
