<!--

정상적인 초기 호출순서

1. life  data() : created() -> _copyHeaders()
3. event @update:pagination -> _onUpdateOption()
   - 업데이트 된것도 없는데 이유도 없이 무조건 초기 1회 실행됨;;
   - 그래서 최초 1회 실행은 무시인데
     utoload 옵션인 경우 _apiGetListData() 실행
     -> _apiGetListData()
3. _httpGetList()
4. _updateData()

@itemClick

-->
<template>
    <q-table
      dense flat square hide-pagination
      :binary-state-sort="false"
      ref                = "q_table"
      class              = "imd-table"
      separator          = "cell"
      :loading           = "innerData.loading"

      :columns           = "mHeaders"
      :data              = "filterdList"

      :pagination.sync   = "qtable_pagination_computed"
      @request           = "i_onChangeSortInfo"

      :hide-header       = "hideHeader"
      :table-style       = "{ display: T.isEmptyArray(innerData.items) ? 'none' : 'block' }"
    >


      <!--
          @request           = "_onChangeSortInfo" -- 2페이지 이상해서 정렬정보 바꾸면 1 페이지로 이동한다.
      -->

<!--      <template v-slot:top-right>-->
<!--        ppp = {{ ppp }}-->
<!--      </template>-->

<!--      :pagination.sync   = "ppp"-->
<!--      :pagination.sync   = "qtable_pagination_computed"-->
<!--      :data              = "filterdList"-->
<!--      @update:pagination="v =>_onUpdateOption(v, '@update:pagination')"-->
<!--      :data            = "filterdList"-->
<!--      @update:pagination="v=>_onUpdateOption(null, '@update:pagination')"-->
<!--      @update:pagination="v=>_onUpdateOption(v, '@update:pagination')"-->
<!--      @request="v=>_onUpdateOption(v.pagination, '@request')"-->



    <!--
      :table-style="{display: T.isEmptyArray(mList.items) ? 'none' : 'block'}"  -- 데이터가 없습니다. 라는 표시를 할때 scroll 테이블인 경우 이게 필요함.
                                                                                   scroll 테이블일때 이걸 안하면 중간에 어마무시한 공백이 생김

      @row-click="(v,row,idx)=>T.log('row-click: [' + idx + ']: ' +JSON.stringify(row))"
      @request="onRequest" - 이것은 현재페이가 2페이지 이상이면서 소팅이 변경되었을때 호출됨
      binary-state-sort
      @update:pagination="_onUpdateOption"
      @update:pagination="v=>T.log(v)"
      -->

      <!-- 헤더 ( bypass ) -->
<!--      <template v-slot:header="props" v-if="typeof $scopedSlots['header'] !== 'undefined'"> &lt;!&ndash; 미지정시 원래 스타일을 사용한다. &ndash;&gt;-->
<!--        <slot name="header" :header="props"></slot>-->
<!--      </template>-->

      <!-- 모든 slot 기본 랩핑 -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>


      <template v-slot:header-cell-_checkbox_="props"><!-- 헤더: 체크박스 -->
        <th><i-input-chk v-model="computed_top_checkbox"/></th>
      </template>
<!--      <template v-slot:item-_checkbox_="{item}">&lt;!&ndash; 체크박스 &ndash;&gt;-->
<!--        <i-input-chk v-model="selected_list" :val="item.no"/>-->
<!--      </template>-->

      <!-- 목록 -->
      <template v-slot:body="props">
        <slot name="item" v-bind="props">
          <q-tr :props="props" @click="i_onItemclick(props.row, $event)" :class="{ 'selectTr' : i_isCurrentItem(props.row) }" >

            <template v-for="col in props.cols">

              <!--  특정 컬럼 형식지정 <template v-slot:item-cust_no="{value}">{{ T.comma(value) }}</template>-->
              <template v-if="typeof $scopedSlots['item-td-'+col.name] !== 'undefined'">
                <slot :name="['item-td-'+col.name]"
                      :value="col.value"
                      :item="props.row"
                      :col="col"
                      :i="props.rowIndex"
                      :_no="innerData.pageing.totalCount - props.rowIndex"
                />
              </template>
              
              
              <q-td v-else-if="typeof $scopedSlots['item-'+col.name] !== 'undefined'"
                    :key="col.name"
                    :props="props"
                    :align="col.align"
                    :style="col.style"
              >
                <slot :name="['item-'+col.name]"
                      :value="col.value"
                      :item="props.row"
                      :i="props.rowIndex"
                      :_no="innerData.pageing.totalCount - props.rowIndex"
                />
              </q-td>
<!--              :_no="mList.totalCount - ((mList.pageNo -1) * mList.pageSize) - props.rowIndex"-->


              <q-td v-else-if="col.name === '_checkbox_'"
                    :key="col.name"
                    :props="props"
                    :align="col.align"
                    :style="col.style"
              >
                <i-input-chk v-model="selected_list" :val="props.row[innerData.checkbox_key]"/>
              </q-td>

              <!-- 미지정인 경우 -->

              <q-td v-else
                :key="col.name"
                :props="props"
                :align="col.align"
                :style="col.style"
              >
                <!-- ellipsis ( ellipsis width ) 가 지정된 경우. 참고:  https://poiemaweb.com/css3-centering   -->
                <div v-if="col.ewidth" class="imd-vac-wrapper">
                  <div class="imd-vac-content">
                    {{ col.value }}
                    <s-tooltip :content="col.value" position="down" />
                  </div>
                </div>
                <template v-else>
                  {{ col.value }}
                </template>
              </q-td>

            </template>
<!--            <q-td v-for="col in props.cols" :key="col.name" :props="props">-->
<!--              {{ col.value }}-->
<!--            </q-td>-->
          </q-tr>
        </slot>
      </template>

      <!-- 빈칸 체우기 : items 있을때만 -->
      <template v-slot:bottom-row="props">
        <slot name="body.append" v-bind="props">
          <q-tr v-for="i in leftCount" :key="i">
            <q-td v-for="j in mHeaders.length" :key="j">&nbsp;</q-td>
          </q-tr>
        </slot>
        <slot name="table-footer"></slot>
      </template>

      <!-- 결과 없음 -->
      <template v-slot:no-data>
        <template v-if="innerData.loading">
          <q-tr :height="pageing.pageSize * 29">
            <q-td align="center" :colspan="mHeaders.length"> 검색중..</q-td>
          </q-tr>
        </template>
        <slot v-else name="no-results">
          <div class="full-width text-center q-py-sm">
            {{ noDataText }} <!-- 데이터가 없습니다.-->
          </div>
<!--            <q-tr> <q-td class="text-center border_red" :colspan="mHeaders.length"> 데이터가 없습니다. </q-td> </q-tr>-->
<!--            <q-tr v-for="i in options.rowsPerPage - 1" :key="i">-->
<!--              <q-td v-for="j in mHeaders.length" :key="j">b</q-td>-->
<!--            </q-tr>-->
        </slot>
      </template>

      <!-- 푸터 : 총수, 페이징, 페이지당 줄수  -->
      <template v-slot:bottom="scope">
        <div v-if="!hideFooter" class="fit text-center content-center items-center" style="line-height: 1">
          <!-- [중간] 페이징 -->
          <div v-if="!innerData.onepage" style="display: inline-block" class="">
            <!-- [주의] 페이지를 변경하지 않더라도 여기에 쓰인 totPages 가 compute 인데 pageSize 를 참고하기 때문에 -->
            <!-- 아래의 페이지 사이즈를 변경하면 덩달아서 실행된다. 이 케이스에 대한 안전장치로 페이지번호가 -->
            <!-- 실제로 변경되었는지를 확인 후 서버호출을 해야 한다.-->
            <q-pagination
              v-if="totPages && totPages > 1"
              :value="pageing.pageNo"
              @input="i_onChangePage"
              :max="totPages"
              :max-pages="innerData.showPageSize"
              dense
              class="font_mal css-s-page"
              size="13px"
              padding="0"
            />
<!--            direction-links-->
<!--            boundary-links-->
          </div>
<!--          @input="()=>_onUpdateOption(null, 'q-pagination @input')"-->

          <!-- [좌측] 총 수 -->
          <div v-if="totCnt && totCnt >= 0" class="float-left" style="line-height: 24px">
            <template v-if="onepage && filterdList && filterdList.length != totCnt">
              총 {{ T.comma(filterdList.length) }} 건
            </template>
            <template v-else>
              총 {{ T.comma(totCnt) }} 건
            </template>
          </div>

          <!-- [우측] 페이지당 줄 수 -->
          <div v-if="!innerData.onepage && totPages && totPages >= 1" class="row float-right items-center q-mr-xs">
            <div style="display: inline-block">
              페이지당 줄 수
            </div>
            <!-- [주의] 아래 페이지당 줄 수를 변경하면.. pageing.pageSize가 totPages(computed) 에서 쓰이므로 옵져버 작동해서 -->
            <!-- 위에 q-pagination 이 리랜더링 되면서 _onChangePage 가 덩달하 호출되게 된다. 근데 안될때도 있다. -->
            <!-- [참고] 다행스럽게도 @input이 호출되는건 사용자가 페이지를 변경했을때 뿐이다. 페이지 변경시 무조건 서버호출 하자.-->
            <i-input-sel
              style="width: 60px;"
              v-model="pageing.pageSize"
              @input="i_onChangePageSize"
              :items="[3,5,10,15,20,30,40,50,70,100,200,500,1000,2000,10000]"
            />


          </div>
        </div>

      </template>
    </q-table>
</template>


<script lang="ts">
import {defineComponent} from "vue";
import stable_setup, { stable_props } from 'src/components-ui/stable/s-table-logic'
export default defineComponent({
  name: 's-table',
  props: stable_props,
  setup(props, {emit}) {
    return stable_setup(props, emit, 'S-TABLE')
  }
})
</script>
