<template>
  <div class="row q-px-sm q-gutter-xs">
    <div class="imd-page-title text-bold text-black" id="pageTitle">
      <slot/>
    </div>
    <q-space/>
  </div>
</template>

<script>
export default {
}
</script>
