// (주의사항)
// 컴파일 방법 w-t.cmd 실행
// const dayjs = require('dayjs')
// import _ from 'lodash'
// import axios from 'axios'
// import alertify from "alertifyjs"
// import config  from './config'
// const T = { 라고 하면 안된다. SPA 에서는 되는데 parcel 로 컴파일한 결과가 wontalk 에서 안된다.
// 아마도 global 변수로 취급되지 않아서 그런 것 같다.

// https://jsdoc.app/tags-param.html

import tools_log           from './tools_log'                   // log 관련

import tools_base          from './base.1.tools_isnull'          // 타입상관 없이 null 체크 같은 기본
import tools_string_number from './base.2.tools_string_number'   // string, number 관련
import tools_array         from './base.3.tools_array'           // array 관련
import tools_object        from './base.4.tools_map_object'      // map 형태 object
import tools_alert_noti    from './tools_alert_noti'             // alert, confirm, prompt, notify
import tools_move_input    from './tools_move_input'             // 포커스 자동이동. nextFocus, nextSelect
import tools_http          from './tools_http'                   // 포커스 자동이동. nextFocus, nextSelect
import tools_web           from './tools_web'                    // 웹브라우져 관련
import tools_stable        from './tools_stable'                 // stable관련
import tools_misc          from './tools_misc'                   // 기타 잡다
import tools_vue           from './tools_vue'                    // vue 전용

// const inst = {
export default {
    ...tools_log
  , ...tools_base
  , ...tools_string_number
  , ...tools_array
  , ...tools_object
  , ...tools_alert_noti
  , ...tools_move_input
  , ...tools_http
  , ...tools_web
  , ...tools_stable
  , ...tools_misc
  , ...tools_vue
}

// T = inst

// import Vue from "vue" // 이거 안하면 웹은 괜찮아도 spa 에서 T 가 셋팅이 안된다.

// [for web, spa] spa만 쓰려면 global-components.js 에서 해도 되지만 웹에서도 되어야 해서 여기서 셋팅한다.
// Vue.prototype.T = inst // this.T 로 사용하기 위함 이렇게하면 template 에서는 그냥 T 로 사용가능해진다.

// [for spa only] 웹에서는 필요없지만 spa 에서는 imoprt 방식으로도 사용되기에 필요하다.
// export default { ...inst }
