<template>
  <q-markup-table
    class="imd-table"
    dense flat square
    separator = "cell"
  >
    <slot/>
  </q-markup-table>
</template>

<script lang="ts">
export default {
}
</script>
