import {PropType} from "vue";
import {computed, onMounted, reactive, ref, Ref, toRefs, watch,} from 'vue'
import {imageResize, imageRotate} from 'lib/img_utils'
import {OneImeResultType, OneImeType, OneImeWorkType, QFileType, QUploaderType} from "src/components-ui/types_comp";

const log = T.getLogger("i-input-one-file", "debug")

/**
 * 남은작업.
 * - 기존 파일인 경우에도 이미지 회전 가능하도록
 * - instance type 이 작동하지 않는다. 원인 분석 필요.
 */

export default {
  // inheritAttrs: false,
  props: {
    /** <pre>
     * {
     *   name: 기존 파일명 - READ ONLY
     *   path: 기존 경로   - READ ONLY
     *   result: 작업결과 {
     *     name          : 업로드된 파일명
     *     tmp_saved_name: 임시로 저장된 파일명
     *     isDelete      : 기존 파일을 삭제할때 true
     *   }
     *   work: 내부변수 ( 무시. 다시 마운트 될때 값 유지용. )
     * }
     * </pre> */
    value     : { type: Object as PropType<OneImeType> },

    /** (사용금지) 이미지 선택시 자동업로드 여부
     * [경고] 이 방식을 사용하면 업로드 후 이미지 회전하고 저장하면 적용되지 않는다. */
    autoUpload: { type: Boolean, default: false,},

    /** style 옵션 */
    alt       : { type: String, default: "이미지",},
    width     : { type: Number, default: 150, },
    height    : { type: Number, default: 190, },
  },
  setup(props, {emit}) {

    const metaCopy = true  // 메타정보 복사여부
    let uploadedFn = null // doUplod시 완료 콜백
    const refUploader: Ref<QUploaderType | undefined> = ref()

    const state = reactive({
      result          : null as OneImeResultType, // result 내부변수
      work            : null as OneImeWorkType, // work 내부변수
      /** 이미지 크게 보기*/
      popup_img_type    : null as 'prev' | 'selected' | null, // 팝업 이미지 구분
    })

    const _copyResultWorkFromValue = () => {
      log.debug("_copyResultWorkFromValue 초기화")
      state.result  = T.isNU(props.value?.result) ? null : {...props.value.result} // result 복원
      state.work    = T.isNU(props.value?.work  ) ? null : {...props.value.work  } // work 복원
    }

    watch(()=>props.value?.path, (newValue, oldValue) => {
      log.debug("WATCH props.value.path : " + oldValue + " -> " + newValue)
      // UNDEFINED -> a : 데이터 로딩시 아무것도 안해도 된다.. 초기화 해도 된다.
      // "" -> a        : 없던 사진을 신규등록 완료시  : 초기화 필요.
      // a -> b         : 저장완료시. : 초기화 필요.
      // a -> ""        : 삭제완료시. : 아무것도 안해도 된다.
      // if (T.isNU(oldValue)) return
      // if (T.isBlank(newValue)) return
      if (newValue !== oldValue) { // 어쟀든 바뀌면 초기화.
        uploaderResetAddFile(null)
        _copyResultWorkFromValue()
      }
    })

    onMounted(() => {
      // s-split 등으로 인하여 화면 사이즈 변경시 다시 마운트 되면
      // props.result, props.work 로부터 값들을 다시 가져와서 셋팅하는 작업을 한다.
      // 즉 화면이 변경되어도 모든 값들을 다시 재로딩 한다.
      log.debugV("마운트 ", {result: props.value?.result, work: props.value?.work})
      if (T.isNotNU(props.value?.work?.qfile)) { // 업로더에 파일 다시 추가
        const qf = props.value.work.qfile
        qf.no_add = true
        refUploader.value.addFiles([qf]); // 이걸하면 onAdded가 자동으로 실행되기에 newFile.no_add = true;
      }
      _copyResultWorkFromValue()
    })

    /** 보기 모드 (셋중에 하나) <pre>
     * - prev     : 기존 이미지를 보여준다.
     * - selected : 업로드 이미지를 선택한 경우. ( 업로드 했어도 selected 이다 )
     * - form     : 업로드 폼 모드. 업로드 폼을 보여준다.
     *              이것은 prev, upload 상태에서 clear (삭제) 버튼을 클릭했거나
     *              처음부터 prev (기존 이미지)가 없는 경우이다.
     * </pre> */
    const view_mode = computed<ViewModeType>(() => { // 업로더 폼 보여주기
      if (state.result?.isDelete) return "form" // 삭제한 상태
      if (T.isNotNU(state.work?.status)) return "selected" // 이미지를 선택한 상태
      if (T.isNotBlank(props.value?.path)) return "prev" // 기존 이미지가 있는 상태
      return "form" // 셋다 아니라면. 그냥 기존 이미지가 없는 상태다.
    })

    watch(() => props.work, (newValue, oldValue) => {
      log.debug("watch props.work", {oldValue, newValue})
    })

    /** 업로더의 파일 하나 교체 */
    const replaceUploaderFile = (oldFile:QFileType, newFile:QFileType) => {
      oldFile.no_remove = true // 기존 파일은 삭제 금지
      newFile.no_add = true // 교체 파일은 추가 금지
      refUploader.value.removeFile(oldFile); // 이걸하면  onRemoved가 자동으로 실행되기에 oldFile.no_remove = true;
      refUploader.value.reset();
      refUploader.value.addFiles([newFile]); // 이걸하면 onAdded가 자동으로 실행되기에 newFile.no_add = true;
    }

    /** 업로더를 초기화 하고 파일 하나를 add 한다. (그러면 onAdded 가 호출된다.) 물론 null 을 지정하면 파일을 추가하진 않는다.
     * @param file null 을 지정하면 파일을 추가하진 않는다.
     */
    const uploaderResetAddFile = (file:File = null) => {
      // state.file = file
      refUploader.value.reset();
      refUploader.value.removeUploadedFiles();
      refUploader.value.removeQueuedFiles();
      if (file) {
        refUploader.value.addFiles([file]);
      }
    }

    /** 결과 전송 */
    const emitData = () => {
      const data: OneImeType = {}
      if (T.isNotNU(props.value?.name)) data.name   = props.value.name
      if (T.isNotNU(props.value?.path)) data.path   = props.value.path
      if (T.isNotNU(state.result     )) data.result = state.result
      if (T.isNotNU(state.work       )) data.work   = state.work
      emit("input", data)
    }

    // 현재 이미지 삭제
    const clear = () => {
      state.work = null
      state.result = T.isNotBlank(props.value?.path) // 기존 이미지가 있었다면
          ? {isDelete: true} // 삭제 ㄱㄱ
          : null // 원래 없었으면 아무일도 일어나지 않는다.
      emitData()
      uploaderResetAddFile(null)
    }

    /** 업로드 폼해서 클릭하여 추가한 경우 */
    const selectFile = file => { // file은 q-file 내부의 clearable 아이콘 클릭시 null 로 들어온다.
      log.debug("selectFile =", file)
      if (file) { // 파일을 선택한 경우 ( clear 한 경우엔 file 이 null 이다 )
        uploaderResetAddFile(file)
      } else { // 클리어한 경우
        clear()
      }
    }

    // 우측의 업로드 아이콘으로 추가한 경우 파일이 추가된 경우 ( 이미지 교체 )
    const onAdded = async (files: QFileType[]) => {
      // (보통 resize로 인하여) 교체로 추가하는 경우 아무것도 안함.
      if (files[0].no_add) {
        log.debug("교체 ( 주로 리사이즈 )로 인한 추가라서 아무것도 안함", files[0])
        return (delete files[0].no_add);
      }

      // 추가 및 자동 리사이즈
      // 현재 제일 큰 번호
      for (const file of files) {
        file.resized = false;
        log.debug("파일 추가 = ", file)

        try {
          const tmp:OneImeWorkType = {}
          tmp.name           = file.name
          tmp.tmp_saved_name = undefined
          tmp.size           = file.size
          tmp.ext            = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name)[0] : undefined
          tmp.type           = file.type
          tmp.status         = 'added' // ready, added, resized, ok, error업로드 실패 오류 메세지
          tmp.msg            = ''        // 상태 메세지

          state.result = null // 파일 추가하면 결과는 일단 비워둔다.
          // 리사이즈 시도
          try {
            const resizedFile = await imageResize(file, metaCopy) as QFileType; // 리사이즈 실행하고
            replaceUploaderFile(file, resizedFile) // 업로더의 파일 교체
            tmp.qfile          = resizedFile
            tmp.size           = resizedFile.size
            tmp.status         = 'resized' // ready, resized, ok, error업로드 실패 오류 메세지
            tmp.msg            = ''        // 상태 메세지
            state.work = tmp
            emitData()
          } catch(e) {
            tmp.status = 'error'
            tmp.msg = e
            state.work = tmp
            emitData()
            // noinspection ExceptionCaughtLocallyJS
            throw e
          }



          if (props.autoUpload) { // 자동 업로드인 경우
            doUpload() // onUploading() 가 바로 호출되고 완료시 onUploaded() 호출됨.
          }
        } catch(e) {
          alert("이미지 리사이즈 오류\n\n"+ e);
        }
      }
    }

    const doUpload = (cb:(result: OneImeResultType)=>void = null) => {
      uploadedFn = cb
      refUploader.value.upload() // onUploading() 가 바로 호출되고 완료시 onUploaded() 호출됨.
    }

    /** 업로드 시작 */
    const onUploading = (info) => {
      log.debug("업로드 시작 :", info) //완료시 onUploaded
    }

    /** 업로드 완료 */
    const onUploaded = (v) => {
      // log.debug("업로드 종료 = ", v);
      // console.log("업로드 종료 = ", v.xhr.response);
      const ret = JSON.parse(v.xhr.response);
      log.debug("업로드 완료 ", ret)
      // type: "image/jpeg"
      // ext: "jpg"
      // name: "2021-08-01 14.21.32.jpg"
      // saved_filename: "8188_20210906112717429lwu7tdml.jpg"
      // size: 1000950
      // status: "ok"
      state.work = {
        ...state.work,
        tmp_saved_name : ret.saved_filename,
        size           : ret.size,
        status         : "ok",
        msg            : "",
      }
      state.result = {
        name           : state.work.name,
        tmp_saved_name : state.work.tmp_saved_name,
      }
      emitData()
      if (typeof uploadedFn === 'function') {
        // T.notify("서버에 이미지를 업로드 완료 했습니다.");
        uploadedFn(state.result)
      }
    }


    // 파일이 제거된 경우
    // onRemoved: removedFiles => removedFiles[0].no_remove // 교체로 인한 삭제시
    //   ? (delete removedFiles[0].no_remove) // 아무것도 안함
    //   : removedFiles.forEach(file => delete state.sortMap[file.name]),
    const onRemoved = (v) => T.log("파일제거됨 :", v)

    // 이미지 회전
    const doRotate = async (rotate: 'none' | 'right' | 'left') =>
      replaceUploaderFile(
        refUploader.value.files[0],
        await imageRotate(refUploader.value.files[0], rotate, metaCopy) as QFileType
      )

    /** 처음 상태로 복원 */
    const doUndo = () => {
      uploaderResetAddFile(null)
      state.work = null
      state.result = null
      emitData()
    }

    /** (권장) 이미지 업로드를 async 방식으로 실행 <pre>
     * - 업로드가 가능할땐 업로드 한다. 이미 했다면 기존 결과를 그대로 다시 반환
     * - 결과는 셋중에 하나다
     *    1. null             : 아무것도 안한경우
     *    2. {isDelete: true} : 기존 이미지를 삭제만 한 경우
     *    3. {name: "업로드된이름.jpg", tmp_saved_name: "임시업로드이름"}
     *       : 이미지를 업로드 한 경우
     * </pre>
     * @return value.result */
    const doUploadAsync = () : Promise<OneImeResultType> => {

      // if (state.work?.status === 'ok') { // 이미 업로드 완료 상태인 경우
      //   log.debug("이미 완료")
      //   return new Promise(r=> r(state.result))
      // }

      if (state.work?.status === 'resized') { // 업로드 준비
        log.debug("시이이작")
        return new Promise(resolve=> doUpload(r => resolve(r)))
      }

      log.debug("아무것도 안함.")
      return new Promise(r=> r(state.result)) // 그 외에는
    }

    return {
      view_mode,
      ...toRefs(state),
      refUploader,
      selectFile,
      clear,
      onAdded,
      onRemoved,
      doRotate,
      onUploading,
      doUpload,
      onUploaded,
      doUndo,
      doUploadAsync,
      canUndo: computed(() => T.isNotBlank(props.value?.path) && state.result != null),
      styleWrap: computed(() => ({
        width: props.width + 'px',
        height: props.height + 'px',
      })),
      styleImgage: computed(() => ({
        maxWidth: (props.width - 8) + 'px',
        maxHeight: (props.height - 38) + 'px',
      })),
      styleQFileInput: computed(() => ({
        width: '100%',
        height: (props.height - 70) + 'px',
      })),
    }
  }
}

/** 보기 모드 (셋중에 하나) <pre>
 * - prev     : 기존 이미지를 보여준다.
 * - selected : 업로드 이미지를 선택한 경우. ( 업로드 했어도 selected 이다 )
 * - form     : 업로드 폼 모드. 업로드 폼을 보여준다.
 *              이것은 prev, upload 상태에서 clear (삭제) 버튼을 클릭했거나
 *              처음부터 prev (기존 이미지)가 없는 경우이다.
 * </pre> */
type ViewModeType = "prev" | "selected" | "form"
