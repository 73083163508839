<template>
  <div>

  <!--  개발모드  -->
    <s-page-title v-if="isDevMode"><span v-html="real_title"/></s-page-title>

    <span ref="ref_slot" v-show="false">
      <slot/>
    </span>

  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";

const props = defineProps({
  title: {type: String, default: null}
})

const ref_slot = ref()    // slot.default 의 html 을 구하기 위해...
const real_title = ref()  // 실제 제목

// const isDevMode = _config.mode === 'dev'
const isDevMode = location.port == 4000

onMounted(() => {
  real_title.value = T.ifBlank(props.title, ref_slot.value?.innerHTML)

  if (!isDevMode) { // 운영모드인데 noname일때
    T.NF(document.getElementById("pageTitle-noname"),
      o => o.innerText = real_title.value)
  }
  // console.log("slots.default()            =", slots.default())
  // console.log("slots.default()[0].text    =", slots.default()[0].text)
  // console.log("ref_slot.value.textContent =", ref_slot.value?.textContent)
  // console.log("ref_slot.value?.innerHTML  =", ref_slot.value?.innerHTML)
  // console.log("real_title.value           =", real_title.value)
})
</script>