<!--

  https://fonts.google.com/icons?selected=Material+Icons
-->
<template>
  <s-icon
    :name="real_name"
    size="16px"
    class="shadow-4 rounded-borders"
    style="padding: 2px; font-weight: normal; color: #E9E9E9; background-color: #1976D2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- 툴팁 지정시  -->
    <s-tooltip v-if="tip" :content="tip" />

  </s-icon>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, toRefs, watch, computed} from "vue"
export default defineComponent ({
  name: "i-bar-icon",
  inheritAttrs: false,
  props: {
    name: {type:String},
    tip: {type:String},
  },
  setup(props, { emit }) {
    return {
      real_name: computed(() => {
        if (props.name === 'move') return 'open_with';
        return props.name;
      })
    }
  }
  // props: ['type', 'skey',],
  // data() {
  //   return {
  //     keys : null,
  //     canClickEmit : true, // click emit 가능 여부
  //   }
  // },
  // computed: {
  //   dpTitle() {
  //     return this.skey ? this.title + '(' + this.skey + ')' : this.title;
  //   }
  // },
  // created() {
  //   this.keys = this.skey && this.skey.toLowerCase().split("+") || null
  // },
  // methods: {
  //   // emitClick(e) { this.$emit('click', e) },
  //
  //   /** 0.5초 이내 연속 누름 무시 */
  //   emitClick: _.throttle(function(e) {
  //     this.$emit('click', e)
  //   }, 500, {trailing: false}),
  // },
})
</script>

<style scoped>
</style>

